import { Button, Grid, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';

export const CreateForm = (Props: any) => {
  const [values, setValues] = useState<any>(() =>
    Props?.columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Name' ||
        name == 'Address' ||
        name == 'Zipcode' ||
        name == 'Phone')
    ) {
      error = `${name} feild is required`;
    }
    if (value && value.length < 2 && (name == 'Address' || name == 'Name')) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Name') {
      error = `The ${name} must be in letters`;
    }
    if (value && !isNumeric(value) && name == 'Zipcode') {
      error = `${name} must be in numbers`;
    }
    if (value && !isNumeric(value) && name == 'Phone') {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    Props.columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      Props.onSubmit(values);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {Props?.columns?.map((column: any, index: number) =>
              column.header === 'ID' ? (
                <></>
              ) : (
                <Grid key={column.accessorKey} item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          {Props?.ButtonLabel}
        </Button>
      </div>
    </div>
  );
};