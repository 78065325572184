import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  IconButton,
} from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
} from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useAPI } from '../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomerOrderDeatils } from '../../../customer-order/components/CustomerOrder';

export const GenerateCustomerInvoice = () => {
  const { httpPost, handleAlertBar } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState<CustomerOrderDeatils[]>([]);

  const columns = useMemo<MRT_ColumnDef<CustomerOrderDeatils>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

  // POST with API : create invoice for Customer Order ID
  const createInvoiceForCustomerOrderId = async (row: any) => {
    const payload = {
      orderId: row.orderId,
      customerId: row?.customer?.id,
      clientId: row.client?.id
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_INVOICE_CREATE_POST}`,
      payload,
    ).then((response: any) => {
      if (response.status === 200) {
        fetchAllCustomerOrderIdForInvoice();
        handleAlertBar(
          'success',
          `${row.orderId} Invoice created successfully`,
        );
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Customer Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API : Customer Order ID for Invoice
  const fetchAllCustomerOrderIdForInvoice = async () => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_IDS_FOR_INVOICE_POST}`,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Retail Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const onBackRetailInvoice = () => {
    navigate('..');
  };

  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };
  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={0.5}>
          <IconButton onClick={onBackRetailInvoice}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={8} lg={8.5}>
          <h4 style={{ marginTop: '5px' }}>Generate Invoice</h4>
        </Grid>
      </Grid>
      <hr></hr>
      <Card sx={{ margin: '5px 15px' }} elevation={2}>
        <CardActions>
          <Grid container className="m-2" spacing={2}>
            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Start date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="startDate"
                  value={dayjs(startDate)}
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'End date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="endDate"
                  value={dayjs(endDate)}
                  minDate={dayjs(startDate)}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </Grid>
            {startDate && endDate ? (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={fetchAllCustomerOrderIdForInvoice}
                >
                  Get Customer Order
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardActions>
      </Card>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTablePaperProps={{
            elevation: 2,
            sx: {
              width: '100%',
              '& .css-1tbggly': {
                minHeight: '5px',
              },
            },
          }}
          columns={columns}
          data={tableData}
          enableEditing
          positionActionsColumn="last"
          enableRowActions
          enableFullScreenToggle={false}
          enableColumnFilters={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableHiding={false}
          enableStickyHeader
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => createInvoiceForCustomerOrderId(row.original)}
              >
                Generate Invoice
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
