import React, { useState } from 'react'
import { CreateForm } from '../../../../shared/components/create-form/CreateForm';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { RetailStoreDetails } from '../RetailStore';

export const CreateRetailStore = () => {
const navigate = useNavigate();
  const location = useLocation();
  const { httpPost , handleAlertBar } = useAPI();
  const columns = location?.state?.column;
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

    // POST with API

    const handleCreateRetailStore = (values: RetailStoreDetails) => {
        if (!Object.keys(validationErrors).length) {
           httpPost(WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_POST, values ).then((response) => {
             console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
             console.log( 'RESPONSE :',response);
              if (response.status === 200) {
                handleAlertBar("success", `New Retail Store Created Successfully`);
                onClose();
              } else {
                 if(response.data.error){        
                 console.error('Error Updating RetailStore: ', response.data.message);
                 }
              }
           });
         }
     };


  const onClose = () => {
    navigate('/retail-store');
  }
  return (
      <CreateForm 
        columns={columns}
        onClose={onClose}
        onSubmit={handleCreateRetailStore}
        ButtonLabel={'Create New Retail Store'} />
  )
}