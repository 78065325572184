import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { BusinessDepartmentDetails } from '../../models/BusinessDepartmentModel';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { Button, Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const CreateBusinessDepartment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location.state.column;
  const business = location.state.businessId;
  const businessStore = location.state.businessStoreId
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const { businessList } = useContext(BusinessContext);
  const [businessId, setBusinessId] = useState('');

  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const type = localStorage.getItem('userType');
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (!value && (name == 'Name' || name == 'Description')) {
      error = `${name} feild is required`;
    }
    if (value && value.length < 2 && (name == 'Address' || name == 'Name')) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Name') {
      error = `The ${name} must be in letters`;
    }
    if (value && !isNumeric(value) && name == 'Phone') {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (businessId === '') {
      newErrors['business'] = 'Business field is required';
    }
    if (businessStoreId === '') {
      newErrors['businessStore'] = 'Business Store field is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      onSubmit(values);
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET + '/' + id,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setBusinessStoreData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // Business Store
  useEffect(() => {
    if (businessId) {
      fetchBusinessStore(businessId);
    }
    if(business) {
      setBusinessId(business);
      fetchBusinessStore(business);
    }
    if(businessStore) {
      setBusinessStoreId(businessStore)
    }
  }, [businessId, business, businessStore]);


  const onSubmit = (values: BusinessDepartmentDetails) => {
    if (!Object.keys(validationErrors).length) {
      const payload = {
        name: values?.name,
        description: values?.description,
        businessId: businessId,
        businessStoreId: businessStoreId,
        contactName: values?.contactName,
        contactPhone: values?.contactPhone,
      };
      console.log(payload);
      httpPost(
        WASHUB_CONSTANTS.API_URLS.CLIENT_BUSINESS_DEPARTMENT_DATA_POST,
        payload,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New Business Department Created Successfully`,
          );
          setValues({});
          backToDepartmentList();
        } else {
          if (response.data.error) {
            console.error('Error Updating department: ', response.data.message);
          }
        }
      });
    }
  };

  const backToDepartmentList = () => {
    navigate('..', { state: { busId: businessId, storeId: businessStoreId } });
  };
  return (
    <div style={{ width: '100%' }}>
      <Grid container>
        <Grid item m={2}>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToDepartmentList}
          >
            Business Department
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item m={2}>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create New Business Department
          </Typography>
        </Grid>
      </Grid>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: '76%' }}>
          <TextField
            key="business"
            style={{ width: '40%', marginRight: '10px' }}
            label="Select Business"
            name="business"
            select
            onChange={(e) => {
              setBusinessId(e.target.value);
            }}
            value={businessId}
          >
            {businessList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          {businessId ? (
            <TextField
              key="businessStore"
              style={{ width: '40%' }}
              label="Select Business Store"
              name="businessStore"
              select
              onChange={(e) => {
                setBusinessStoreId(e.target.value);
              }}
              value={businessStoreId}
            >
              {businessStoreData.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </div>
      </Stack>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
          {columns?.map((column: any, index: number) =>
              column.header === 'ID' ? (
                <></>
              ) : (
                <Grid key={column.accessorKey} item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  key={column.accessorKey}
                  id={column.header}
                  label={column.header}
                  name={column.accessorKey}
                  value={values[column.accessorKey]}
                  onChange={handleChange}
                  onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                  error={!!errors[column.accessorKey]}
                  helperText={errors[column.accessorKey]}
                />
                </Grid>
              ),
            )}

          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
            onClick={handleSubmit}
          variant="contained"
        >
          Create New Business Department
        </Button>
      </div>
    </div>
  );
};
