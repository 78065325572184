import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';
import { DeptServiceItemDetails } from '../../models/BusinessDepartmentModel';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import ClearIcon from '@mui/icons-material/Clear';
import { AddServiceItemDept } from './AddServiceItemDept';
import { AlertDialog } from '../../../../shared/components/alert-dialog/AlertDialog';

export const MappedServiceItem = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowDetail = location?.state?.row;
  const { handleAlertBar, httpGet, httpPost, httpDelete } = useAPI();
  const [tableData, setTableData] = useState<DeptServiceItemDetails[]>([]);
  const [deleteRowData, setDeletedRowData] = useState<any>([]);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const backToDepartmentList = () => {
    navigate('..', {
      state: {
        busId: rowDetail?.business?.id,
        storeId: rowDetail?.businessStore?.id,
      },
    });
  };
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  const columns = useMemo<MRT_ColumnDef<DeptServiceItemDetails>[]>(
    () => [
      {
        accessorKey: 'mediaItem.url',
        header: 'Item Image',
        editVariant: 'select',
        Cell: ({ cell }) => (
          <img width={50} height={40} src={cell.getValue<string>()} />
        ),
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'serviceItemSection.name',
        header: 'Category',
      },
      {
        accessorKey: 'category.name',
        header: 'Service Type',
      }
    ],
    [],
  );
  // GET with API
  const fetchDeptServiceItemMap = async (rowDetail: any) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_MAP_GET_DATA}/${rowDetail?.business?.id}/${rowDetail?.businessStore?.id}/${rowDetail?.id}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching serviceitem: ',
            response?.data?.message,
          );
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };

  const addNewServiceItemMap = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMapServiceItem = async (rowSelection: any) => {
    const rowIds = Object.keys(rowSelection);
    const requestBody = {
      businessId: rowDetail?.business?.id,
      storeId: rowDetail?.businessStore?.id,
      departmentId: rowDetail?.id,
      serviceItemId: rowIds,
    };
    await httpPost(
      WASHUB_CONSTANTS.API_URLS
        .CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_MAP_POST_DATA,
      requestBody,
    ).then((response) => {
      if (response && response.data) {
        if (response.data.error) {
          handleAlertBar('error', response?.data?.message);
        } else {
          handleCloseDialog();
          handleAlertBar(
            'success',
            'Service Items successfully mapped in department',
          );
          fetchDeptServiceItemMap(rowDetail);
        }
      }
    });
  };

  useEffect(() => {
    if (rowDetail) {
      fetchDeptServiceItemMap(rowDetail);
    }
  }, [rowDetail]);

  const handleDeleteRow = (row: MRT_Row<DeptServiceItemDetails>) => {
    setDeletedRowData(row?.original);
    setIsAlertDialog(true);
  };
  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  const handleDeleteServiceItems = () => {
    console.log(deleteRowData);
    httpDelete(
      `${WASHUB_CONSTANTS.API_URLS
        .CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_UNMAPPED_DELETE_DATA}/${deleteRowData?.serviceItemMapId}`,
    ).then((response) => {
      if (response && response.data && response.data.error) {
        handleAlertBar('error', response?.data?.message);
      } else {
        handleAlertBar(
          'success',
          'Service item unmapped from department successfully!',
        );
        fetchDeptServiceItemMap(rowDetail);
        handleAlertDialogClose();
      }
    });
  };
  return (
    <div style={{ width: '100%' }}>
      <Grid container>
        <Grid item m={2}>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToDepartmentList}
          >
            Business Department
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item m={2}>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Service Items
          </Typography>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: '76%', display: 'flex',
          flexDirection: 'row', }}>
          <Typography variant='h6'>
            {rowDetail?.business?.name}
          </Typography>
          <NavigateNextIcon />
          <Typography variant='h6'>
            {rowDetail?.businessStore?.name}
          </Typography>
          <NavigateNextIcon />
          <Typography variant='h6'>
            {rowDetail?.name}
          </Typography>
        </div>
        <Button
          color="primary"
          sx={{ color: 'white' }}
          onClick={addNewServiceItemMap}
          variant="contained"
        >
          Add New Service Item
        </Button>
      </Stack>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          muiTableContainerProps={{
            sx: {
              height: '49vh',
            },
          }}
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton onClick={() => handleDeleteRow(row)} color="error">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <AddServiceItemDept
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleMapServiceItem={handleMapServiceItem}
        columns={columns}
        businessId={rowDetail?.business?.id}
      />
      <AlertDialog
        open={isAlertDialog}
        title={'Delete Service Item'}
        description={'Are you sure you want to delete this service item ?'}
        submitBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        handleSubmit={handleDeleteServiceItems}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
    </div>
  );
};
