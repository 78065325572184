import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { data } from './makeData';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";
import { useNavigate } from 'react-router-dom';


export type BusinessDetails = {
  id: string;
  name: string;
  address: string;
  address2: string; 
  zipCode: string; 
  state: string;
  country: string;
  mail: string;
  phone: string;
  contactName: string;
  contactPhone: string;
};

export const Business = (props: any) => {
  const { httpGet, httpPost, httpPut, httpDelete } = useAPI();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<BusinessDetails[]>(() => data);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const navigate = useNavigate();
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };
  
  // GET with API
  const fetchBusiness = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_GET, queryParams).then((response) => {
       if (response.status === 200) {
          setTableData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching Business: ', response.data.message);
           setTableData([]);
          }
       }
    })
    };

 useEffect(() => {    
    fetchBusiness();
  }, []); 

  // POST with API

  const handleCreateNewRow = (values: BusinessDetails) => {
     if (!Object.keys(validationErrors).length) {
        //console.log(row, values);
        httpPost(WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_POST, values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchBusiness();
           } else {
              if(response.data.error){        
              console.error('Error Updating Business: ', response.data.message);
              }
           }
        });
      }
  };

  // PUT with API

  const handleSaveRowEdits: MaterialReactTableProps<BusinessDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        //console.log(row, values);
        tableData[row.index] = values;
        httpPut(WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_PUT+'/'+values.id, values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchBusiness();
              exitEditingMode(); //required to exit editing mode and close modal
           } else {
              if(response.data.error){        
              console.error('Error Updating Business: ', response.data.message);
              }
           }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<BusinessDetails>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  
  const columns = useMemo<MRT_ColumnDef<BusinessDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',        
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',        
      },
      {
        accessorKey: 'country',
        header: 'Country',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',        
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',        
      },
    ],
    [],
  );

  const createNewBusiness = () => {
    const column = [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',        
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',        
      },
      {
        accessorKey: 'country',
        header: 'Country',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',        
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',        
      },
    ];
    navigate('/create-business',{
      state:{column}
    });
  }

  return (
    <div style={{padding:"5px 15px", height: "calc(100vh - 12vh)"}} >
          <Grid container justifyContent={'space-between'} className='mb-2 mt-1'>
          <Grid item>
            <Typography sx={{ textAlign: 'start'}} variant='h5'className='mt-1'>Business</Typography>
          </Grid>
          <Grid item>
          <Button
            color="primary"
            sx={{color: 'white'}}
            onClick={createNewBusiness}
            variant="contained"
          >
            Create New Business
          </Button>
          </Grid>
          </Grid>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTableContainerProps= {{
          sx: {
              height: "65vh"
          }
        }}
        columns={columns}
        enableFullScreenToggle={false}
        enableStickyHeader
        data={tableData}
        enableColumnOrdering
        enableEditing
        enableHiding
        editDisplayMode='row'
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {/*<Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>*/}
          </Box>
        )}
      />
    </div>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<BusinessDetails>[];
  onClose: () => void;
  onSubmit: (values: BusinessDetails) => void;
  open: boolean;
}


const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default Business;