/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from 'react';
import { useAPI } from '../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../shared/Constants';
import { useLocation } from 'react-router-dom';
import { Box, Card, CardMedia, Stack, Typography } from '@mui/material';
export const UserOrder = () => {
  const { httpGet } = useAPI();
  const [clientDetail, setClientDetail] = useState<any>({});
  const isFirstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (isFirstRender.current) {
      fetchRetailStoreDetail();
      isFirstRender.current = false;
    }
  }, []);
  const isBusinessOrder = location.pathname.includes('businessCustomerOrder');
  const isBusinessStoreOrder = location.pathname.includes('businessStoreOrder');
  const fetchRetailStoreDetail = async () => {
    const key = localStorage.getItem('key');
    const retailUrl = `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREBYID_DATA_GET}/${key}`;
    const businessUrl = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_CUSTOMER_ORDER_DATA_GET}/${key}`;
    await httpGet(isBusinessOrder || isBusinessStoreOrder ? businessUrl : retailUrl).then(
      (response: any) => {
        if (response.status === 200) {
          setClientDetail((): any =>
            response?.data,
          );
          localStorage.setItem(
            'buid',
            (isBusinessOrder || isBusinessStoreOrder) && response?.data?.business?.id,
          );
        } else {
          if (response.data.error) {
            console.error('Error fetching Business: ', response.data.message);
          }
        }
      },
    );
  };
  return (
    <>
      <div
        style={{
          color: 'white',
          backgroundImage: "url('images/banner.png')",
          backgroundSize: 'cover',
          padding: '10px',
          fontFamily: 'Sans-Serif',
          display: 'flex',
          justifyContent: isBusinessStoreOrder? 'end' : 'space-between',
          height: "20vh"
        }}
      >
        {isBusinessStoreOrder ? (
          <Box id="businessStore">
            <Stack sx={{ textAlign: 'end' }}>
              <Typography
                component="h3"
                sx={{ fontWeight: 900, fontSize: '20px', color: "#045464" }}
              >
                {clientDetail?.name}
              </Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.address}</Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.city}</Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.phone}</Typography>
            </Stack>
          </Box>
        ) : (
          <>
            <div style={{display: "flex",alignContent: "flex-start"}}>
              <Card
                    sx={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "25px"
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        padding: "15px 5px 15px 5px",
                        width: "90%",
                        objectFit: "cover",
                      }}
                      image={clientDetail?.client?.clientlogo || clientDetail?.business?.client?.clientlogo}
                    />
              </Card>
              <Typography
                component="h2"
                sx={{ marginLeft: "5px",fontWeight: 900, fontSize: '30px', color: "#045464" }}
              >
                {clientDetail?.client?.name || clientDetail?.business?.client?.name}
              </Typography>

              </div>
            <div>
            <Stack sx={{ textAlign: 'end'}}>
              <div style={{position: 'relative', marginBottom: '45px'}}>
              <Card
                  sx={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "25px",
                    position: 'absolute',
                    right: '0px',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="100%"
                    image={clientDetail.retailLogo || clientDetail.businessStoreLogo}
                  />
              </Card>
              </div>
              <Typography
                component="h3"
                sx={{ fontWeight: 900, fontSize: '20px', color: "#045464" }}
              >
                {clientDetail?.name}
              </Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.address}</Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.city}</Typography>
              <Typography sx={{color: "#045464"}} component="p">{clientDetail.phone}</Typography>
            </Stack>
            </div>
          </>
        )}
      </div>
    </>
  );
};
