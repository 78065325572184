import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  IconButton,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
} from 'material-react-table';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { BusinessStoreOrderDeatils } from '../../../business-store-order/components/BusinessStoreOrder';

export const GenerateInvoices = () => {
  const { httpPost, handleAlertBar } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const businessId = location.state.businessId;
  const businessStoreId = location.state.businessStoreId;
  const invoiceFor = location.state.invoiceFor;
  const [tableData, setTableData] = useState<BusinessStoreOrderDeatils[]>([]);

  const columns = useMemo<MRT_ColumnDef<BusinessStoreOrderDeatils>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

  // POST with API : create invoice for Business Order ID
  const createInvoiceForBusinessOrderId = async (row: any) => {
    if (invoiceFor === 'Business') {
      const payload = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        businessId: businessId,
        orderIds: [row.orderId],
        clientId: row?.client?.id,
      };
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_INVOICE_CREATE_POST}`,
        payload,
      ).then((response: any) => {
        if (response.status === 200) {
          fetchAllBusinessOrderIdForInvoice();
          handleAlertBar(
            'success',
            `${row.orderId} Invoice created successfully`,
          );
          setIsLoading(false);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching Business Order for Invoices: ',
              response.data.message,
            );
          }
        }
      });
    } else {
      createInvoiceForBusinessStoreOrderId(row);
    }
  };

  // POST with API : create invoice for Business Order IDS
  const createInvoiceForBusinessOrderIds = async (rowsData: any) => {
    const selectedBusinessOrderIds: any = rowsData.map(
      (item: any) => item.orderId,
    );
    if (invoiceFor === 'Business') {
      const payload = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        businessId: businessId,
        orderIds: selectedBusinessOrderIds,
      };
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_IDS_INVOICES_CREATE_POST}`,
        payload,
      ).then((response: any) => {
        if (response.status === 200) {
          setIsLoading(false);
          fetchAllBusinessOrderIdForInvoice();
          handleAlertBar('success', 'Invoices created successfully');
        } else {
          if (response.data.error) {
            handleAlertBar('error', response.data.message);
            console.error(
              'Error fetching Business Order for Invoices: ',
              response.data.message,
            );
          }
        }
      });
    } else {
      createInvoiceForBusinessStoreOrderIds(rowsData);
    }
  };

  // POST with API : Business Order ID for Invoice
  const fetchAllBusinessOrderIdForInvoice = async () => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessId: businessId,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_IDS_FOR_INVOICE_POST}`,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API : create invoice for Business Store Order ID
  const createInvoiceForBusinessStoreOrderId = async (row: any) => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessId: businessId,
      businessStoreId: businessStoreId,
      orderIds: [row.orderId],
      clientId: row?.client?.id,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_INVOICE_CREATE_POST}`,
      payload,
    ).then((response: any) => {
      if (response.status === 200) {
        fetchAllBusinessOrderIdForInvoice();
        handleAlertBar(
          'success',
          `${row.orderId} Invoice created successfully`,
        );
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API : create invoice for Business Store Order IDS
  const createInvoiceForBusinessStoreOrderIds = async (rowsData: any) => {
    const selectedBusinessOrderIds: any = rowsData.map(
      (item: any) => item.orderId,
    );
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessId: businessId,
      businessStoreId: businessStoreId,
      orderIds: selectedBusinessOrderIds,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDERSTORE_IDS_INVOICES_CREATE_POST}`,
      payload,
    ).then((response: any) => {
      if (response.status === 200) {
        setIsLoading(false);
        fetchAllBusinessOrderIdForInvoice();
        handleAlertBar('success', 'Invoices created successfully');
      } else {
        if (response.data.error) {
          handleAlertBar('error', response.data.message);
          console.error(
            'Error fetching Business Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // POST with API : Business Store Order ID for Invoice
  const fetchAllBusinessStoreOrderIdForInvoice = async () => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessId: businessId,
      businessStoreId: businessStoreId,
    };
    httpPost(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_IDS_FOR_INVOICE_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Order Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleGenerateInvoice = (
    rows: MRT_Row<BusinessStoreOrderDeatils>[],
  ) => {
    const rowData = rows.map((row) => row.original);
    createInvoiceForBusinessOrderIds(rowData);
  };

  const onBackBusinessInvoice = () => {
    navigate('..', {
      state: { order: {business:{id: businessId}, businessStore: {id: businessStoreId}} },
    });
  };

  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };
  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={0.5}>
          <IconButton onClick={onBackBusinessInvoice}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={8} lg={8.5}>
          <h4 style={{ marginTop: '5px' }}>Generate Invoice</h4>
        </Grid>
      </Grid>
      <hr></hr>
      <Card sx={{ margin: '5px 15px' }} elevation={2}>
        <CardActions>
          <Grid container className="m-2" spacing={2}>
            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Start date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="startDate"
                  value={dayjs(startDate)}
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'End date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="endDate"
                  value={dayjs(endDate)}
                  minDate={dayjs(startDate)}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </Grid>
            {startDate && endDate && invoiceFor === 'Business' ? (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={fetchAllBusinessOrderIdForInvoice}
                >
                  Get Business Order
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {startDate && endDate && invoiceFor === 'BusinessStore' ? (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={fetchAllBusinessStoreOrderIdForInvoice}
                >
                  Get Business Store Order
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardActions>
      </Card>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableEditing
          positionActionsColumn="last"
          enableRowActions
          enableRowSelection
          enableFullScreenToggle={false}
          enableStickyHeader
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => createInvoiceForBusinessOrderId(row.original)}
              >
                Generate Invoice
              </Button>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                onClick={() =>
                  handleGenerateInvoice(table.getSelectedRowModel().rows)
                }
              >
                Generate Selected Order Invoice
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
