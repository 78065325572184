import { Box, Button } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { LaundryCartContext } from '../../context/service.context';
import { Icons } from '../../../../assets/icons';
import {
  getCategoryColor,
  getGroupByCategory,
} from '../../../../shared/Constants';
import './cartsummary.scss';

interface ICartSummaryProps {
  order: any;
  setOrder: (value: any) => void;
  submit: () => void;
}

const tableHeader = [
  {
    name: 'No.',
    width: '5%',
  },
  {
    name: 'Item',
    width: '30%',
  },
  {
    name: 'Quantity',
    width: '10%',
  },
  {
    name: 'Rate',
    width: '50%',
  },
  {
    name: 'Amount',
    width: '10%',
  },
];

const CartSummary: React.FC<ICartSummaryProps> = ({ order, setOrder, submit }) => {
  const { data, setData, clientInfo } = useContext(LaundryCartContext);
  const { userName, address, phoneNumber } = clientInfo;
  const TaxPercentage = 5;
  const currency = 'AED: ';

  const getTax = () => {
    return (getTotal() * TaxPercentage) / 100;
  };

  const getTotalQuantity = () => {
    return order?.reduce((acc: number, item: any) => {
      return acc + item.quantity;
    }, 0);
  };

  const getTotal = () => {
    return order?.reduce((acc: number, item: any) => {
      return acc + item.price * item.quantity;
    }, 0);
  };

  const increaseQuantity = (id: any) => {
    const currentItems = [...order];
    const index = currentItems.findIndex((item: any) => item.id === id);
    currentItems[index].quantity += 1;
    setOrder(currentItems);
  };

  const decreaseQuantity = (id: any) => {
    const currentItems = [...order];
    const index = currentItems.findIndex((item: any) => item.id === id);
    if (currentItems[index].quantity > 0) {
      currentItems[index].quantity -= 1;
      setOrder(currentItems);
    }
  };

  const validCategroy = (_data: any) => {
    return _data?.some((e: any) => e?.quantity > 0);
  };

  const quantityFilteredCartData = (_data: any) => {
    return _data?.filter((item: any) => {
      if (item.quantity > 0) return item;
    });
  };
  
  return (
    <div>
      <ul className="dashboard-user-info">
        <li className="dashboard-user-info-name">
          <img
            src={Icons.user}
            width={'20px'}
            alt="Icons.user"
            id="Icons.user"
          />
          <span
            title={userName}
          >
            {userName}
          </span>
        </li>
        <li className="dashboard-user-info-phone">
          <img
            src={Icons.phone}
            width={'20px'}
            alt="Icons.phone"
            id="Icons.phone"
          />
          <span>{phoneNumber}</span>
        </li>
      </ul>
      <p className="dashboard-user-info-address">{address}</p>
      <hr style={{ borderColor: 'rgb(1 1 1 / 40%)' }} />
      <Box className="dashboard-summary-list">
        {Object.keys(getGroupByCategory(data))?.map(
          (key: string, gIndex: number) => {
            return (
              <Box key={`category${gIndex}`}>
                {validCategroy(getGroupByCategory(data)[key]) && (
                  <section>
                    <h6
                      id={key?.toLowerCase()}
                      className="dashboard-category-section"
                      style={{
                        background: getCategoryColor(key),
                      }}
                    >
                      {key}
                    </h6>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        flexWrap: 'wrap',
                        paddingBottom: '1rem',
                      }}
                    >
                      <Box className="dashboard-summary-category">
                        <table>
                          <thead>
                            <tr>
                              {tableHeader?.map(
                                (header: any, tIndex: number) => {
                                  return (
                                    <th
                                      key={`tableheader-${tIndex}`}
                                      style={{
                                        width: header?.width,
                                      }}
                                    >
                                      {header?.name}
                                    </th>
                                  );
                                },
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {quantityFilteredCartData(
                              getGroupByCategory(data)[key],
                            )?.map((item: any, oIndex: number) => {
                              return (
                                item?.quantity > 0 && (
                                  <tr
                                    key={oIndex}
                                    style={{
                                      background: item?.edit
                                        ? '#feea9d'
                                        : 'transparent',
                                    }}
                                    onClick={() => {
                                      const currentItems = [...order];
                                      const _index = currentItems.findIndex(
                                        (i: any) => i.id === item.id,
                                      );
                                      currentItems[_index].edit = true;
                                      setOrder(currentItems);
                                    }}
                                  >
                                    <td
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    >
                                      {item?.edit ? (
                                        <button
                                          type="button"
                                          className="dashboard-tableActions-edit-btn"
                                          onClick={(event) => {
                                            const currentItems = [...order];
                                            const _index =
                                              currentItems.findIndex(
                                                (i: any) => i.id === item.id,
                                              );
                                            currentItems[_index].edit = false;
                                            setOrder(currentItems);
                                            event?.stopPropagation();
                                          }}
                                        >
                                          <img
                                            src={Icons.closeX}
                                            alt="close"
                                            id={`closeIcon${oIndex}`}
                                          />
                                        </button>
                                      ) : (
                                        oIndex + 1
                                      )}
                                    </td>
                                    <td>{item.item}</td>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    >
                                      {item?.edit ? (
                                        <ul className="dashboard-tableActions">
                                          <li style={{ width: '30%' }}>
                                            <button
                                              type="button"
                                              className="dashboard-tableActions-decrement-btn"
                                              onClick={(event) => {
                                                decreaseQuantity(item.id);
                                                event.stopPropagation();
                                              }}
                                            >
                                              -
                                            </button>
                                          </li>
                                          <li style={{ width: '30%' }}>
                                            <input
                                              type="text"
                                              className="dashboard-tableActions-input"
                                              value={item?.quantity}
                                              readOnly
                                            />
                                          </li>
                                          <li style={{ width: '20%' }}>
                                            <button
                                              type="button"
                                              className="dashboard-tableActions-increment-btn"
                                              onClick={(event) => {
                                                increaseQuantity(item.id);
                                                event.stopPropagation();
                                              }}
                                            >
                                              +
                                            </button>
                                          </li>
                                        </ul>
                                      ) : (
                                        item.quantity
                                      )}
                                    </td>
                                    <td>
                                      {item.currency}
                                      {item.price}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: 'left',
                                      }}
                                    >
                                      {item.currency}
                                      {item.price * item.quantity}
                                    </td>
                                  </tr>
                                )
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </section>
                )}
              </Box>
            );
          },
        )}
        <Box className="dashboard-summary-total">
          <hr style={{ borderColor: 'rgb(1 1 1 / 40%)' }} />
          <table className="dashboard-summary-total-table">
            <tbody>
              <tr>
                <td
                  style={{
                    width: '50%',
                  }}
                >
                  Total:
                </td>
                <td
                  style={{
                    width: '20%',
                    textAlign: 'center',
                  }}
                >
                  {`${getTotalQuantity()}`}
                </td>
                <td
                  style={{
                    width: '30%',
                  }}
                >
                  {`${currency}${getTotal()}`}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '50%',
                  }}
                >
                  Tax:
                </td>
                <td
                  style={{
                    width: '20%',
                    textAlign: 'center',
                  }}
                ></td>
                <td
                  style={{
                    width: '30%',
                  }}
                >
                  {`${currency}${getTax()}`}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '30%',
                    fontWeight: 'bold',
                  }}
                >
                  Grand Total:
                </td>
                <td></td>
                <td
                  style={{
                    width: '40%',
                    fontWeight: 'bold',
                    color: '#8e267c',
                  }}
                >
                  {`${currency}${getTotal() + getTax()}`}
                </td>
              </tr>
            </tbody>
          </table>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              padding: '1rem 1rem 0rem 1rem',
            }}
          >
            <Button variant="contained" onClick={submit}>Submit</Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CartSummary;
