
import React from 'react'
import { OrderHeader } from './order-header/OrderHeader'
import './OrderCenter.scss';
import { Routers } from '../../shared/Routers';

export const OrderCenter = () => {
  return (
    <div className='order-center-container'>
        <OrderHeader />
        <Routers/>
    </div>
  )
}