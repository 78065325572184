import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CollectionTable from './Table';
import { useCollectionAPI } from '../hooks/useCollectionAPI';
import { WASHUB_CONSTANTS } from '../../shared/Constants';
import { useAPI } from '../../shared/services/api/API';
import { IBarCodeDetails, ICollectionTable } from '../models/CollectionPoint';
import { MyButton } from '../../shared/components/Buttons/Button';

const CollectionPoint = () => {
  const { httpGet, handleAlertBar } = useAPI();
  const [barCode, setBarCode] = useState('');
  const [isdisabled, setIsdisabled] = useState(true);
  const [barcodeDetails, setbarcodeDetails] = useState<ICollectionTable>();
  const [enableBarCode, setEnableBarCode] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [selectedProd, setSelectedProd] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [isActive, setIsActive] = useState('addUnScanned');
  const [iSuccess, setIsSuccess] = useState(false);
  const [orderId, setOrderId] = useState('');

  const {
    getAllBusinessEmp,
    getAllBusProducts,
    getAllBusStores,
    generateBarcode,
    createBarCodeOrder,
    allEmpIds,
    allBusProducts,
    allBusStores,
  } = useCollectionAPI();
  const barcodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBarcode = e.target.value.trim();
    if (newBarcode.length > 0) {
      setIsdisabled(false);
      setBarCode(newBarcode);
    } else {
      setIsdisabled(true);
      setBarCode('');
    }
  };

  const getBarCodeDetails = async () => {
    if (barCode === barcodeDetails?.barCode) {
      setBarCode('');
      return handleAlertBar('error', 'already scanned');
    }
    try {
      const response = await httpGet(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_BARCODE_DETAILS_GET +
          barCode,
      );
      console.log(response.data);
      if (response.status === 200) {
        setBarCode('');
        setbarcodeDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching barcode details:', error);
    }
  };

  const newUnScannedHandler = () => {
    setIsActive('addUnScanned');
    setBarCode('');
    setIsdisabled(true);
    setbarcodeDetails(undefined);
    setEnableBarCode(false);
  };

  const clearDataHandler = () => {
    setIsActive('cancelOrder');
    setBarCode('');
    setIsdisabled(true);
    setbarcodeDetails(undefined);
    setEnableBarCode(false);
    setIsSuccess(false);
  };

  const newBarcodeHandler = () => {
    clearDataHandler();
    setEnableBarCode(true);
    getAllBusinessEmp();
    getAllBusProducts();
    getAllBusStores();
    setIsActive('newBarcode');
  };

  const handleGenerateBarcode = async () => {
    const result = await generateBarcode(
      selectedStore,
      selectedProd,
      selectedEmp,
    );
    setbarcodeDetails(result?.data);
    console.log('result', result);
  };

  useEffect(() => {
    if (allEmpIds.length > 0) {
      setSelectedEmp(allEmpIds[0]);
    }
    if (allBusProducts.length > 0) {
      setSelectedProd(allBusProducts[0]);
    }
    if (allBusStores.length > 0) {
      setSelectedStore(allBusStores[0]);
    }
  }, [allEmpIds, allBusProducts, allBusStores]);

  const saveDataHandler = async () => {
    if (barcodeDetails) {
      const barCode: IBarCodeDetails = {
        campId: barcodeDetails.campId,
        productId: barcodeDetails.productId,
        employeeId: barcodeDetails.employeeNo,
        barCode: barcodeDetails.barCode,
        itemCount: 0,
      };
      await createBarCodeOrder(barCode).then((result) => {
        clearDataHandler();
        setIsSuccess(true);
        console.log('result', result);
        if (result?.status === 200) {
          setOrderId(result.data.orderId);
        }
      });
    }
  };

  return (
    <Box sx={{ border: '1px solid #259aca', margin: '20px' }}>
      <Typography
        sx={{ backgroundColor: '#259aca', padding: '5px', color: 'white' }}
      >
        Collection Point
      </Typography>
      <Box sx={{ padding: '20px' }}>
        <Stack spacing={2} direction="row">
          <MyButton
            label="Add UnScanned"
            variant="contained"
            onClick={newUnScannedHandler}
            sx={{
              backgroundColor:
                isActive === 'addUnScanned' ? '#25bcca' : '#259aca',
              color: 'white',
              '&:hover': {
                backgroundColor:
                  isActive === 'addUnScanned' ? '#25bcca' : '#259aca',
              },
            }}
          ></MyButton>
          <MyButton
            variant="contained"
            label="New Barcode"
            sx={{
              backgroundColor:
                isActive === 'newBarcode' ? '#25bcca' : '#259aca',
              color: 'white',
              '&:hover': {
                backgroundColor:
                  isActive === 'newBarcode' ? '#25bcca' : '#259aca',
              },
            }}
            onClick={newBarcodeHandler}
          ></MyButton>
          <MyButton
            label="Cancel Order"
            variant="contained"
            onClick={clearDataHandler}
            sx={{
              backgroundColor:
                isActive === 'cancelOrder' ? '#25bcca' : '#259aca',
              color: 'white',
              '&:hover': {
                backgroundColor:
                  isActive === 'cancelOrder' ? '#25bcca' : '#259aca',
              },
            }}
          ></MyButton>
        </Stack>
        {enableBarCode && (
          <Box id="enablingBarcode" sx={{ mt: '20px' }}>
            <Grid
              container
              spacing={2}
              flexDirection="row"
              sx={{ alignItems: 'baseline' }}
            >
              <Grid item>
                <label htmlFor="">Employee No</label>
              </Grid>
              <Grid item>
                <TextField
                  id="employeeNo"
                  select
                  value={selectedEmp}
                  onChange={(e) => setSelectedEmp(e.target.value)}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                  sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                >
                  {allEmpIds?.map((emp, i) => (
                    <MenuItem key={i} value={emp}>
                      {emp}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: '15px' }}>
              <Grid item>
                <label>Product Id</label>
              </Grid>
              <Grid item>
                <TextField
                  id="productId"
                  select
                  value={selectedProd}
                  onChange={(e) => setSelectedProd(e.target.value)}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                  sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                >
                  {allBusProducts?.map((prod, i) => (
                    <MenuItem key={i} value={prod}>
                      {prod}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: '15px' }}>
              <Grid item>
                <label>Camp Id</label>
              </Grid>
              <Grid item>
                <TextField
                  id="campId"
                  select
                  value={selectedStore}
                  onChange={(e) => setSelectedStore(e.target.value)}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                  sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                >
                  {allBusStores?.map((store, i) => (
                    <MenuItem key={i} value={store}>
                      {store}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box sx={{ mt: '15px' }}>
              <MyButton
                label="Generate Barcode"
                variant="contained"
                sx={{ backgroundColor: '#25bcca', color: 'white' }}
                onClick={handleGenerateBarcode}
              ></MyButton>
            </Box>
          </Box>
        )}
        <Box sx={{ margin: '80px 0px' }}>
          <TextField
            id="outlined-basic"
            placeholder="Scan Barcode"
            variant="outlined"
            value={barCode}
            fullWidth
            onChange={barcodeHandler}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 32,
                '& input': {
                  padding: '8px 14px',
                  fontSize: 14,
                },
              },
            }}
          />
          <MyButton
            label="Add To Cart"
            sx={{ marginTop: '15px' }}
            disabled={isdisabled}
            variant="contained"
            onClick={getBarCodeDetails}
          ></MyButton>
        </Box>
        <Box>
          {barcodeDetails && (
            <CollectionTable barcodeDetails={barcodeDetails} />
          )}
          {iSuccess && !barcodeDetails && (
            <Box>
              <Typography sx={{ textAlign: 'center' }}>
                Your order is successfully submited. Here is the order number{' '}
                {orderId}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Stack spacing={2} direction="row">
            <MyButton
              label="Save Data"
              variant="contained"
              color="success"
              onClick={saveDataHandler}
            ></MyButton>
            <MyButton
              label=" Cancel Data"
              variant="contained"
              sx={{
                backgroundColor: '#9c27b0',
                color: 'white',
                '&:hover': { backgroundColor: '#9c27b0' },
              }}
            ></MyButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CollectionPoint;
