import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { BusinessContext } from '../../../../shared/BusinessContext';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const CreatePriceMaster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const productMasterId = location?.state?.productMasterId;
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (name == 'Customer Description' ||
        name == 'Price' ||
        name == 'Weight' ||
        name === 'Pack Quantity')
    ) {
      error = `${name} field is required`;
    }
    if (value && value.length < 2 && name == 'Customer Description') {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && name == 'Customer Description') {
      error = `The ${name} must be in letters`;
    }
    if (
      value &&
      !isNumeric(value) &&
      (name == 'Price' || name == 'Weight' || name === 'Pack Quantity')
    ) {
      error = `${name} field should be in number`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });
    if (businessStoreId === '') {
      newErrors['businessStoreId'] = 'Business Store field is required';
    }
    // if (departmentId.length === 0) {
    //   newErrors['departmentId'] = 'Department field is required';
    // }
    // if (productMasterId === '') {
    //   newErrors['productMasterId'] = 'product master field is required';
    // }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      createPriceMaster(values);
      setValues({});
      // backToServiceItemList();
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  const createPriceMaster = (values: any) => {
    if (!Object.keys(validationErrors).length) {
      const requestBody = {
        storeId: businessStoreId,
        serviceItemId: productMasterId,
        price: +values?.price,
        weight: +values?.weight,
        packQuantity: +values?.packQuantity,
        customerDescription: values?.customerDescription,
      };

      httpPost(
        WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_SERVICE_ITEM_CREATE_DATA_POST,
        requestBody,
      ).then((response) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New Price Master Item Created Successfully`,
          );
          setValues({});
          backToServiceItemList();
        } else {
          if (response.data.error) {
            console.error(
              'Error Creating serviceitem: ',
              response.data.message,
            );
          }
          handleAlertBar('error', response.data.message);
        }
      });
    }
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRICE_MASTER_BUSINESS_STORE_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setBusinessStoreData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  useEffect(() => {
    if (businessStoreId) {
      setBusinessStoreId(businessStoreId);
    } else {
      fetchBusinessStore();
    }
  }, [businessStoreId]);

  const backToServiceItemList = () => {
    navigate('..', { state: { productMID: productMasterId } });
  };

  return (
    <div>
      <Grid container className="mb-2 me-3 ms-3">
        <Grid item>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToServiceItemList}
          >
            Price Master
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Price Master
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns?.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
            <Grid item xs={12} md={6} lg={6}>
              {' '}
              <TextField
                fullWidth
                key="businessStore"
                label="Select Business Store*"
                name="businessStore"
                select
                onChange={(e) => {
                  setBusinessStoreId(e.target.value);
                }}
                value={businessStoreId}
                error={!!errors['businessStoreId']}
                helperText={errors['businessStoreId']}
              >
                {businessStoreData.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              {' '}
              <FormControl
                sx={{ width: '100%' }}
                error={!!errors['departmentId']}
              >
                <InputLabel id="demo-multiple-name-label">
                  Select Department*
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  // id="demo-multiple-name"
                  multiple
                  value={departmentId || ''}
                  onChange={handleChangeDepartment}
                  input={<OutlinedInput label="Select Department" />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {departmentList.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors['departmentId']}</FormHelperText>
              </FormControl>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Create Price Master
        </Button>
      </div>
    </div>
  );
};
