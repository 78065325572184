import { Box, Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import React from 'react'
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';

export const BusinessInvoiceList = (Props: any) => {
    const { httpPost } = useAPI();
    const generateBusinessOrderInvoice = (row: any) => {
      const payload = {
        clientId: row.client.id,
        invoiceId: row.invoiceId,
      }
      httpPost(WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_INVOICE_GENERATE_PDF_POST, payload).then((response) => {
         if (response.status === 200) {
            // fetchBusiness();
            console.log('invoice response ---> ' + response.data)
         } else {
            if(response.data.error){        
            console.error('Error Updating Business: ', response.data.message);
            }
         }
      });
    }
    return (
      <div style={{padding:"5px 15px"}}>
      <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps= {{
            sx: {
                height: "61vh"
            }
          }}
          columns={Props?.columns}
          data={Props?.tableData}
          editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
          enableEditing
          positionActionsColumn="last"
          enableRowActions
          enableFullScreenToggle={false}
          enableStickyHeader
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => generateBusinessOrderInvoice(row.original)}
              >
                Downoad Pdf
              </Button>
            </Box>
          )}
        />
      </div>
  
    )
}