import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateForm } from '../../../../shared/components/create-form/CreateForm';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { BusinessStoreDetails } from '../makeData';

export const CreateBusinessStore = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { httpPost , handleAlertBar } = useAPI();
  const columns = location?.state?.column;
  const business= location?.state?.businessId;
  const { businessList } = useContext(BusinessContext);
  const [businessId, setBusinessId] = useState('');
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

    // POST with API

    const handleCreateBusinessStore = (values: BusinessStoreDetails) => {
      if (!Object.keys(validationErrors).length) {
         values['businessId'] = businessId;
         httpPost(WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_POST, values ).then((response) => {
           console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
           console.log( 'RESPONSE :',response);
            if (response.status === 200) {
              handleAlertBar("success", `New Business Store Created Successfully`);
              onClose();
            } else {
               if(response.data.error){        
               console.error('Error Updating BusinessStore: ', response.data.message);
               }
            }
         });
       }
   };

     // Business Store
  useEffect(() => {
    if(business) {
      setBusinessId(business);
    }
  }, [business]);


  const onClose = () => {
    navigate('/business-store', {
      state: { businessId: businessId},
    });
  }
  return (
    <>
      <Grid container>
        <Grid item m={2}>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={onClose}
          >
            Business Store
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item m={2}>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Business Store
          </Typography>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: '76%' }}>
          <TextField
            key="business"
            style={{ width: '40%', marginRight: '10px' }}
            label="Select Business"
            name="business"
            select
            onChange={(e) => {
              setBusinessId(e.target.value);
            }}
            value={businessId}
          >
            {businessList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

        </div>
      </Stack>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
      <CreateForm 
        columns={columns}
        onClose={onClose}
        onSubmit={handleCreateBusinessStore}
        ButtonLabel={'Create New Business Store'} />
    </>
  )
}