import { useState } from 'react';
import { WASHUB_CONSTANTS } from '../../shared/Constants';
import { useAPI } from '../../shared/services/api/API';
import axios from 'axios';
import { EmployeeData } from '../modules/EmployeeSetup';
import {
  IBarCodeDetails,
  ICreateBusinessEmployee,
} from '../models/CollectionPoint';

export const useCollectionAPI = () => {
  const { httpGet, httpPost, httpPut } = useAPI();
  const [allEmpIds, setAllEmpIds] = useState([]);
  const [allEmpData, setAllEmpData] = useState([]);
  const [allBusProducts, setAllBusProducts] = useState([]);
  const [allBusStores, setAllBusStores] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  const getAllBusinessEmp = () => {
    return httpGet(WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_ALL_BUS_EMP)
      .then((response) => {
        if (response.status === 200) {
          const empData = response.data.map((emp: any) => emp.employeeNo);
          setAllEmpIds(empData);
          if (JSON.stringify(allEmpData) !== JSON.stringify(response.data)) {
            setAllEmpData(response.data);
          }
        } else {
          console.error('Unexpected response status:', response.status);
        }
      })
      .catch((error) => {
        console.error('Error fetching all employee details:', error);
      });
  };

  const getAllBusProducts = async () => {
    try {
      const response = await httpGet(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_ALL_BUS_PRODUCTS_GET,
      );
      if (response.status === 200) {
        const prodData = response.data.map((prod: any) => prod.productId);
        setAllBusProducts(prodData);
      }
    } catch (error) {
      console.error('Error fetching all bus products:', error);
    }
  };

  const getAllBusStores = async () => {
    try {
      const response = await httpGet(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_ALL_BUS_STORE_GET,
      );
      if (response.status === 200) {
        const storeData = response.data.map((store: any) => store.name);
        setAllBusStores(storeData);
        return storeData;
      }
    } catch (error) {
      console.error('Error fetching all bus products:', error);
    }
  };

  const generateBarcode = async (
    selectedStore: string,
    selectedProd: string,
    selectedEmp: string,
  ) => {
    try {
      const queryParams = {
        width: 500,
        height: 200,
      };
      const data = {
        campId: selectedStore,
        productId: selectedProd,
        employeeId: selectedEmp,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_CREATE_BARCODE,
        data,
        queryParams,
      );
      return response;
    } catch (error) {
      console.error('Error generating barcode:', error);
    }
  };

  const createBusinessEmployee = async ({
    name,
    employeeNo,
    location,
    designation,
    phone,
  }: ICreateBusinessEmployee) => {
    try {
      const data = {
        name: name,
        employeeNo: employeeNo,
        location: location,
        designation: designation,
        phone: phone,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_CREATE_BUS_EMP_POST,
        data,
      );
      if (response.status === 200) {
        const empData = response.data;
        setEmployeeData(empData);
        await getAllBusinessEmp();
      }
    } catch (error) {
      console.error('Error creating new employee :', error);
    }
  };

  const updateBusEmployee = async (empId: string, employee: EmployeeData) => {
    try {
      const { name, employeeNo, location, designation, phone } = employee;
      const baseURL = process.env.REACT_APP_API_BASE_URL;
      const data = {
        name,
        employeeNo,
        location,
        designation,
        phone,
      };
      const token = localStorage.getItem('jwtToken');
      const headers = {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      };
      const url = `${baseURL}${WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_UPDATE_BUS_EMP_PUT}${empId}`;
      const response = await axios.put(url, data, { headers });

      if (response.status === 200) {
        console.log('Employee successfully updated:', response.data);
      } else {
        console.error('Error updating employee:', response.data);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };

  const deleteBusEmployee = async (employeeId: string) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseURL}${WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_DELETE_BUS_EMP_DELETE}${employeeId}`;
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.put(url, null, {
        headers: {
          accept: 'application/json',
          Authorization: token,
        },
      });
      if (response.status === 200) {
        await getAllBusinessEmp();
      }
      console.log('Employee deleted successfully', response);
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  const createBarCodeOrder = async ({
    campId,
    productId,
    barCode,
    employeeId,
    itemCount,
  }: IBarCodeDetails) => {
    try {
      const data = {
        campId: campId,
        productId: productId,
        employeeId: employeeId,
        barCode: barCode,
        itemCount: itemCount,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.COLLECTION_CENTER_CREATE_BARCODE_ORDER_POST,
        data,
      );
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error('Error creating barcode order :', error);
    }
  };

  return {
    getAllBusinessEmp,
    getAllBusProducts,
    getAllBusStores,
    generateBarcode,
    createBusinessEmployee,
    updateBusEmployee,
    deleteBusEmployee,
    createBarCodeOrder,
    allEmpIds,
    allBusProducts,
    allBusStores,
    employeeData,
    allEmpData,
  };
};
