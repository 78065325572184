import {
  Button,
  Card,
  CardContent,
  DialogContentText,
  Divider,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessModal from '../../../../shared/SuccessModal';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import InfoIcon from '@mui/icons-material/Info';
import { UserDataContext } from '../../context/service.context';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';

export const ServiceType = () => {
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState(`2`);
  const [serviceCharge, setServiceCharge] = useState<number>(0);
  const [chargePertentage, setChargePercentage] = useState<number>(0);
  const [vatPercentage, setVatPercentage] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { httpGet, httpPost } = useAPI();
  const totalPrice = location.state.totalPrice;
  const departmentId = location.state.departmentId;
  const payload = location.state.payload;
  const serviceItemOrder = location.state.serviceItemOrder;
  const totalQuantity = location.state.totalQuantity;
  const isFrom = location.state.isFrom;
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const todayDate = new Date();
  const end = new Date();
  const tomorrowDate = end.setDate(end.getDate() + 1);
  const [deliveryData, setDeliveryData] = useState({
    order: '',
    pickupDate: '',
    deliveryDate: '',
  });
  const [open, setOpen] = React.useState(false);
  const buid = localStorage.getItem('buid');
  const key = localStorage.getItem('key');
  const [pickupDate, setPickupDate] = useState(dayjs(todayDate));
  const [deliveryDate, setDeliveryDate] = useState(dayjs(tomorrowDate));
  const [orderNote, setOrderNote] = useState('');
  const handlePickupDateChange = (date: any) => {
    setPickupDate(date);
    setDeliveryDate(date.add(1,'day'))
  };
  const handleDeliveyDateChange = (date: any) => {
    setDeliveryDate(date);
  };
  const { dispatch, serviceItemTax } = useContext(UserDataContext);
  const handleBack = () => {
    if (isFrom === 'BusinessStoreOrder') {
      navigate(`../businessStoreOrder/clothes-selection?key=${key}`, {
        state: {
          orderDetails: {
            departmentId: departmentId,
          },
        },
      });
    } else {
      navigate(`/business-checkout?key=${key}`);
    }
  };
  const handleServiceTypeData = (id: string) => {
    setServiceTypeId(id);
    const data: any = serviceTypeList.find((e: any) => e.id === +id);
    setChargePercentage(data.percentage);
    const charge = (totalPrice * data.percentage) / 100;
    setServiceCharge(charge);
    setDeliveryDate(dayjs());
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    setOpen(true);
    const vat = (totalPrice * +serviceItemTax) / 100;
    setVatPercentage(vat);
  };

  useEffect(() => {
    const charge = (totalPrice * chargePertentage) / 100;
    setServiceCharge(charge);
    const vat = ((totalPrice + charge) * +serviceItemTax) / 100;
    setVatPercentage(vat);
  }, [vatPercentage, totalPrice, serviceCharge]);

  useEffect(() => {
    fetchBusinessStoreServiceTypeData();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    if (+serviceTypeId === 1) {
      if (currentHour >= 12) {
        const laterDate = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000);
        if(dayjs(currentDate) < pickupDate) {
          setDeliveryDate(dayjs(pickupDate));
        } else {
          setPickupDate(dayjs(currentDate));
          setDeliveryDate(dayjs(laterDate));
        } 
      } else {
        setPickupDate(dayjs(currentDate));
        setDeliveryDate(dayjs(currentDate));
      }
    } else {
      if (currentHour <= 24) {
        const laterDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        setPickupDate(dayjs(currentDate));
        setDeliveryDate(dayjs(laterDate));
      }
    }
  }, [serviceTypeId]);

  const fetchBusinessStoreServiceTypeData = async () => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_SERVICE_TYPE_DATA_GET}/${key}`,
    ).then((response) => {
      if (response.status === 200) {
        setServiceTypeList(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };
  const createBusinessCustomerOrder = () => {
    payload['serviceTypeId'] = serviceTypeId;
    payload['pickupDate'] = pickupDate.format('YYYY-MM-DD');
    payload['deliveryDate'] = deliveryDate.format('YYYY-MM-DD');
    payload['orderNote'] = orderNote;
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_CUSTOMER_ORDER_CREATE_POST}/${key}`,
      payload,
    )
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: 'UPDATE_USER_DATA',
            payload: {
              userName: '',
              phoneNumber: '',
              email: '',
              address: '',
              state: '',
              zipCode: '',
              city: '',
              country: '',
            },
          });
          dispatch({ type: 'SELECT_ITEM', payload: [] });
          dispatch({ type: 'SELECT_ITEM_TAX', payload: '' });
          if (response?.data) {
            setOpen(false);
            setIsSuccessModal(true);
            setDeliveryData({
              order: response.data.order,
              pickupDate: pickupDate.format('YYYY-MM-DD'),
              deliveryDate: deliveryDate.format('YYYY-MM-DD'),
            });
            setOrderNote('');
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const createBusinessOrder = async () => {
    const serviceItems = serviceItemOrder?.map((item: any) => {
      return {
        specialInstructions: 'string',
        amount: item.price * item.quantity,
        itemCount: item.quantity,
        categoryId: item.category.id,
        serviceItemId: item.id,
      };
    });
    const data = {
      businessId: buid,
      businessStoreId: 0,
      serviceItemOrder: serviceItems,
      departmentId: +departmentId,
      serviceTypeId: +serviceTypeId,
      pickupDate: pickupDate.format('YYYY-MM-DD'),
      deliveryDate: deliveryDate.format('YYYY-MM-DD'),
      orderNote: orderNote,
    };
    try {
      const response = await httpPost(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_CUSTOMER_ORDER_CREATE_POST}/${key}`,
        data,
      );
      if (response.status === 200) {
        dispatch({
          type: 'UPDATE_USER_DATA',
          payload: {
            userName: '',
            phoneNumber: '',
            email: '',
            address: '',
            state: '',
            zipCode: '',
            city: '',
            country: '',
          },
        });
        dispatch({ type: 'SELECT_ITEM', payload: [] });
        dispatch({ type: 'SELECT_ITEM_TAX', payload: '' });
        setOpen(false);
        setIsSuccessModal(true);
        setOrderNote('');
        setDeliveryData({
          order: response.data.order,
          pickupDate: pickupDate.format('YYYY-MM-DD'),
          deliveryDate: deliveryDate.format('YYYY-MM-DD'),
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const createRetailCustomerOrder = () => {
    payload['serviceTypeId'] = serviceTypeId;
    payload['pickupDate'] = pickupDate.format('YYYY-MM-DD');
    payload['deliveryDate'] = deliveryDate.format('YYYY-MM-DD');
    payload['orderNote'] = orderNote;
    const key = localStorage.getItem('key');
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.RETAILSTORE_CUSTOMER_ORDER_DATA_POST}/${key}`,
      payload,
    )
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: 'UPDATE_USER_DATA',
            payload: {
              userName: '',
              phoneNumber: '',
              email: '',
              address: '',
              state: '',
              zipCode: '',
              city: '',
              country: '',
            },
          });
          dispatch({ type: 'SELECT_ITEM', payload: [] });
          dispatch({ type: 'SELECT_ITEM_TAX', payload: '' });
          if (response?.data) {
            setOpen(false);
            setIsSuccessModal(true);
            setOrderNote('');
            setDeliveryData({
              order: response.data.order,
              pickupDate: pickupDate.format('YYYY-MM-DD'),
              deliveryDate: deliveryDate.format('YYYY-MM-DD'),
            });
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const submitOrder = () => {
    if (isFrom === 'BusinessCustomerOrder') {
      createBusinessCustomerOrder();
    } else if (isFrom === 'BusinessStoreOrder') {
      createBusinessOrder();
    } else {
      createRetailCustomerOrder();
    }
  };
  return (
    <Grid container position={'relative'} top={'-10px'}>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '5px 15px 15px',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
          <Link
            component="button"
            title="back to clothes selection"
            onClick={handleBack}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              style={{ color: '#ffffff' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            color={'white'}
            sx={{ padding: '3px' }}
          >
            Select Service Type
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ padding: '20px' }}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                key="serviceType"
                label="Select Service Type"
                name="serviceType"
                className="mt-3"
                select
                onChange={(e) => {
                  handleServiceTypeData(e.target.value);
                }}
                value={serviceTypeId}
              >
                {serviceTypeList.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {+serviceTypeId === 1 && (
              <div className="mx-3">
                <InfoIcon color="warning" sx={{ fontSize: '1rem' }} />
                <span style={{ fontSize: '0.8rem' }}>
                  Same day delivery charges apply
                </span>
              </div>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Pickup Date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="pickupDate"
                  value={dayjs(pickupDate)}
                  // minDate={dayjs(todayDate)}
                  onChange={handlePickupDateChange}
                  sx={{ marginRight: '23px', width: '48%' }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Delivery date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="deliveryDate"
                  value={dayjs(deliveryDate)}
                  minDate={dayjs(pickupDate)}
                  onChange={handleDeliveyDateChange}
                  sx={{ width: '48%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className="mt-3">
          <TextField
            id="outlined-helperText"
            label="Add Notes"
            fullWidth
            value={orderNote}
            onChange={(e) => setOrderNote(e.target.value)}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <div
        style={{
          height: '42vh',
          overflow: 'auto',
          padding: '5px 20px',
          width: '100%',
        }}
      >
        <Typography className="body1">Order Details</Typography>
        <Card
          sx={{ minWidth: 275, maxHeight: '250px', overflow: 'auto' }}
          elevation={2}
        >
          <CardContent>
            {serviceItemOrder?.length > 0 &&
              serviceItemOrder.map((item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '15px', color: 'black' }}
                    >
                      {isFrom !== 'BusinessStoreOrder'
                        ? `${item?.quantity} X ${item?.name}`
                        : `${item?.name}`}
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '15px', color: 'black' }}
                    >
                      {isFrom !== 'BusinessStoreOrder'
                        ? `AED ${(item?.price * item?.quantity).toFixed(2)}`
                        : `${item?.quantity}`}
                    </DialogContentText>
                  </Grid>
                </Grid>
              ))}
            {isFrom !== 'BusinessStoreOrder' && (
              <>
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      Service Charge {chargePertentage} %
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      AED {serviceCharge.toFixed(2)}
                    </DialogContentText>
                  </Grid>
                </Grid>
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      Subtotal ({totalQuantity} items){' '}
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      AED {(totalPrice + serviceCharge).toFixed(2)}
                    </DialogContentText>
                  </Grid>
                </Grid>
                <Grid
                  container
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      Vat {serviceItemTax} %
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <DialogContentText
                      sx={{ fontSize: '14px', color: 'black' }}
                    >
                      AED {vatPercentage.toFixed(2)}
                    </DialogContentText>
                  </Grid>
                </Grid>
              </>
            )}
            <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
            <Grid
              container
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
            >
              <Grid item>
                {isFrom !== 'BusinessStoreOrder' ? (
                  <Typography variant="h5">Total</Typography>
                ) : (
                  <Typography variant="h5">Total Count</Typography>
                )}
              </Grid>
              <Grid item>
                {isFrom !== 'BusinessStoreOrder' ? (
                  <Typography variant="h5">
                    AED{' '}
                    {Number(totalPrice + vatPercentage + serviceCharge).toFixed(
                      2,
                    )}
                  </Typography>
                ) : (
                  <Typography variant="h5">{totalQuantity} items </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: ' 20px',
          left: '50%',
          transform: ' translateX(-50%)',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomButton
              fullWidth
              color="primary"
              onClick={submitOrder}
              variant="contained"
              // sx={{
              //   p: '.5rem',
              //   textAlign: 'bottom',
              //   borderRadius: '20px 20px 20px 20px',
              // }}
              label={'Confirm & Submit >>'}
            />
          </Grid>
        </Grid>
      </div>
      {isSuccessModal && (
        <SuccessModal
          isOpen={isSuccessModal}
          closeModal={setIsSuccessModal}
          deliveryData={deliveryData}
          isBusiness={isFrom === 'BusinessCustomerOrder'}
          isBusinessStoreOrder={isFrom === 'BusinessStoreOrder'}
        />
      )}
    </Grid>
  );
};
