import React, { useState } from 'react'
import { CreateForm } from '../../../../shared/components/create-form/CreateForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { AddPaymentDetail } from '../../model/payment';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';

export const AddCustomerPayment =() =>{
  const navigate = useNavigate();
  const location = useLocation();
  const { httpPost , handleAlertBar } = useAPI();
  const paymentFormField = location?.state?.paymentFormField || location?.state;
  const orderId = location?.state?.orderId;
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

    // POST with API

    const handleAddPayment = (values: AddPaymentDetail) => {
      if (!Object.keys(validationErrors).length) {
        values.paymentAmount = +values.paymentAmount;
         httpPost(WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_PAYMENT_POST, values ).then((response) => {
           console.log( 'RESPONSE :',response);
            if (response.status === 200) {
                console.log(response.data)
                handleAlertBar("success", `Payment added Successfully`);
                onClose();
            } else {
               if(response.data.error){        
               console.error('Error Updating BusinessStore: ', response.data.message);
               }
            }
         });
       }
   };


  const onClose = () => {
    navigate('../order-details', {state: {orderId}});
  }
  return (
      <CreateForm 
        columns={paymentFormField}
        onClose={orderId ? onClose : null}
        onSubmit={handleAddPayment}
        ButtonLabel={'Add Payment'} />
  )
}