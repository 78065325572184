import React, { useCallback, useMemo, useState, useEffect } from 'react';
import './SideBar.scss';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SideBar = () => {
  const role = localStorage.getItem('userRole');
  const location = useLocation();
  return (
    <div className="sideBar">
      <div
        className={`sideBarContent ${location.pathname === '/collectionCenter/dashboard' ? 'active' : ''}`}
      >
        <NavLink to="/collectionCenter/dashboard">Dashboard</NavLink>
      </div>
      <div className="sideBarContent">
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
            paddingLeft: '1rem',
          }}
        >
          <AccordionSummary
            sx={{ padding: '0px', textAlign: 'center' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            HR & Items Master
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to="/collectionCenter/employeeSetup">
              Employee Setup
            </NavLink>
            <NavLink to="/" style={{ marginTop: '10px' }}>
              Update Items
            </NavLink>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider sx={{ opacity: 0.5, margin: '20px 0px' }} />

      <div
        className={`sideBarContent ${location.pathname === '/' ? 'active' : ''}`}
      >
        <NavLink to="/">Stock Movement</NavLink>
      </div>
      <div
        className={`sideBarContent ${location.pathname === '/collectionCenter/collectionPoint' ? 'active' : ''}`}
      >
        <NavLink to="/collectionCenter/collectionPoint">
          Collection Point
        </NavLink>
      </div>
      <div
        className={`sideBarContent ${location.pathname === '/' ? 'active' : ''}`}
      >
        <NavLink to="/">Collection Point2</NavLink>
      </div>
      <div
        className={`sideBarContent ${location.pathname === '/' ? 'active' : ''}`}
      >
        <NavLink to="/">Generated GRN</NavLink>
      </div>
      <div
        className={`sideBarContent ${location.pathname === '/' ? 'active' : ''}`}
      >
        <NavLink to="/">Operational Reports</NavLink>
      </div>
    </div>
  );
};
export default SideBar;
