import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ICollectionTable } from '../models/CollectionPoint';

interface ICollectionTableProps {
  barcodeDetails: ICollectionTable;
}

const CollectionTable = (props: ICollectionTableProps) => {
  const { barcodeDetails } = props;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Employee No</TableCell>
            <TableCell>Barcode</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Product Id</TableCell>
            <TableCell>Camp Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{barcodeDetails.employeeNo}</TableCell>
            <TableCell>{barcodeDetails.barCode}</TableCell>
            <TableCell>{barcodeDetails.productName}</TableCell>
            <TableCell>{barcodeDetails.productId}</TableCell>
            <TableCell>{barcodeDetails.campId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(CollectionTable);
