import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { ILaundryCartProps, LaundryCartContext } from "./context/service.context";
import { WASHUB_CONSTANTS } from "../../shared/Constants";
import { IServiceItem } from "../../shared/models/ServiceItems.model";
import { useAPI } from "../../shared/services/api/API";
import { useLocation, useNavigate } from "react-router-dom";
import Order from "./components/Order";
export const CustomerCardOrder = (Props: any) => {
  const { httpGet, httpPost } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState<IServiceItem[]>([]);
  const customerDetail = location?.state?.order;
  const [orderId, setOrderId] = useState(customerDetail?.orderId);
  const {
    data: cartData,
    setData,
    setClientInfo,
  } = useContext(LaundryCartContext);

    // GET with API
    const fetchServiceItem = async () => {
        await httpGet(WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_SERVICEITEM_DATA_GET)
          .then((response: any) => {
            if (response.status === 200) {
              setServiceData(() => transformData(response?.data?.retailServiceItem));
            }
          })
          .catch(() => {
            setData(() => []);
          });
    };

    const transformData = (data: any) =>{
      return  data.reduce((acc: any, item: any) => {
        item.serviceItemSection.forEach((service: any, index: number)=> {
          service?.optiServiceItem.forEach((optiServiceItem: any, i: number)=>{
            acc.push({
            item: optiServiceItem?.name,
            price: optiServiceItem?.price,
            currency: 'AED: ', // required in BE
            quantity: 0,
            category: optiServiceItem?.category?.name,
            icon: optiServiceItem?.mediaItem?.url,
            id: optiServiceItem?.id,
            itemId: optiServiceItem?.id,
            categoryId: optiServiceItem?.category?.id,
            serviceItemSectionName: service?.serviceItemSectionName
          });
        });
      });
      return acc;
    }, []);
    }

    // Selected Business Store
    useEffect(() => {
        if (customerDetail.customer) {
            setClientInfo((pv: ILaundryCartProps['clientInfo']) => {
              return {
                ...pv,
                userName: customerDetail.customer?.firstName,
                userId: customerDetail.customer?.id,
                address: [
                  customerDetail.customer?.address,
                  customerDetail.customer?.address2,
                  customerDetail.customer?.city,
                  customerDetail.customer?.state,
                  customerDetail.customer?.zipCode,
                ].join(', '),
                phoneNumber: customerDetail.customer?.phone,
              };
            });
          }
      fetchServiceItem();
    },[]);

    useEffect(() => {
        if (orderId) {
        //   fetchRetailStoreOrder(orderId);
        } else {
          setData(() => serviceData);
        }
      }, [serviceData, orderId]);

  const onSubmit = () => {
    const itemData = cartData.filter((item: any) => item?.quantity > 0);
    const payload: any = {
      customer: {
        firstName: customerDetail?.customer?.firstName,
        lastName: customerDetail?.customer?.lastName,
        phone: customerDetail?.customer?.phone,
        address: customerDetail?.customer?.address,
        address2: customerDetail?.customer?.address2,
        state: customerDetail?.customer?.state,
        country: customerDetail?.customer?.country,
        zipCode: customerDetail?.customer?.zipCode,
        mail: customerDetail?.customer?.mail,
      },
      deliveryAddress: {
        phone: customerDetail?.customer?.phone,
        address: customerDetail?.customer?.address,
        address2: customerDetail?.customer?.address2,
        state: customerDetail?.customer?.state,
        country: customerDetail?.customer?.country,
        zipCode: customerDetail?.customer?.zipCode,
        mail: customerDetail?.customer?.mail,
        city: customerDetail?.customer?.city,
      },
      pickupAddress: {
        phone: customerDetail?.customer?.phone,
        address: customerDetail?.customer?.address,
        address2: customerDetail?.customer?.address2,
        state: customerDetail?.customer?.state,
        country: customerDetail?.customer?.country,
        zipCode: customerDetail?.customer?.zipCode,
        mail: customerDetail?.customer?.mail,
        city: customerDetail?.customer?.city,
      },
      serviceItemOrder: itemData.map((item: any) => {
        return {
          specialInstructions: 'string',
          amount: +item?.price * +item?.quantity,
          itemCount: item?.quantity,
          categoryId: item?.categoryId,
          serviceItemId: item?.itemId,
        };
      }),
    };
    navigate("../pick-and-drop", { state: { payload: payload, orderId: orderId }});
  }



    return (
        <div style={{ height: '89vh', overflow: 'auto' }}>
        {cartData?.length ? <Order submit={onSubmit} /> : null}
      </div>
    )
}
