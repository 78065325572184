/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export interface IUserOrderProps {
  userInfo: {
    userName: string;
    userId?: string;
    address: string;
    phoneNumber: string;
    email: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
  };
  dispatch: (action: any) => void
  serviceItem: [];
  serviceItemTax: string;
}

export const UserOrderData: IUserOrderProps = {
  userInfo: { userName: '', phoneNumber: '', email: '', address: '', state: '', zipCode: '', city:'',country: '' },
  dispatch: (action: any) => {},
  serviceItem: [],
  serviceItemTax: '',
};

export const UserDataContext = React.createContext<IUserOrderProps>({
  ...UserOrderData,
});
