import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

interface ISuccessModalProps {
  isOpen: boolean;
  closeModal: (isClosed: boolean) => void;
  deliveryData: any;
  isBusiness?: boolean;
  isBusinessStoreOrder?: boolean
  isPickupOrder?: boolean
  isBusinessOrder?: boolean
}

const SuccessModal = (props: ISuccessModalProps) => {
  const { isOpen, closeModal, deliveryData, isBusiness, isBusinessStoreOrder, isPickupOrder, isBusinessOrder } = props;
  const key = localStorage.getItem('key');
  const navigate = useNavigate();
  const handleClose = () => {
    closeModal(false);
    console.log('isBusiness', isBusiness);
    if (!isBusiness && !isBusinessStoreOrder && !isPickupOrder && !isBusinessOrder) {
      navigate(`/retailCustomerOrder?key=${key}`);
    } else if (isBusiness) {
      navigate(`/businessCustomerOrder?key=${key}`);
    } else if(isBusinessStoreOrder){
      navigate(`/businessStoreOrder?key=${key}`);
    } else if(isPickupOrder) {
      navigate(`/orderCenter/home`);
    } else if(isBusinessOrder) {
      navigate(`/business-store-order`, {state: {order: {
        business: {id: deliveryData.businessId},
        businessStore: {id: deliveryData.businessStoreId},
        department: {id: deliveryData.departmentId}
    }}});
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: '35px',
            maxWidth: '500px',
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                fontSize: '20px',
                textAlign: 'center',
                display: 'block',
              }}
            >
              Your order is successfully submited. Here is the order number
            </span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 900,
                textAlign: 'center',
                display: 'block',
              }}
            >
              {deliveryData?.order}
            </span>
            <span
              style={{
                fontSize: '20px',
                textAlign: 'center',
                display: 'block',
              }}
            >
              Your order will be picked up on 
            </span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 900,
                textAlign: 'center',
                display: 'block',
              }}
            >
              {deliveryData?.pickupDate}
            </span>
            <span
              style={{
                fontSize: '20px',
                textAlign: 'center',
                display: 'block',
              }}
            >
              and will be delivered on 
            </span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 900,
                textAlign: 'center',
                display: 'block',
              }}
            >
              {deliveryData?.deliveryDate}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button sx={{ fontSize: '20px' }} onClick={handleClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SuccessModal;
