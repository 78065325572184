import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const Dashboard = (props: Props) => {
  return <div>Dashboard page</div>;
};

export default Dashboard;
