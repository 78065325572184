import { Button, MenuItem, Stack, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { CustomerInvoiceList } from './customer-invoice-list/CustomerInvoiceList';
import { MRT_ColumnDef } from 'material-react-table';
import { IRetailInvoice } from '../../../shared/models/RetailOrder.model';
import { BusinessContext } from '../../../shared/BusinessContext';
import { ICustomerDetails } from '../../../shared/models/CustomerDetails.model';

export const CustomerInvoices = () => {
  const { httpGet, handleAlertBar } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const type = localStorage.getItem('userType');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tableData, setTableData] = useState<IRetailInvoice[]>([]);
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
    searchString: searchQuery,
  };
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    fetchAllCustomerOrderInvoice();
  }, []);

  // GET with API : Retail Store Order
  const fetchAllCustomerOrderInvoice = async () => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_INVOICES_DETAILS_GET,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Customer Order Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<IRetailInvoice>[]>(
    () => [
      {
        accessorKey: 'invoiceId',
        header: 'Invoice Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'invoiceAmount',
        header: 'Invoice Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

  //Generate Customer Invoice
  const generateCustomerInvoice = () => {
    navigate('generate-invoices');
  };

  return (
    <div>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          width: '100%',
          padding: '15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}
      >
        <TextField
          style={{ width: '30%' }}
          id="searchInput"
          value={searchQuery}
          onChange={handleSearchInputChange}
          variant="outlined"
          placeholder="Search Customer Invoice"
        />

        <div style={{ width: '40%' }}></div>

        <div style={{ width: '30%' }}>
          <Button
            fullWidth
            variant="contained"
            onClick={generateCustomerInvoice}
          >
            Generate Customer Invoice
          </Button>
        </div>
      </Stack>
      <CustomerInvoiceList tableData={tableData} columns={columns} />
    </div>
  );
};
