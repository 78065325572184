import { RoleDeatils } from './Role';

export const data: RoleDeatils[] = [
  {
    id: '9s41rp',
    name: 'Mohan_Admin',
    description: 'if you want to ignore the error, you can just use data[summaryType as any] even if this is not a good practice since you are introducing potential bugs in your code that the TS checker is avoiding.'
  },
  {
    id: '08m6rx',
    name: 'Mohan_admin_2',
    description: 'if you want to ignore the error, you can just use data[summaryType as any] even if this is not a good practice since you are introducing potential bugs in your code that the TS checker is avoiding.'
  },
];
