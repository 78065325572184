import React, { useEffect } from 'react';
import { UserOrderData, UserDataContext } from './service.context';

const LOCAL_STORAGE_USER_DATA_KEY = 'userData';
const LOCAL_STORAGE_ITEMS_DATA_KEY = 'itemDetail';
const LOCAL_STORAGE_ITEMS_TAX_KEY = 'itemTax'; 

export const UserDataProvider = React.memo(function UserDataProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [userInfo, setClientInfo] = React.useState({ userName: '', phoneNumber: '', email: '', address: '', state: '', zipCode: '', city:'',country: '' });
  const [ serviceItem, setServiceItemOrder] = React.useState<any>([]);
  const [ serviceItemTax, setServiceTax ] = React.useState('');
  const dispatch = (action: any) => {
    switch (action.type) {
        case 'UPDATE_USER_DATA':
            setClientInfo(action.payload);
            break;
        case 'SELECT_ITEM': 
            setServiceItemOrder(action.payload);
            break;
        case 'SELECT_ITEM_TAX': 
            setServiceTax(action.payload);
            break;
        default:
            break;
    }
  };
  return (
    <UserDataContext.Provider
      value={{
        userInfo,
        serviceItem,
        serviceItemTax,
        dispatch
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
});
