import React, { createContext, useMemo, useState } from "react";
import { AuthenticationContextModel } from "./models/ContextModels";
import { WASHUB_CONSTANTS } from "./Constants";

export const AuthenticationContext = createContext(
  {} as AuthenticationContextModel
);

export const AuthenticationProvider = ({ children }: any) => {
  const [token, setToken] = useState<string | null>(
    null
  );
  const [userRole, setUserRole] = useState<string | null>(
    null
  );

  const [userType, setUserType] = useState<string | null>(
    null
  );

  const urlPath = window.location.pathname;

  const updateToken = (value: string | null) => {
    setToken(value);
  };
  const updateUserRole = (value: string | null) => {
    setUserRole(value);
  };

  const updateUserType = (value: string | null) => {
    setUserType(value);
  };


  const authProviderValues = useMemo(
    () => ({ token, userRole, userType, updateToken, updateUserRole,updateUserType, urlPath }),
    [token, userRole]
  );

  return (
    <AuthenticationContext.Provider value={authProviderValues}>
      {children}
    </AuthenticationContext.Provider>
  );
};
