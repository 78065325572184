import React, { useContext } from "react";
import { AuthenticationContext } from "./Contexts";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: any) => {
  const { token } = useContext(AuthenticationContext);
  if (!token) {
    return <Navigate to="/" replace />;
  }
  return children;
};
