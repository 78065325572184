import React, { useContext, useEffect, useState } from 'react';
import { WASHUB_CONSTANTS } from '../../shared/Constants';
import { useAPI } from '../../shared/services/api/API';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IServiceItem } from '../../shared/models/ServiceItems.model';
import {
  ILaundryCartProps,
  LaundryCartContext,
} from './context/service.context';
import Order from './components/Order';
import { AxiosResponse } from 'axios';
import {
  IOrderItem,
  IRetailOrderResponse,
  IRetailStore,
} from '../../shared/models/RetailOrder.model';
import { BusinessContext } from '../../shared/BusinessContext';

const RetailOrder = () => {
  const { httpGet, httpPost } = useAPI();
  const location = useLocation();
  const RetailStoreOrder = location?.state?.order;
  const navigate = useNavigate();
  const { retailStoreData } = useContext(BusinessContext);
  const [retailStoreId, setRetailStoreId] = useState('');
  const [serviceData, setServiceData] = useState<IServiceItem[]>([]);
  const [orderId, setOrderId] = useState(RetailStoreOrder?.orderId);
  const type = localStorage.getItem('userType');
  const [selectDeliveryAddress, setSelectDeliveryAddress] = useState('store');

  const {
    data: cartData,
    setData,
    setClientInfo,
  } = useContext(LaundryCartContext);

  // useEffect(() => {
  //   fetchRetailStore();
  //   return () => {
  //     setData(() => []);
  //   };
  // }, []);

  // edit retail Store order
  useEffect(() => {
    if (RetailStoreOrder?.retailStore?.id) {
      setRetailStoreId(RetailStoreOrder.retailStore?.id);
    }
  }, [RetailStoreOrder]);

  // Selected Business Store
  useEffect(() => {
    if (retailStoreId) {
      if (retailStoreData?.length) {
        const retailStore: IRetailStore | undefined = retailStoreData.find(
          (e: IRetailStore) => +e?.id === +retailStoreId,
        ) as unknown as IRetailStore;
        if (retailStore) {
          setClientInfo((pv: ILaundryCartProps['clientInfo']) => {
            return {
              ...pv,
              userName: retailStore?.name,
              userId: retailStore?.id,
              address: [
                retailStore?.address,
                retailStore?.address2,
                // retailStore?.city, not available in retail api
                retailStore?.state,
                retailStore?.zipCode,
              ].join(', '),
              phoneNumber: retailStore?.phone,
            };
          });
        }
      }
      fetchServiceItem();
    }
  }, [retailStoreId, retailStoreData]);

  // Service Item Order
  useEffect(() => {
    if (serviceData?.length && orderId) {
      fetchRetailStoreOrder(orderId);
    } else {
      setData(() => serviceData);
    }
  }, [serviceData, orderId]);

  // GET with API : Retail Store Order
  const fetchRetailStoreOrder = async (id: string) => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDERDETAILS_DATA_GET}`;
    } else if (type === 'Retail') {
      url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DETAILS_DATA_GET}`;
    }
    httpGet(url + '/' + id)
      .then((response: AxiosResponse<IRetailOrderResponse>) => {
        if (response.status === 200) {
          if (response?.data?.orderItems?.length) {
            /* edit order */
            serviceData?.map((item: any) => {
              const orderItem = response?.data?.orderItems.find(
                (e: IOrderItem) => e?.serviceItem.id === item?.itemId,
              );
              if (orderItem) {
                item.quantity = orderItem?.itemCount;
              }
            });
            setData(() => serviceData);
          } else {
            setData(() => serviceData);
          }
        }
      })
      .catch(() => {
        setData(() => []);
      });
  };

  // GET with API
  const fetchServiceItem = async () => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.SERVICEITEM_DATA_GET}`;
    } else if (type === 'Retail') {
      url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_SERVICEITEM__DATA_GET}`;
    }
    httpGet(url)
      .then((response: any) => {
        if (response.status === 200) {
          // const selectedItemArray = response?.data?.retailServiceItem.map(
          //   (e: any) => e.optiServiceItem,
          // );
          // const flattenedArray = selectedItemArray.flat();
          // const formattedData = flattenedArray?.map(
          //   (item: IServiceItem, i: number) => {
          //     return {
          //       item: item?.name,
          //       price: item?.price,
          //       currency: 'AED: ', // required in BE
          //       quantity: 0,
          //       category: item?.category?.name,
          //       icon: item?.mediaItem?.url,
          //       id: i,
          //       itemId: item?.id,
          //       categoryId: item?.category?.id,
          //     };
          //   },
          // );
          setServiceData((): any => transformData(response?.data?.retailServiceItem));
        }
      })
      .catch(() => {
        setData(() => []);
      });
  };

  const transformData = (data: any) =>{
    return  data.reduce((acc: any, item: any) => {
      item.serviceItemSection.forEach((service: any, index: number)=> {
        service?.optiServiceItem.forEach((optiServiceItem: any, i: number)=>{
          acc.push({
          item: optiServiceItem?.name,
          price: optiServiceItem?.price,
          currency: 'AED: ', // required in BE
          quantity: 0,
          category: optiServiceItem?.category?.name,
          icon: optiServiceItem?.mediaItem?.url,
          id: optiServiceItem?.id,
          itemId: optiServiceItem?.id,
          categoryId: optiServiceItem?.category?.id,
          serviceItemSectionName: service?.serviceItemSectionName
        });
      });
    });
    return acc;
  }, []);
  }

  // POST: Create and Edit Retail Store Order
  const createOrder = () => {
    const itemData = cartData.filter((item: any) => item?.quantity > 0);
    const payload: any = {
      retailStoreId: retailStoreId,
      customer: {
        firstName: RetailStoreOrder?.customer?.firstName,
        lastName: RetailStoreOrder?.customer?.lastName,
        phone: RetailStoreOrder?.customer?.phone,
        address: RetailStoreOrder?.customer?.address,
        address2: RetailStoreOrder?.customer?.address2,
        state: RetailStoreOrder?.customer?.state,
        country: RetailStoreOrder?.customer?.country,
        zipCode: RetailStoreOrder?.customer?.zipCode,
        mail: RetailStoreOrder?.customer?.mail,
      },
      storePickup: selectDeliveryAddress === 'store',
      storeDelivery: selectDeliveryAddress === 'store',
      serviceItemOrder: itemData.map((item: any) => {
        return {
          specialInstructions: 'string',
          amount: +item?.price * +item?.quantity,
          itemCount: item?.quantity,
          categoryId: item?.categoryId,
          serviceItemId: item?.itemId,
        };
      }),
    };
    if (!payload?.storeDelivery) {
      payload['deliveryAddress'] = {
        phone: RetailStoreOrder?.customer?.phone,
        address: RetailStoreOrder?.customer?.address,
        address2: RetailStoreOrder?.customer?.address2,
        state: RetailStoreOrder?.customer?.state,
        country: RetailStoreOrder?.customer?.country,
        zipCode: RetailStoreOrder?.customer?.zipCode,
        mail: RetailStoreOrder?.customer?.mail,
        city: RetailStoreOrder?.customer?.city,
      };
    }
    if (payload) {
      if (orderId) {
        httpPost(
          `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DATA_PUT}/${orderId}`,
          payload,
        )
          .then((response) => {
            if (response?.status === 200) {
              navigate('/retail-store-order', {
                state: { retailStoreId },
              });
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } else {
        httpPost(WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DATA_POST, payload)
          .then((response) => {
            if (response?.status === 200) {
              navigate('/retail-store-order', {
                state: {
                  retailStoreId,
                },
              });
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    }
  };

  return (
    <div style={{ height: '89vh', overflow: 'auto' }}>
      <Stack
        sx={{
          width: '100%',
          padding: '15px 15px 10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}
      >
        <TextField
          style={{width: '30%'}}
          key="retailStore"
          label="Select Retail Store"
          name="retailStore"
          select
          onChange={(e) => {
            setRetailStoreId(e?.target?.value);
          }}
          value={retailStoreId}
        >
          {retailStoreData?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <FormGroup
        sx={{ width: '100%', padding: '0px 20px', background: '#fffff' }}
      >
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={selectDeliveryAddress === 'store'}
                  onChange={() => setSelectDeliveryAddress('store')}
                />
              }
              label="Deliver to the store"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={selectDeliveryAddress === 'home'}
                  onChange={() => setSelectDeliveryAddress('home')}
                />
              }
              label="Deliver at the home"
            />
          </Grid>
        </Grid>
      </FormGroup>
      {cartData?.length ? <Order submit={createOrder} /> : null}
    </div>
  );
};

export default RetailOrder;
