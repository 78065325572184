import { Box, Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import React from 'react'
import { useNavigate } from 'react-router-dom';

export const BusinessOrderUnverifyList = (Props: any) => {
    const navigate = useNavigate();
    return (
      <div style={{padding:"5px 15px"}}>
      <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps={{
            sx: {
              height: '60vh',
            },
          }}
          muiTablePaperProps={{
            sx: {
              width: '100%',
              '& .css-1tbggly': {
                minHeight: '2px',
              },
            },
          }}
          localization={{noRecordsToDisplay: Props?.businessStoreId && Props?.businessId && Props?.departmentId || Props?.orderId && Props?.businessId ? 'No data Available' : 'Select a business & business store & department to display records'}}
          columns={Props?.columns}
          data={Props?.tableData}
          editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
          enableEditing
          positionActionsColumn="last"
          enableRowActions
          enableFullScreenToggle={false}
          enableExpandAll={false}
          enableHiding={false}
          enableColumnFilters={false}
          enableToolbarInternalActions={false}
          enableStickyHeader
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('order-details-verify', {
                    state: {
                      orderType: Props?.OrderType.Business,
                      order: row?.original,
                    },
                  });
                }}
              >
                Verify
              </Button>
            </Box>
          )}
        />
      </div>
  
    )
}