import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../shared/services/api/API';

export const CreateProcessingCenter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const { httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
      /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (!value && (name == 'description' || name == 'name')) {
      error = `${name} field is required`;
    }
    if (
      value &&
      value.length < 2 &&
      (name == 'description' || name == 'name')
    ) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (
      value &&
      isNumeric(value) &&
      (name == 'description' || name == 'name')
    ) {
      error = `The ${name} must be in letters`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log(values);
      createProcessingCenter(values);
      setValues({});
    }
  };

  const createProcessingCenter = (values: any) => {
    if (!Object.keys(validationErrors).length) {
      const requestBody = {
        name: values?.name,
        description: values?.description,
      };

      httpPost(
        WASHUB_CONSTANTS.API_URLS.PROCESSING_CENTER_CREATE_DATA_POST,
        requestBody,
      ).then((response: any) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New Processing Center Created Successfully`,
          );
          setValues({});
          backToServiceItemList();
        } else {
          if (response.data.error) {
            console.error(
              'Error Creating serviceitem: ',
              response.data.message,
            );
          }
          handleAlertBar('error', response.data.message);
        }
      });
    }
  };

  const backToServiceItemList = () => {
    navigate('..');
  };

  return (
    <div>
      <Grid container className="mb-2 me-3 ms-3">
        <Grid item>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToServiceItemList}
          >
            Processing Center
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Processing Center
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns?.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Create Processing Center
        </Button>
      </div>
    </div>
  );
};
