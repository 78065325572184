import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IRetailCustomerDetails } from '../../../../shared/models/RetailOrder.model';
import { OrderType } from '../../../../shared/Constants';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { ICustomerDetails } from '../../../../shared/models/CustomerDetails.model';

export const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableHiding: false,
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 140,
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
      size: 140,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
    },
    {
      accessorKey: 'address',
      header: 'Address',
      size: 140,
    },
    {
      accessorKey: 'address2',
      header: 'Address-2',
      size: 140,
    },
    {
      accessorKey: 'zipCode',
      header: 'Zipcode',
    },
    {
      accessorKey: 'mail',
      header: 'Email',
    },
    {
      accessorKey: 'state',
      header: 'State',
    },
    {
      accessorKey: 'country',
      header: 'Country',
    },
  ];

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const onSubmit = (values: ICustomerDetails) => {
    if (!Object.keys(validationErrors).length) {
      console.log(values);
      navigate('../card-details', {
        state: {
          edit: false,
          orderType: 'Customer Order',
          order: {
            customer: values,
          },
        },
      });
    }
  };

  const onClose = () => {
    navigate('..');
  };
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ width: '100%' }}>
        <div
          style={{
            backgroundColor: 'purple',
            color: 'white',
            padding: '10px',
            marginBottom: '20px',
            textAlign: 'center',
            borderBottom: '1px solid black',
          }}
        >
          {'Enter Customer Details'}
        </div>
        <div style={{ height: '69vh', overflow: 'auto', padding: '20px 20px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '80%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns?.map((column: any, index: number) =>
                column.header == 'ID' ? (
                  <></>
                ) : (
                  <TextField
                    key={index}
                    label={column.header}
                    name={column.accessorKey}
                    placeholder={
                      column.accessorKey === 'paymentDate'
                        ? 'YYYY-MM-DD'
                        : `Please enter ${column.header}`
                    }
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                ),
              )}
            </Stack>
          </form>
        </div>
        <div
          style={{
            margin: ' 1rem 1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {onClose ? <Button onClick={onClose}>Cancel</Button> : <div></div>}
          <Button
            color="secondary"
            onClick={() => onSubmit(values)}
            variant="contained"
          >
            {'order Laundry  >>'}
          </Button>
        </div>
      </div>
    </div>
  );
};
