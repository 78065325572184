import { MRT_ColumnDef, MRT_EditActionButtons, MaterialReactTable, MaterialReactTableProps } from 'material-react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { IOrderItem } from '../../../../shared/models/RetailOrder.model';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { BusinessContext } from '../../../../shared/BusinessContext';

export const RetailStoreOrderDetails = () => {
    const location = useLocation();
  const navigate = useNavigate();
  const orderId = location?.state?.order?.orderId || location?.state?.orderId;
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [tableData, setTableData] = useState<IOrderItem[]>([]);
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const { businessStatusList } = useContext(BusinessContext);
  const columns = useMemo<MRT_ColumnDef<IOrderItem>[]>(
    () => [
      {
        accessorKey: 'serviceItem.name',
        header: 'Item Name',
        enableEditing: false,
        size: 10,
      },
      {
        accessorKey: 'serviceItem.serviceCategoryName',
        header: 'Item Category',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        size: 10,
      },
      {
        accessorKey: 'itemCount',
        header: 'Total Count',
        enableEditing: false,
        enableHiding: true,
        size: 10,
      },
      {
        accessorKey: 'deliverItemCount',
        header: 'Delivery Count',
        enableEditing: false,
        size: 10,
      },
      {
        accessorKey: 'balanceItemCount',
        header: 'Balance Count',
        enableEditing: false,
        size: 10,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableEditing: false,
        size: 10,
      },
      {
        accessorKey: 'processItemCount',
        header: 'Process Count',
        enableEditing: true,
        size: 10,
      },
      {
        accessorKey: 'orderItemStatus',
        header: 'Status',
        size: 20,
      },
    ],
    [],
  );
    const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleSaveRowEdits: MaterialReactTableProps<IOrderItem>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        console.log(row, valuesDetail);
        const payload = {
          orderId: orderDetails.orderId,
          orderItemId: row.original.id,
          status: valuesDetail.orderItemStatus,
          processCount: valuesDetail.processItemCount,
        };
        httpPost(
          `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DETAILS_ITEM_STATUS_UPDATE_POST}`,
          payload,
        )
          .then((response) => {
            if (response?.status === 200) {
              handleAlertBar('success', `Status updated successfully`);
              fetchOrders(orderDetails.orderId);
              exitEditingMode();
            }
          })
          .catch((error: any) => {
            console.log('error', error);
            handleAlertBar('error', error);
          });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  //Get Order Detail By Order Id
  const fetchOrders = async (orderId: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DETAILS_DATA_GET +
        '/' +
        orderId,
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.orderItems?.length) {
            setOrderDetails(response?.data);
            setTableData(response?.data?.orderItems);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const generateRetailStoreOrderInvoice = () => {
    const payload = {
      clientId: orderDetails?.retailStore.client.id,
      retailStoreId: orderDetails?.retailStore.id,
      orderId: orderId
    }
    httpPost(WASHUB_CONSTANTS.API_URLS.RETAILSSTOREORDER_INVOICE_CREATE_POST, payload).then((response) => {

       if (response.status === 200) {
          fetchOrders(orderId);
          console.log('invoice response ---> ' + response.data)
       } else {
          if(response.data.error){        
          console.error('Error Updating Business: ', response.data.message);
          }
       }
    });
  }

  useEffect(() => {
      fetchOrders(orderId);
  }, []);

  const backToOrderList = () => {
    navigate('..', { state: { retailStoreId: orderDetails?.retailStore.id } });
  };

  const addPayment = () => {
    const paymentFormField = [
      {
        accessorKey: 'paymentDate',
        header: 'Payment Date',
      },
      {
        accessorKey: 'invoiceNo',
        header: 'Invoice No',
      },
      {
        accessorKey: 'purchaseOrder',
        header: 'Purchase Order',
      },
      {
        accessorKey: 'paymentStatus',
        header: 'Payment Status',        
      },
      {
        accessorKey: 'paymentType',
        header: 'Payment Type',
      },
      {
        accessorKey: 'transactionId',
        header: 'Transaction Id',        
      },
      {
        accessorKey: 'paymentReferenceNo',
        header: 'Payment Reference No.',        
      },
      {
        accessorKey: 'paymentAmount',
        header: 'Payment Amount',        
      }
    ];
    navigate('../add-payment',{
      state:{ paymentFormField, orderId }
    });
  }
  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={0.5}>
          <IconButton onClick={backToOrderList}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={8} lg={8.5}>
          <h4 style={{ marginTop: '5px' }}>
            Order Details: {orderDetails.orderId}
          </h4>
        </Grid>
        <Grid item xs={12} md={3} lg={3} display={'flex'}>
        {(orderDetails.orderStatus === 'INPACKAGING' || orderDetails.orderStatus === 'READYTODELIVER' || orderDetails.orderStatus === 'DELIVERED') ? 
        <Button variant="contained" onClick={generateRetailStoreOrderInvoice}>Generate Invoice</Button>
         : <Grid item xs={0} sm={3} md={3} lg={3}></Grid>}
          <Button style={{ marginLeft: '10px' }} variant="contained" onClick={addPayment}>
            Add Payment
          </Button>
        </Grid>
      </Grid>
      <hr></hr>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '5px 10px 10px 10px',
        }}
      >
        <Paper sx={{ width: '100%', height: '100%', padding: '10px' }}>
          <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography>
                Order Date: <span>{orderDetails.orderDate}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Total Item: <span>{orderDetails.totalItemCount}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Status: <span>{orderDetails.orderStatus}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Total Amount: <span>{orderDetails.totalAmount}</span>
              </Typography>
            </Grid>
          </Grid>
          <div style={{ marginTop: '10px' }}>
            <MaterialReactTable
              muiTableBodyRowProps={{
                sx: {
                  height: '5px',
                },
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  width: '100%',
                  '& .css-1tbggly': {
                    minHeight: '0px',
                  },
                  '& .css-10gei56': {
                    minHeight: '0px',
                  },
                },
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 20,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  height: '62vh',
                },
              }}
              columns={columns}
              data={tableData}
              enableFullScreenToggle={false}
              enableStickyHeader
              positionActionsColumn="last"
              enableRowActions
              enableColumnActions={false}
              enableColumnFilters={false}
              enableFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableHiding={false}
              enableDensityToggle={false}
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              renderEditRowDialogContent={({ table, row, internalEditComponents }) => (
                <>
                  <DialogTitle variant="h5" textAlign={'center'}>Edit</DialogTitle>
                  <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                  >
                    {columns.filter(column => 
                    column.accessorKey === 'processItemCount' || column.accessorKey === 'orderItemStatus').map((column) => (
                      <>
                      {column.accessorKey === 'processItemCount' && <TextField
                    key={column.accessorKey}
                    variant='standard'
                    label={column.header}
                    name={column.accessorKey}
                    defaultValue={row.original.processItemCount}
                    onChange={(e) =>
                      setValuesDetail({ ...valuesDetail, [e.target.name]: +e.target.value })
                    }
                  /> }
                  {column.accessorKey === 'orderItemStatus' && <div>
                  <Typography sx={{fontSize:"12px", color: "rgba(0, 0, 0, 0.6)"}}>Status</Typography>
                  <Select 
                  fullWidth
                  label={column.header}
                  variant='standard'
                  name={column.accessorKey}
                  defaultValue={row.original.orderItemStatus}
                  onChange={(e) =>
                    setValuesDetail({ ...valuesDetail, [e.target.name]: e.target.value })
                  }
                  >
                    {businessStatusList.map((item: any)=>(
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select> </div>}
                      </>
                ))}
                  </DialogContent>
                  <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                  </DialogActions>
                </>
              )}
              renderRowActions={({ cell, row, table }) => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '0.5rem',
                  }}
                >
                  <Button
                    color="secondary"
                    onClick={() => {
                      table.setEditingRow(row);
                    }}
                    
                    variant="contained"
                  >
                    Update 
                  </Button>
                </div>
              )}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}