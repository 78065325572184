import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Chip,
  TextField,
  Button,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "./personalAccount.scss";
import React, { useEffect, useState } from "react";
import { MyButton } from "../../../shared/components/Buttons/Button";
import { data } from './makeData';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";
import { Icons } from "../../../assets/icons";

export type ProfileAccountDetails = {
  id?: number;
  userId?: string;
  firstName: string;
  lastName: string;
  phone: string;
  position: string;
  roleId?: string;
  role?: any;
  departmentId?: string;
  client?: string;
};

export const ProfileAccount = () => {
  const { httpGet } = useAPI();
  const [selectedlanguage, setSelectedLanguages] = useState("");
  const [demoData, setDemoData] = useState<ProfileAccountDetails>({firstName: '',lastName: '',position:'',phone: ''});
  const [IsLodaing, setIsLodaing] = useState(true);
  const [isEdit, SetIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    position: "",
    phone: "",
    errors: {
      firstName: false,
      lastName: false,
      position: false,
      phone: false,
    },
  });

  const cancelButton = () => {
    SetIsEdit(false);
  };

  const editProfile = () => {
    SetIsEdit(true);
    setFormData({
      firstName: demoData?.firstName,
      lastName: demoData?.lastName,
      position: demoData?.position,
      phone: demoData?.phone,
      errors: {
        firstName: false,
        lastName: false,
        position: false,
        phone: false,
      },
    });
  };

  const isFormValid = () => {
    const isMobileValid = /^((\+\d{1,3}-)?\d{3}-\d{3}-\d{4}|\d{10})$/.test(
      formData.phone
    );

    return (
      formData.firstName.trim() !== "" &&
      isMobileValid
    );
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;
    if (name === "phone") {
      isError = !/^((\+\d{1,3}-)?\d{3}-\d{3}-\d{4}|\d{10})$/.test(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };
  const onFormSubmit = () => {
    const rolesId =
    demoData.role?.length > 0
        ? demoData.role.map((e: any) => e.id)
        : [];
    const payload = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      phone: formData?.phone,
      userId: demoData.userId,
      roles: rolesId,
      position: demoData?.position,
    };
    setFormData((prevData) => ({ ...prevData, success: true }));
    // updateProfileData(payload);
  };
  
  
  useEffect(() => {
    httpGet(WASHUB_CONSTANTS.API_URLS.PROFILE_DATA).then((response) => {
       if (response.status === 200) {
          setDemoData(response.data);
          setIsLodaing(false);
          console.log('demo:',demoData)
       } else {
          if(response.data.error){   
          setDemoData({firstName: '',lastName: '',position:'',phone: ''});     
          console.error('Error fetching department: ', response.data.message);
          }
       }
    })
  }, []);

  return (
    !IsLodaing ? (
    <div className="m-4">
      <Box display="flex" alignItems="center" marginTop={4} marginBottom={4}>
      <Grid container className="d-flex justify-content-between pt-1 pb-3">
          <Grid item xs md lg={9}>
            <Typography variant="h3" className="pt-2">
              Personal Account
            </Typography>
          </Grid>
          <Grid item xs md lg={3} className="d-flex justify-content-end">
            {
              (isEdit ? (
                <div>
                  <Button
                    variant="outlined"
                    className="m-2"
                    onClick={cancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="m-2"
                    onClick={onFormSubmit}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <Link component="button" title="Edit" onClick={editProfile}>
                  <EditIcon />
                </Link>
              ))}
          </Grid>
        </Grid>       
      </Box>
      <Grid container className="contents">  
      <Grid item xs={12} sm={6} md={3} className="left-section">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <img src={Icons.defaultPicture} alt="profile img" className="image" width={"100px"}  height={"100px"}/>

            </Grid>
          </Grid>
        </Grid>      
       <Grid item xs={12} sm={6} md={9} lg={9}className="right-section">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} className="organization-details">
              <Typography variant="subtitle1" className="subtitle">
                First Name
              </Typography>
              {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter First Name"
                      variant="standard"
                      name="firstName"
                      value={formData?.firstName}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.firstName}
                      helperText={
                        formData.errors.firstName &&
                        "Please Enter First Name"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="body1" className="content">{demoData?.firstName}</Typography>
                )}
            </Grid>
              <Grid item xs={12} sm={6} md={5} className="organization-details">
                <Typography variant="subtitle1" className="subtitle">
                  Last Name
                </Typography>
                {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter Last Name"
                      variant="standard"
                      name="lastName"
                      value={formData?.lastName}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.lastName}
                      helperText={
                        formData.errors.firstName &&
                        "Please Enter Last Name"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="body1" className="content">{demoData?.lastName}</Typography>
                )}
              </Grid>            
          
            <Grid item xs={12} sm={6} md={4} className="organization-details">
              <Typography variant="subtitle1" className="subtitle">
                Position
              </Typography>
              {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter Position"
                      variant="standard"
                      name="position"
                      value={formData?.position}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.position}
                      helperText={
                        formData.errors.position &&
                        "Please Enter Position"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="body1" className="content">{demoData?.position}</Typography>
                )}
            </Grid>            
            <Grid item xs={12} sm={6} md={4} className="organization-details">
              <Typography variant="subtitle1" className="subtitle">
                Phone Number
              </Typography>
              {isEdit ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      style={{ width: "60%" }}
                      required
                      placeholder="Enter Phone number"
                      variant="standard"
                      name="phone"
                      value={formData.phone}
                      disabled={true}
                      onChange={handleFormChange}
                      error={formData?.errors?.phone}
                      helperText={
                        formData?.errors?.phone && "Please enter valid Phone number"
                      }
                    />
                  </div>
                ) : (
                  <Typography variant="body1" className="content">{demoData?.phone}</Typography>
                )}
            </Grid>
          </Grid>
       </Grid>
      </Grid>
      </div>
    ):null
  );
};
