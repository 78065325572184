import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { QueryParamsModels } from "../models/APIModels";
import { useContext } from "react";
import { SpinnerContext } from "../../SpinnerContext";
import { AlertBarContext } from "../../AlertBarContext";
import { AlertColor } from "@mui/material";

export const useAPI = () => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { showAlertBar } = useContext(AlertBarContext);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  // request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.headers["isLoading"]) {
        toggleSpinner(true);
      }
      return config;
    },
    (error: AxiosError) => {
      console.error("API Request Error->", error);
      handleAlertBar("error", error?.message);
      return Promise.reject(error);
    }
  );
  // response interceptor
  axiosInstance.interceptors.response.use(
    function (response) {
      toggleSpinner(false);
      if (response && response.data && response.data.error) {
        if (response.data.status && response.data.status === 400) {
          handleAlertBar(
            "error",
            response.data.message
          );
        }
      }
      return response;
    },
    function (error: AxiosError) {
      console.error("API Response Error->", error);
      handleAlertBar("error", error?.message);
      toggleSpinner(false);
      return Promise.reject(error);
    }
  );

  const getRequestConfig = (
    params: QueryParamsModels | undefined,
    isSpinner: boolean | undefined = true,
  ) => {
    const requestConfig: AxiosRequestConfig = {
      validateStatus: () => true,
      params,
      headers: {
        Authorization: localStorage.getItem("jwtToken") || null,
        isLoading: isSpinner ? "BackDrop" : null,
      },
    };
    return requestConfig;
  };

  const httpGet = (
    url: string,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.get(url, getRequestConfig(params, isSpinner));
  };

  const httpPost = (
    url: string,
    data: any,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.post(url, data, getRequestConfig(params, isSpinner));
  };

  const httpPostMedia = (
    url: string,
    data: any,
    config: any
  ) => {
    return axiosInstance.post(url, data, config);
  };

  const httpPut = (
    url: string,
    data: any,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.put(url, data, getRequestConfig(params, isSpinner));
  };

  const httpDelete = (
    url: string,
    params?: QueryParamsModels,
    isSpinner?: boolean
  ) => {
    return axiosInstance.delete(url, getRequestConfig(params, isSpinner));
  };
  
  const handleAlertBar = (
    type: AlertColor,
    message: string,
    title?: string
  ) => {
    showAlertBar({
      open: true,
      type,
      message,
      title,
    });
  };

  return { axiosInstance, httpGet, httpPost, httpPut, httpDelete, httpPostMedia, handleAlertBar };
};
