import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../shared/services/api/API';
import { BusinessContext } from '../../../shared/BusinessContext';
import { Edit } from '@mui/icons-material';
import { BusinessDepartmentDetails } from '../models/BusinessDepartmentModel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const BusinessDepartment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const busId = location?.state?.busId;
  const storeId = location?.state?.storeId;
  const { handleAlertBar, httpGet, httpPost, httpPut } = useAPI();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<BusinessDepartmentDetails[]>([]);
  const { businessList } = useContext(BusinessContext);
  const [businessId, setBusinessId] = useState('');

  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const type = localStorage.getItem('userType');
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET}`;
    } else if (type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_FOR_BUSINESS_GET}`;
    }
    httpGet(url + '/' + id, queryParams).then((response) => {
      if (response.status === 200) {
        setBusinessStoreData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  useEffect(() => {
    if (businessStoreId) {
      fetchBusinessStoreDepartmentData(businessStoreId);
    }
  }, [businessStoreId]);

  useEffect(() => {
    if (storeId) {
      setBusinessStoreId(storeId);
      fetchBusinessStoreDepartmentData(storeId);
    }
  }, [storeId]);

  const fetchBusinessStoreDepartmentData = async (businessStoreId: string) => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.CLIENT_BUSINESS_DEPARTMENT_DATA_GET}/${businessStoreId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Store Department: ',
            response.data.message,
          );
        }
      }
    });
  };

  // Business Store
  useEffect(() => {
    if (businessId) {
      fetchBusinessStore(businessId);
    }
  }, [businessId]);

  useEffect(() => {
    if(busId) {
      setBusinessId(busId);
      fetchBusinessStore(busId);
    }
  }, [busId]);

  // PUT with API

  const handleSaveRowEdits: MaterialReactTableProps<BusinessDepartmentDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const payload = {
          name: values.name,
          description: values.description,
          contactName: values.contactName,
          contactPhone: values.contactPhone,
          businessId: businessId,
          businessStoreId: businessStoreId,
        };
        httpPut(
          WASHUB_CONSTANTS.API_URLS.CLIENT_BUSINESS_DEPARTMENT_DATA_PUT +
            '/' +
            values.id,
          payload,
        ).then((response) => {
          if (response.status === 200) {
            fetchBusinessStoreDepartmentData(businessStoreId);
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              console.error(
                'Error Updating Business Department: ',
                response.data.message,
              );
            }
          }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const mappedServiceItem = (row: MRT_Row<BusinessDepartmentDetails>) => {
    navigate('mapped-service-item',{state: {
      row: row.original
    }});
  }

  const columns = useMemo<MRT_ColumnDef<BusinessDepartmentDetails>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 140,
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',
      },
      {
        accessorKey: 'mapped',
        header: 'Mapped Service Item',
        enableEditing: false,
        Cell: ({row}) => (
                <Button
                color="primary"
                sx={{color: 'white'}}
                onClick={() => mappedServiceItem(row)}
                variant="contained"
              >
                Mapped Service Item
              </Button>
        ),
      },
    ],
    [],
  );

  const createNewBusinessDepartment = () => {
    const column = [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 140,
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',
      },
    ];
    navigate('create-business-department', {
      state: { column: column, businessId: businessId, businessStoreId: businessStoreId },
    });
  };

  return (
    <div>
        <Grid container>
          <Grid item className='mb-1 mt-1 ms-3'>
            <Typography sx={{textAlign: 'center'}} variant='h5' className='mt-1'>Business Department</Typography>
          </Grid>
        </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: '76%' }}>
          <TextField
            size='small'
            key="business"
            style={{ width: '40%', marginRight: '10px' }}
            label="Select Business"
            name="business"
            select
            onChange={(e) => {
              setBusinessId(e.target.value);
            }}
            value={businessId}
          >
            {businessList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          {businessId ? (
            <TextField
              size='small'
              key="businessStore"
              style={{ width: '40%' }}
              label="Select Business Store"
              name="businessStore"
              select
              onChange={(e) => {
                setBusinessStoreId(e.target.value);
              }}
              value={businessStoreId}
            >
              {businessStoreData.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
        </div>
        <Button
              size='small'
              color="primary"
              sx={{color: 'white'}}
              onClick={createNewBusinessDepartment}
              variant="contained"
            >
              Create Department
        </Button>
      </Stack>

      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps= {{
            sx: {
                height: "58vh"
            }
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="row"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
