import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { BusinessDeliveryItem } from '../../models/BusinessDelivery';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, IconButton, Paper } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const DeliveryItemDetails = () => {
  const { httpGet } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any>([]);
  const deliveryDetail = location.state?.rowData;
  // GET with API : Delivery Order Details
  const fetchDeliveryOrderDetails = async (id: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESS_DELIVERY_NOTE_DETAIL_GET + '/' + id,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<BusinessDeliveryItem>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'itemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'serviceItemName',
        header: 'Item Name',
      },
    ],
    [],
  );

  useEffect(() => {
    if (deliveryDetail?.deliveryBatch) {
      fetchDeliveryOrderDetails(deliveryDetail?.deliveryBatch);
    }
  }, [deliveryDetail?.deliveryBatch]);

  const backToDeliveryItemList = () => {
    navigate('..', { state: { deliveryDetail } });
  };

  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={1}>
          <IconButton onClick={backToDeliveryItemList}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={10} lg={9}>
          <h4 style={{ marginTop: '5px' }}>
            Delivery Order Details: {deliveryDetail?.orderId}
          </h4>
        </Grid>
      </Grid>
      <hr></hr>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '5px 10px 10px 10px',
        }}
      >
        <Paper sx={{ width: '100%', height: '100%', padding: '10px' }}>
          <div style={{ marginTop: '10px' }}>
            <MaterialReactTable
              muiTableBodyRowProps={{
                sx: {
                  height: '5px',
                },
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  width: '100%',
                  '& .css-1tbggly': {
                    minHeight: '0px',
                  },
                  '& .css-10gei56': {
                    minHeight: '0px',
                  },
                },
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 20,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  height: '67vh',
                },
              }}
              columns={columns}
              data={tableData}
              enableFullScreenToggle={false}
              enableStickyHeader
              positionActionsColumn="last"
              enableColumnActions={false}
              enableColumnFilters={false}
              enableFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableHiding={false}
              enableDensityToggle={false}
              enableEditing
              editDisplayMode="cell"
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};
