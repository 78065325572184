import closeX from "./x.svg";
import user from "./user.svg";
import xCircle from "./x-circle.svg";
import phone from "./phone.svg";
import defaultPicture from "./defaultPicture.svg";
export const Icons = {
  close: xCircle,
  closeX: closeX,
  user: user,
  phone: phone,
  defaultPicture: defaultPicture,
};
