/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Assets } from '../../../assets/laundry';
import { Categories } from '../../../shared/Constants';

export interface ICartData {
  item: string;
  price: number;
  currency: string;
  quantity: number;
  category: string;
  icon: any;
  id: string | number;
  itemId: string | number;
  categoryId: string | number;
}
export interface ILaundryCartProps {
  clientInfo: {
    userName: string;
    userId: string;
    address: string;
    phoneNumber: string;
  };
  setClientInfo: (value: any) => void;
  setData: (value: any) => void;
  data: ICartData[];
}

export const InitialLaundryCartData: ILaundryCartProps = {
  clientInfo: { userName: '', userId: '', address: '', phoneNumber: '' },
  setClientInfo: (value: any) => {},
  setData: (value: any) => {},
  data: [],
};

export const LaundryCartContext = React.createContext<ILaundryCartProps>({
  ...InitialLaundryCartData,
});
