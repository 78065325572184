import React, { useMemo, useState, useEffect, useContext } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, Button, Stack, TextField, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { data, orderStatus } from './makeData';
import { useAPI } from '../../../shared/services/api/API';
import { OrderType, WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useNavigate } from 'react-router-dom';
import { BusinessContext } from '../../../shared/BusinessContext';

export type CustomerOrderDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
  phone: string;
};

export const CustomerOrder = () => {
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tableData, setTableData] = useState<CustomerOrderDeatils[]>(
    () => data,
  );
  const navigate = useNavigate();
  const { businessStatusList } = useContext(BusinessContext);
  const [categoryDisplayData, setCategoryDisplayData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
    searchString: searchQuery,
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // GET with API : Customer Order
  const fetchCustomerOrder = async () => {
    setIsLoading(true);
    await httpGet(
      WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_DATA_GET,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching CustomerOrder: ',
            response.data.message,
          );
          setTableData([]);
        }
      }
    });
  };

  useEffect(() => {
    fetchCustomerOrder();
  }, []);

  const handleStatusChange = (event: any, row: any) => {
    const payload = {
      orderId: row.orderId,
      orderItemId: 0,
      status: event.target.value,
      processCount: row.totalItemCount,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_STATUS_UPDATE_POST}`,
      payload,
    )
      .then((response) => {
        if (response?.status === 200) {
          handleAlertBar('success', `Status updated successfully`);
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        handleAlertBar('error', error);
      });
  };

  const columns = useMemo<MRT_ColumnDef<CustomerOrderDeatils>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        enableRowActions: true,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'orderDate',
        header: 'Date',
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'orderStatus',
        header: 'Status',
        editVariant: 'select',
        editSelectOptions: businessStatusList,
        size: 30,
        muiEditTextFieldProps: ({ cell, row }) => ({
          select: true,
          onChange: (value: any) => handleStatusChange(value, row.original),
        }),
      },
    ],
    [businessStatusList],
  );

  return (
    <div style={{ height: '89vh',}}>
      <Stack
        sx={{
          width: '20%',
          padding: '15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}
      >
        <TextField
          id="searchInput"
          value={searchQuery}
          onChange={handleSearchInputChange}
          variant="outlined"
          placeholder="Search Customer Phone No."
        />
      </Stack>
      <div style={{ padding: '0px 15px 5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps= {{
            sx: {
                height: "60vh"
            }
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableColumnFilters={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableHiding={false}
          enableStickyHeader
          editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
          enableEditing
          enableRowActions
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => table.setEditingRow(row)}
              >
                Update
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  navigate('order-details', {
                    state: {
                      orderType: OrderType.Customer,
                      order: row?.original,
                    },
                  });
                }}
              >
                Details
              </Button>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button
              color="secondary"
              onClick={() => {
                navigate('customer-details');
              }}
              variant="contained"
            >
              Create Customer Order
            </Button>
          )}
        />
      </div>
    </div>
  );
};

export default CustomerOrder;
