import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_EditActionButtons,
} from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import { CostCenterDetails } from '../models/CostCenter.model';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../shared/services/api/API';
import { AlertDialog } from '../../../shared/components/alert-dialog/AlertDialog';

export const CostCenter = () => {
  const navigate = useNavigate();
  const { httpGet, httpPut, httpDelete, handleAlertBar } = useAPI();
  const [tableData, setTableData] = useState<CostCenterDetails[]>([]);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [deleteCostCenterData, setDeleteCostCenterData] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  const fetchCostCenter = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.COST_CENTER_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<CostCenterDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const payload = {
          description: valuesDetail?.description || row.original?.description,
          name: valuesDetail?.name || row.original?.name,
        };

        httpPut(
          WASHUB_CONSTANTS.API_URLS.COST_CENTER_UPDATE_DATA_PUT +
            '/' +
            row.original.id,
          payload,
        ).then((response) => {
          if (response.status === 200) {
            fetchCostCenter();
            setValuesDetail({});
            handleAlertBar('success', `This Cost Center updated Successfully`);
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              handleAlertBar('error', response.data.message);
              console.error(
                'Error Updating serviceitem: ',
                response.data.message,
              );
            }
          }
        });
      }
    };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const columns = useMemo<MRT_ColumnDef<CostCenterDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 50,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 50,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 140,
      },
    ],
    [],
  );
  const createNewCostCenter = () => {
    const column = [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 50,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 140,
      },
    ];
    navigate('create-cost-center', {
      state: {
        column: column,
      },
    });
  };
  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleDeleteCostCenter = (row: any) => {
    setDeleteCostCenterData(row);
    setIsAlertDialog(true);
  };
  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  // const deleteCostCenter = () => {
  //   httpDelete(
  //     `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_COST_CENTER_DATA_DELETE}/${deleteCostCenterData?.id}`,
  //   )
  //     .then((response: any) => {
  //       if (response?.status === 200) {
  //         fetchCostCenter();
  //         handleAlertDialogClose();
  //         handleAlertBar('success', `Price Master deleted sucessfully`);
  //       }
  //     })
  //     .catch((error: any) => {
  //       handleAlertBar('error', error);
  //     });
  // };

  useEffect(() => {
    fetchCostCenter();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Cost Center
          </Typography>
        </Grid>
        <Grid item className="mb-1 mt-1 me-3">
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={createNewCostCenter}
            variant="contained"
          >
            Create New Cost Center
          </Button>
        </Grid>
      </Grid>

      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="modal"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          muiTableContainerProps={{
            sx: {
              height: '64vh',
            },
          }}
          renderEditRowDialogContent={({
            table,
            row,
            internalEditComponents,
          }) => (
            <Dialog fullWidth open={true}>
              <DialogTitle
                sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
                variant="h5"
                textAlign={'center'}
                className="p-3 mb-3"
              >
                Update Cost Center
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  {columns.map((column) => (
                    <>
                      {column.accessorKey === 'name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.name}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'description' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.description}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <MRT_EditActionButtons variant="text" table={table} row={row} />
              </DialogActions>
            </Dialog>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Edit">
                <IconButton
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  onClick={() => handleDeleteCostCenter(row?.original)}
                >
                  <Delete />
                </IconButton>
              </Tooltip> */}
            </Box>
          )}
        />
      </div>
      {/* <AlertDialog
        open={isAlertDialog}
        title={'Delete Cost Center'}
        description={'Are you sure you want to delete this cost center ?'}
        submitBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        handleSubmit={deleteCostCenter}
        handleClose={handleAlertDialogClose}
      ></AlertDialog> */}
    </div>
  );
};
