import { Button, MenuItem, Stack, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { RetailInvoiceList } from './retail-invoice-list/RetailInvoiceList';
import { MRT_ColumnDef } from 'material-react-table';
import { IRetailInvoice } from '../../../shared/models/RetailOrder.model';
import { BusinessContext } from '../../../shared/BusinessContext';

export const RetailInvoices = () => {
  const { httpGet } = useAPI();
  const location = useLocation();
  const { retailStoreData } = useContext(BusinessContext);
  const [retailStoreId, setRetailStoreId] = useState('');
  const navigate = useNavigate();
  const type = localStorage.getItem('userType');
  const [tableData, setTableData] = useState<IRetailInvoice[]>([]);

  useEffect(() => {
    if (retailStoreId) {
      fetchAllRetailOrderInvoice(retailStoreId);
    }
  }, [retailStoreId]);

  useEffect(() => {
    if (location.state?.retailStoreId) {
      setRetailStoreId(location.state?.retailStoreId);
    }
  }, [location.state?.retailStoreId]);

  // GET with API : Retail Store Order
  const fetchAllRetailOrderInvoice = async (id: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_INVOICES_DETAILS_GET + '/' + id,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Retail Order Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<IRetailInvoice>[]>(
    () => [
      {
        accessorKey: 'invoiceId',
        header: 'Invoice Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'invoiceAmount',
        header: 'Invoice Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

    //Generate Retail Invoice
    const generateRetailInvoice = () => {
      navigate('generate-invoices', {
        state: { retailStoreId },
      });
    };

  return (
    <div>
      <Stack
        sx={{
          display:'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
        style={{width: "30%"}}
          key="retailStore"
          label="Select Retail Store"
          name="retailStore"
          select
          onChange={(e) => {
            setRetailStoreId(e?.target?.value);
          }}
          value={retailStoreId}
        >
          {retailStoreData?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <div style={{width: "40%"}}></div>

        {retailStoreId ? (
          <div style={{width: "30%"}}>
            <Button fullWidth variant="contained" onClick={generateRetailInvoice}>
              Generate Retail Invoice
            </Button>
          </div>
        ) : null}
      </Stack>
      <RetailInvoiceList
        tableData={tableData}
        columns={columns}
        retailStoreId={retailStoreId}
      />
    </div>
  );
}