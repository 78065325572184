import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';
import { UserDataContext } from '../../context/service.context';
import SuccessModal from '../../../../shared/SuccessModal';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';

export const PickAndDrop = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { httpPost, handleAlertBar } = useAPI();
  const [selectDeliveryAddress, setSelectDeliveryAddress] = useState('store');
  const [deliveryAddress, setDeliveryAddress] = useState({
    userName: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    errors: {
      userName: false,
      address: false,
      city: false,
      zip: false,
      state: false,
      country: false,
    },
  });
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const { userInfo, serviceItem, serviceItemTax, dispatch } =
    useContext(UserDataContext);
  const [subTotal, setSubTotal] = useState(0);
  const [totalQuantity, settotalQuantity] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [vatPercentage, setVatPercentage] = useState(0);
  const isBusinessOrder = location.pathname.includes('business-checkout');
  const buid = localStorage.getItem('buid');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if(isBusinessOrder) {
      createBusinessCustomerOrder();
    } else {
      createRetailCustomerOrder();
    }
    if (selectDeliveryAddress === 'home') {
      if (isFormValid()) {
        console.log('submit');
      } else {
        console.log('Please fill all required fields');
      }
    }
  };

  useMemo(() => {
    let total = 0;
    let quantity = 0;
    serviceItem?.map((item: any) => {
      total += item?.price * item?.quantity;
      quantity += item?.quantity;
    });
    setSubTotal(total);
    settotalQuantity(totalQuantity + quantity);
    const vat = (total * +serviceItemTax) / 100;
    setVatPercentage(vat);
    setGrandTotal(total + vat);
  }, [serviceItem]);

  const createRetailCustomerOrder = () => {
    const payload = {
      retailStoreId: 0,
      customer: {
        firstName: userInfo.userName,
        lastName: 'string',
        phone: userInfo?.phoneNumber,
        address: userInfo.address || deliveryAddress.address,
        address2: userInfo.city || deliveryAddress.city,
        state: userInfo.state || deliveryAddress.state,
        country: userInfo.country || deliveryAddress.country,
        zipCode: userInfo.zipCode || deliveryAddress.zip,
        mail: userInfo.email,
        clientKey: 'string',
      },
      pickupAddress: {
        address: 'string',
        address2: 'string',
        city: 'string',
        state: 'string',
        country: 'string',
        zipCode: 'string',
        mail: 'string',
        phone: 'string',
      },
      deliveryAddress: {
        address: deliveryAddress.address,
        address2: 'string',
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        country: deliveryAddress.country,
        zipCode: deliveryAddress.zip,
        mail: userInfo?.email,
        phone: userInfo?.phoneNumber,
      },
      storePickup: selectDeliveryAddress === 'store',
      storeDelivery: selectDeliveryAddress === 'store',
      serviceItemOrder: serviceItem.map((item: any) => {
        return {
          specialInstructions: 'string',
          amount: item?.price * item?.quantity,
          itemCount: item?.quantity,
          categoryId: item?.category?.id,
          serviceItemId: item?.id,
        };
      }),
    };
    navigate('/service-type', {
      state: {
        totalPrice: subTotal,
        serviceItemOrder: serviceItem,
        totalQuantity: totalQuantity,
        payload: payload,
        isFrom: "RetailCustomerOrder"
      },
    });
  };

  const createBusinessCustomerOrder = () => {
    const payload = {
      businessStoreId: 0,
      businessId: buid,
      customer: {
        firstName: userInfo.userName,
        lastName: 'string',
        phone: userInfo?.phoneNumber,
        address: userInfo.address || deliveryAddress.address,
        address2: userInfo.city || deliveryAddress.city,
        state: userInfo.state || deliveryAddress.state,
        country: userInfo.country || deliveryAddress.country,
        zipCode: userInfo.zipCode || deliveryAddress.zip,
        mail: userInfo.email,
        clientKey: 'string',
      },
      pickupAddress: {
        address: 'string',
        address2: 'string',
        city: 'string',
        state: 'string',
        country: 'string',
        zipCode: 'string',
        mail: 'string',
        phone: 'string',
      },
      deliveryAddress: {
        address: deliveryAddress.address,
        address2: 'string',
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        country: deliveryAddress.country,
        zipCode: deliveryAddress.zip,
        mail: userInfo?.email,
        phone: userInfo?.phoneNumber,
      },
      storePickup: selectDeliveryAddress === 'store',
      storeDelivery: selectDeliveryAddress === 'store',
      serviceItemOrder: serviceItem.map((item: any) => {
        return {
          specialInstructions: 'string',
          amount: item?.price * item?.quantity,
          itemCount: item?.quantity,
          categoryId: item?.category?.id,
          serviceItemId: item?.id,
        };
      }),
    };
    navigate('/service-type', {
      state: {
        totalPrice: subTotal,
        serviceItemOrder: serviceItem,
        totalQuantity: totalQuantity,
        payload: payload,
        isFrom: "BusinessCustomerOrder"
      },
    });
  };

  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  const handleBack = () => {
    if(isBusinessOrder) {
      navigate('/business-clothes-selection');
    } else {
      navigate('/clothes-selection');
    }
  };

  const isFormValid = () => {
    return (
      // deliveryAddress.userName.trim() !== '' &&
      deliveryAddress.address.trim() !== '' &&
      // deliveryAddress.city.trim() !== '' &&
      // deliveryAddress.state.trim() !== '' &&
      // deliveryAddress.country.trim() !== '' &&
      deliveryAddress.zip.trim() !== ''
    );
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;

    if (name === 'email') {
      isError = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    } else if (name === 'phoneNumber') {
      isError = !/^\d{10}$/.test(value) || value.length === 0;
    }
    console.log(name, value);
    setDeliveryAddress((prevData: any) => ({
      ...prevData,
      [name]: value,
      errors: { ...prevData.errors, [name]: isError },
    }));
  };

  return (
    <Grid container position={'relative'} top={'-10px'}>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '5px 15px 15px',
          // borderRadius: '0px 0px 35px 35px',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
          <Link
            component="button"
            title="back to user detail"
            onClick={handleBack}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              style={{ color: '#ffffff' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            color={'white'}
            sx={{ padding: '3px' }}
          >
            Enter Delivery Options
          </Typography>
        </Grid>
      </Grid>
      <FormGroup
        sx={{ width: '100%', padding: '0px 20px', background: '#fffff' }}
      >
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectDeliveryAddress === 'store'}
                  onChange={() => setSelectDeliveryAddress('store')}
                />
              }
              label="Deliver to the store"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectDeliveryAddress === 'home'}
                  onChange={() => setSelectDeliveryAddress('home')}
                />
              }
              label="Deliver at the Address"
            />
          </Grid>
        </Grid>
      </FormGroup>
      <div style={{ height: '58vh', overflow: 'auto', padding: '20px 0px' }}>
        <Grid container spacing={2} sx={{ padding: '0px 20px' }}>
          {selectDeliveryAddress === 'home' && (
            <>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography>Name: </Typography>
                <TextField
                  label="Name"
                  name="userName"
                  value={deliveryAddress.userName}
                  onChange={handleFormChange}
                  color="primary"
                  sx={{ width: '80%' }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography>Address: </Typography>
                <TextField
                  label="Address"
                  name="address"
                  required
                  value={deliveryAddress.address}
                  onChange={handleFormChange}
                  color="primary"
                  sx={{ width: '80%' }}
                  error={deliveryAddress.errors.address}
                  helperText={deliveryAddress.errors.address && 'Please enter address'}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography>City: </Typography>
                <TextField
                  label="City"
                  name="city"
                  value={deliveryAddress.city}
                  onChange={handleFormChange}
                  color="primary"
                  sx={{ width: '80%' }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography justifyContent={'center'}>ZipCode: </Typography>
                <TextField
                  label="Zip Code"
                  name="zip"
                  value={deliveryAddress.zip}
                  onChange={handleFormChange}
                  required
                  color="primary"
                  sx={{ width: '80%' }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography>State: </Typography>
                <TextField
                  label="State"
                  name="state"
                  value={deliveryAddress.state}
                  onChange={handleFormChange}
                  color="primary"
                  sx={{ width: '80%' }}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Typography>Country: </Typography>
                <TextField
                  label="Country"
                  name="country"
                  value={deliveryAddress.country}
                  onChange={handleFormChange}
                  color="primary"
                  sx={{ width: '80%' }}
                ></TextField>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: ' 20px',
          left: '50%',
          transform: ' translateX(-50%)',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomButton
              fullWidth
              color="primary"
              onClick={handleSubmit}
              variant="contained"
              disabled={selectDeliveryAddress === 'home' && !isFormValid()}
              // sx={{
              //   p: '.5rem',
              //   textAlign: 'bottom',
              //   borderRadius: '20px 20px 20px 20px',
              // }}
              label={'Confirm & Submit >>'}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
