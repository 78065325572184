import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Stack,
  MenuItem,
  Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useCollectionAPI } from '../hooks/useCollectionAPI';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';

export type EmployeeData = {
  employeeNo: string;
  name: string;
  location: string;
  designation: string;
  phone: string;
};

type FormData = {
  tgNumber: string;
  employeeName: string;
  selectedStore: string;
  designation: string;
};

const EmployeeSetup: React.FC = () => {
  const {
    allBusStores,
    getAllBusStores,
    createBusinessEmployee,
    getAllBusinessEmp,
    updateBusEmployee,
    deleteBusEmployee,
    allEmpData,
  } = useCollectionAPI();

  const [formData, setFormData] = useState<FormData>({
    tgNumber: '',
    employeeName: '',
    selectedStore: '',
    designation: '',
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [employeesData, setEmployeesData] = useState<EmployeeData[]>([]);

  useEffect(() => {
    const fetchStoresAndEmployees = async () => {
      await getAllBusStores();
      await getAllBusinessEmp();
    };
    fetchStoresAndEmployees();
  }, []);

  useEffect(() => {
    if (allBusStores.length > 0 && !formData.selectedStore) {
      setFormData((prevData) => ({
        ...prevData,
        selectedStore: allBusStores[0],
      }));
    }
  }, [allBusStores]);

  useEffect(() => {
    setEmployeesData(allEmpData);
  }, [allEmpData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCreateEmployee = () => {
    const employeeData = {
      employeeNo: formData.tgNumber,
      name: formData.employeeName,
      location: formData.selectedStore,
      designation: formData.designation,
      phone: '',
    };
    createBusinessEmployee(employeeData);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const toggleEditState = (id: string) => {
    setEditingRowId((prevId) => (prevId === id ? null : id));
  };

  const handleEditChange = (
    id: string,
    field: keyof EmployeeData,
    value: string,
  ) => {
    setEmployeesData((prevData) =>
      prevData.map((emp: any) =>
        emp.id === id ? { ...emp, [field]: value } : emp,
      ),
    );
  };

  const saveChanges = (id: string) => {
    const updatedEmp = employeesData.find((emp: any) => emp.id === id);
    console.log('updatedEmp', updatedEmp);
    if (updatedEmp) {
      updateBusEmployee(id, updatedEmp);
    }
  };

  const filteredEmpData = employeesData.filter((emp) =>
    emp.employeeNo.includes(searchQuery),
  );

  return (
    <Box sx={{ padding: '15px' }}>
      <Box id="newEmployee" sx={{ border: '1px solid #259aca' }}>
        <Box
          id="employeeSetupHeading"
          sx={{ backgroundColor: '#259aca', padding: '5px' }}
        >
          <Typography sx={{ color: 'white', ml: '10px' }}>
            New Employee
          </Typography>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <Grid container spacing={2}>
            {(['tgNumber', 'employeeName'] as (keyof FormData)[]).map(
              (field) => (
                <Grid item xs={3} key={field}>
                  <Stack spacing={1}>
                    <label htmlFor={field}>
                      <b>
                        {field === 'tgNumber'
                          ? 'TG ID Number'
                          : 'Employee Name'}
                      </b>
                      {field === 'tgNumber' && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </label>
                    <TextField
                      id={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      variant="outlined"
                      sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                    />
                  </Stack>
                </Grid>
              ),
            )}
            <Grid item xs={3}>
              <Stack spacing={1}>
                <label htmlFor="selectedStore">
                  <b>Employee Location</b>
                </label>
                <TextField
                  id="selectedStore"
                  select
                  value={formData.selectedStore}
                  onChange={handleInputChange}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                  sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                >
                  {allBusStores.map((store, i) => (
                    <MenuItem key={i} value={store}>
                      {store}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={1}>
                <label htmlFor="designation">
                  <b>Designation</b>
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  id="designation"
                  value={formData.designation}
                  onChange={handleInputChange}
                  SelectProps={{ MenuProps: { disablePortal: true } }}
                  sx={{ '& .MuiInputBase-root': { height: '26px' } }}
                ></TextField>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: '10px' }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleCreateEmployee}
            >
              Create New Employee
            </Button>
          </Box>
        </Box>
      </Box>
      <Box id="employeeTableContainer" sx={{ mt: '30px' }}>
        <Box
          id="employeeTableHeading"
          sx={{ backgroundColor: '#259aca', padding: '5px' }}
        >
          <TextField
            id="searchInput"
            value={searchQuery}
            onChange={handleSearchInputChange}
            variant="outlined"
            placeholder="Search TG Number"
            sx={{
              padding: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              '& .MuiInputBase-root': {
                height: '26px',
                backgroundColor: 'white',
                padding: '1rem',
              },
            }}
          />
        </Box>
        <Box id="employeeTable">
          <TableContainer
            component={Paper}
            sx={{
              overflow: 'auto',
              height: '360px',
              '&::-webkit-scrollbar': {
                width: '5px',
                height: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#ddd',
                borderRadius: '4px',
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>TG ID</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Location Id</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEmpData.map((emp: any) => (
                  <TableRow
                    key={emp.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {(
                      [
                        'employeeNo',
                        'name',
                        'location',
                        'designation',
                      ] as (keyof EmployeeData)[]
                    ).map((field) => (
                      <TableCell key={field}>
                        {editingRowId === emp.id ? (
                          <TextField
                            variant="standard"
                            value={emp[field]}
                            onChange={(e) =>
                              handleEditChange(emp.id, field, e.target.value)
                            }
                          />
                        ) : (
                          emp[field]
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        sx={{ color: 'grey' }}
                        onClick={() => toggleEditState(emp.id)}
                      >
                        {editingRowId === emp.id ? (
                          <Stack
                            spacing={3}
                            flexDirection="row"
                            sx={{ alignItems: 'baseline' }}
                          >
                            <HighlightOffIcon />
                            <SaveIcon
                              style={{ marginLeft: '5px' }}
                              onClick={() => saveChanges(emp.id)}
                            />
                          </Stack>
                        ) : (
                          <EditIcon />
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{ color: 'grey' }}
                        onClick={() => deleteBusEmployee(emp.id)}
                      >
                        <ClearIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeSetup;
