import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../shared/services/api/API';

export const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = location?.state?.column;
  const roleData = location?.state?.roleData;
  const departmentData = location?.state?.departmentData;
  const userTypeData = location?.state?.userTypeData;
  const { httpPost, handleAlertBar } = useAPI();
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const [errors, setErrors] = useState<any>({});

  const validate = (name: string, value: any) => {
    const isNumeric = (enteredValue: string) =>
        /^[+-]?\d+(\.\d+)?$/.test(enteredValue);
    let error = '';
    if (
      !value &&
      (
        name == 'lastName' ||
        name == 'firstName')
    ) {
      error = `${name} field is required`;
    }
    if (value && value.length < 2 && (name == 'firstName' || name == 'lastName')) {
      error = `The ${name} must be more than 1 character required`;
    }
    if (value && isNumeric(value) && (name == 'firstName' || name == 'lastName')) {
      error = `The ${name} must be in letters`;
    }
    return error;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [e.target.name]: e.target.value });

    const error = validate(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleInputBlur = (identifier: any, e: any) => {
    const { name, value } = e.target;
    const error = validate(identifier, value ?? '');
    setErrors({ ...errors, [identifier]: error });
  };

  const handleSubmit = () => {
    const newErrors: any = {};
    columns.forEach((column: any) => {
      if (column.header !== 'ID') {
        const error = validate(column.header, values[column.accessorKey]);
        if (error) {
          newErrors[column.accessorKey] = error;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
        console.log(values)
        createUser(values);
        setValues({});
    }
  };

  const createUser = (values: any) => {
    if (!Object.keys(validationErrors).length) {
        console.log(values)
        const requestBody = {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            userId: values.userId,
            password: values.password,
            position: values.position,
            departmentId: values["department.name"],
            roleId: values["role.name"],
            clientId: values.id,
            userTypeId: values["userType.name"]
          }

      httpPost(
        WASHUB_CONSTANTS.API_URLS.CLIENT_USER_DATA_POST,
        requestBody,
      ).then((response: any) => {
        if (response.status === 200) {
          handleAlertBar(
            'success',
            `New User Created Successfully`,
          );
          setValues({});
          backToUserManagementList();
        } else {
          if (response.data.error) {
            console.error(
              'Error Creating serviceitem: ',
              response.data.message,
            );
          }
          handleAlertBar('error', response.data.message);
        }
      });
    }
  };

  const backToUserManagementList = () => {
    navigate('..');
  };

  return (
    <div>
      <Grid container className="mb-2 me-3 ms-3">
        <Grid item>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={backToUserManagementList}
          >
            User Management
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create User
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />

      <div style={{ padding: '5px 15px' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            {columns?.map((column: any, index: number) =>
              column.header == 'ID' ? (
                <></>
              ) : column.header == 'Role' ? (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    key={column.accessorKey}
                    fullWidth
                    label={column.header}
                    name={column.accessorKey}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    select
                    onChange={handleChange}>
                            {roleData?.map((option: any ) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                </Grid>
              ) : column.header == 'Department' ? (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    key={column.accessorKey}
                    fullWidth
                    label={column.header}
                    name={column.accessorKey}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    select
                    onChange={handleChange}>
                            {departmentData?.map((option: any ) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                </Grid>
              ): column.header == 'User Type' ? (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    key={column.accessorKey}
                    fullWidth
                    label={column.header}
                    name={column.accessorKey}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                    select
                    onChange={handleChange}>
                            {userTypeData?.map((option: any ) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                </Grid>
              ): (
                <Grid item xs={12} md={6} lg={6} key={column.accessorKey}>
                  <TextField
                    fullWidth
                    key={column.accessorKey}
                    id={column.header}
                    label={column.header}
                    name={column.accessorKey}
                    value={values[column.accessorKey]}
                    onChange={handleChange}
                    required
                    onBlur={(e) => handleInputBlur(column.accessorKey, e)}
                    error={!!errors[column.accessorKey]}
                    helperText={errors[column.accessorKey]}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          className="m-3"
          color="primary"
          sx={{ color: 'white' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Create User
        </Button>
      </div>
    </div>
  );
};
