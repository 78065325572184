import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAPI } from '../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';

export const PickupDeliveryAddress = () => {
    const { httpPost } = useAPI();
    const navigate = useNavigate();
    const location = useLocation();
    const payload = location.state.payload;
    const orderId = location.state.orderId;
    const orderType = location.state.orderType;
    const [selectPickDropAddressSame, setPickDropAddressSame] = useState('same');
    const [deliveryAddress, setDeliveryAddress] = useState({
        userName: '',
        address: '',
        phone: '',
        zip: '',
        errors: {
          userName: false,
          address: false,
          phone: false,
          zip: false,
        },
      });
      const [pickupAddress, setPickupAddress] = useState({
        userName: '',
        address: '',
        phone: '',
        zip: '',
        errors: {
          userName: false,
          address: false,
          phone: false,
          zip: false,
        },
      });
      const isFormValidForDeliveryAddress = () => {
        const isMobileValid = /^\d{10}$/.test(deliveryAddress.phone);
        return (
          deliveryAddress.userName.trim() !== '' &&
          deliveryAddress.address.trim() !== '' &&
          deliveryAddress.zip.trim() !== '' && 
          isMobileValid
        );
      };

      const isFormValidForPickupAddress = () => {
        const isMobileValid = /^\d{10}$/.test(pickupAddress.phone);
        return (
          pickupAddress.userName.trim() !== '' &&
          pickupAddress.address.trim() !== '' &&
          pickupAddress.zip.trim() !== '' && 
          isMobileValid
        );
      };
    
      const handleFormChangePickup = (e: any) => {
        const { name, value } = e.target;
        let isError = false;
        if (name === 'phone') {
          isError = !/^\d{10}$/.test(value) || value.length === 0;
        }
        setPickupAddress((prevData: any) => ({
          ...prevData,
          [name]: value,
          errors: { ...prevData.errors, [name]: isError },
        }));
    };

    const handleFormChangeDelivery = (e: any) => {
        const { name, value } = e.target;
        let isError = false;
        if (name === 'phone') {
          isError = !/^\d{10}$/.test(value) || value.length === 0;
        }
        setDeliveryAddress((prevData: any) => ({
          ...prevData,
          [name]: value,
          errors: { ...prevData.errors, [name]: isError },
        }));
    };

          // POST: Create and Edit Retail Store Order
  const createOrder = () => {
    if(selectPickDropAddressSame === 'different') {
        payload['deliveryAddress'] = {
            address: deliveryAddress.address,
            address2: 'string',
            city: 'string',
            state: 'string',
            country: 'string',
            zipCode: deliveryAddress.zip,
            mail: payload?.customer.mail,
            phone: deliveryAddress.phone,
        };
    } else {
        payload['deliveryAddress'] = {
            address: pickupAddress.address,
            address2: 'string',
            city: 'string',
            state: 'string',
            country: 'string',
            zipCode: pickupAddress.zip,
            mail: payload?.customer.mail,
            phone: pickupAddress.phone,
          };

    }
    payload['pickupAddress'] = {
        address: pickupAddress.address,
        address2: 'string',
        city: 'string',
        state: 'string',
        country: 'string',
        zipCode: pickupAddress.zip,
        mail: payload?.customer.mail,
        phone: pickupAddress.phone,
      };

    if (payload) {
      if (orderId) {
        httpPost(
          `${WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_UPDATE_DATA_POST}/${orderId}`,
          payload,
        )
          .then((response) => {
            if (response?.status === 200) {
                navigate('..'); 
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } else {
        httpPost(WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_DATA_POST, payload)
          .then((response) => {
            if (response?.status === 200) {
              navigate('..');
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    }
  };
  return (
    <div>        
        <div>
        <div style={{ padding: "12px 10px 0px 10px", textAlign:"center"}}>{'Pick & Drop'}</div>
        <hr></hr>
        <FormGroup
        sx={{ width: '100%', padding: '0px 20px', background: '#fffff' }}
      >
        <Grid container display={'flex'}justifyContent={'space-between'}>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={selectPickDropAddressSame === 'same'}
                  onChange={() => setPickDropAddressSame('same')}
                />
              }
              label="Delivery same as pickup address"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={selectPickDropAddressSame === 'different'}
                  onChange={() => setPickDropAddressSame('different')}
                />
              }
              label="Delivery at different address"
            />
          </Grid>
        </Grid>
        </FormGroup>
        <hr></hr>
        <Grid container display={'flex'} spacing={2} sx={{ padding: '0px 20px', height: "63vh", overflow: "auto" }}>
        <Grid item xs={12} md={6} lg={6}>
        <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className='pb-4'
              ><Typography>Pick Up Address</Typography></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className='pb-4'
              >
                <TextField
                  placeholder="Name"
                  name="userName"
                  value={pickupAddress.userName}
                  onChange={handleFormChangePickup}
                  color="secondary"
                  required
                  fullWidth
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12} lg={12}
                className='pb-4'
              >
                <TextField
                  placeholder="Address"
                  name="address"
                  value={pickupAddress.address}
                  onChange={handleFormChangePickup}
                  color="secondary"
                  required
                  fullWidth
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={12} lg={12}
                className='pb-4'
              >
                <TextField
                  placeholder="Zip Code"
                  name="zip"
                  value={pickupAddress.zip}
                  onChange={handleFormChangePickup}
                  required
                  color="secondary"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12} lg={12}
                className='pb-4'
              >
                <TextField
                  placeholder="Phone No"
                  name="phone"
                  value={pickupAddress.phone}
                  onChange={handleFormChangePickup}
                  color="secondary"
                  required
                  fullWidth
                ></TextField>
              </Grid>
        </Grid>
        {selectPickDropAddressSame === 'different' && (
                    <Grid item xs={12} md={6} lg={6}>
                                <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className='pb-4'
              ><Typography>Delivery Address</Typography></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12} lg={12}
                            className='pb-4'
                          >
                            <TextField
                              placeholder="Name"
                              name="userName"
                              value={deliveryAddress.userName}
                              onChange={handleFormChangeDelivery}
                              color="secondary"
                              required
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12} lg={12}
                            className='pb-4'
                          >
                            <TextField
                              placeholder="Address"
                              name="address"
                              value={deliveryAddress.address}
                              onChange={handleFormChangeDelivery}
                              color="secondary"
                              required
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12} lg={12}
                            className='pb-4'
                          >
                            <TextField
                              placeholder="Zip Code"
                              name="zip"
                              value={deliveryAddress.zip}
                              onChange={handleFormChangeDelivery}
                              required
                              color="secondary"
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12} lg={12}
                            className='pb-4'
                          >
                            <TextField
                              placeholder="Phone No"
                              name="phone"
                              value={deliveryAddress.phone}
                              onChange={handleFormChangeDelivery}
                              color="secondary"
                              required
                              fullWidth
                            ></TextField>
                          </Grid>
                    </Grid>
        )}
        </Grid>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '18px',
          left: '50%',
          transform: ' translateX(-52%)',
        }}
      >
        <Grid container display={'flex'}>
        <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              fullWidth
              color="secondary"
              onClick={createOrder}
              variant="contained"
              disabled={selectPickDropAddressSame === 'different' ? (!isFormValidForPickupAddress() || !isFormValidForDeliveryAddress()) : !isFormValidForPickupAddress()}
            >
              Submit {'>>'}
            </Button>
          </Grid>
        </Grid>
      </div>
      </div>
  )
}