import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { IOrderItem } from '../../../../shared/models/RetailOrder.model';
import { MRT_ColumnDef, MaterialReactTable, MaterialReactTableProps } from 'material-react-table';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const VerifyBusinessOrderDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const orderId = location?.state?.order?.orderId || location?.state?.orderId;
    const departmentId = location?.state?.order?.businessDepartment?.id;
    const [orderDetails, setOrderDetails] = useState<any>([]);
    const [tableData, setTableData] = useState<IOrderItem[]>([]);
    const { httpGet, httpPost, handleAlertBar } = useAPI();
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
      }>({});
    const [isEditItem, setIsEditItem ] = useState(false);
    const columns = useMemo<MRT_ColumnDef<IOrderItem>[]>(
      () => [
        {
          accessorKey: 'serviceItem.name',
          header: 'Item Name',
          enableEditing: false,
          size: 10,
        },
        {
          accessorKey: 'serviceItem.serviceCategoryName',
          header: 'Item Category',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          size: 10,
        },
        {
          accessorKey: 'itemCount',
          header: 'Total Count',
          enableEditing: true,
          enableHiding: true,
          size: 10,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            required: true,
            onBlur: (event) => {
              row.original.itemCount = +event.target.defaultValue
              setIsEditItem(true);
            },
          }),
        },
        {
          accessorKey: 'orderItemStatus',
          header: 'Status',
          size: 20,
          enableEditing: false,
        },
      ],
      [],
    );

    const handleVerify = async () => {
        const serviceItemOrder = orderDetails.orderItems.map((item: any) => ({
            specialInstructions: "string",
            amount: (+item?.itemCount * +item?.serviceItem?.price),
            itemCount: +item?.itemCount,
            categoryId: item?.serviceItem?.category?.id,
            serviceItemId: item?.serviceItem?.id
        }));
        const payload = {
          businessId: orderDetails?.businessStore.business.id,
          businessStoreId: orderDetails?.businessStore.id,
          departmentId: departmentId,
          serviceItemOrder: serviceItemOrder
        };
        httpPost(
          `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_VERIFY_DATA_POST}/${orderDetails.orderId}`,
          payload,
        )
          .then((response) => {
            if (response?.status === 200) {
              handleAlertBar('success', `Verify order details successfully`);
              fetchOrders(orderDetails.orderId);
            }
          })
          .catch((error: any) => {
            console.log('error', error);
            handleAlertBar('error', error);
          });
  };
  
    //Get Order Detail By Order Id
    const fetchOrders = async (orderId: string) => {
      httpGet(
        WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DETAILS_DATA_GET +
          '/' +
          orderId,
      )
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.orderItems?.length) {
              setOrderDetails(response?.data);
              setTableData(response?.data?.orderItems);
            }
          }
        })
        .catch((error) => console.log(error));
    };
  
    useEffect(() => {
        fetchOrders(orderId);
    }, []);
  
    const backToOrderList = () => {
      navigate('..', { state: { orderDetails } });
    };
  
    return (
      <div>
        <Grid container m={2}>
          <Grid item xs={2} md={0.5}>
            <IconButton onClick={backToOrderList}>
              <NavigateBeforeIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} md={9.5} lg={9}>
            <h4 style={{ marginTop: '5px' }}>
              Order Details: {orderDetails.orderId}
            </h4>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Button variant="contained" onClick={handleVerify} fullWidth>
              Verify
            </Button>
          </Grid>
        </Grid>
        <hr></hr>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '5px 10px 10px 10px',
          }}
        >
          <Paper sx={{ width: '100%', height: '100%', padding: '10px' }}>
            <Grid
              container
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <Typography>
                  Order Date: <span>{orderDetails.orderDate}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Total Item: <span>{orderDetails.totalItemCount}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Status: <span>{orderDetails.orderStatus}</span>
                </Typography>
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px' }}>
              <MaterialReactTable
                muiTableBodyRowProps={{
                  sx: {
                    height: '5px',
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    width: '100%',
                    '& .css-1tbggly': {
                      minHeight: '0px',
                    },
                    '& .css-10gei56': {
                      minHeight: '0px',
                    },
                  },
                }}
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    muiTableHeadCellProps: {
                      align: 'center',
                    },
                    size: 20,
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    height: '62vh',
                  },
                }}
                columns={columns}
                data={tableData}
                enableFullScreenToggle={false}
                enableStickyHeader
                positionActionsColumn="last"
                // enableRowActions
                enableColumnActions={false}
                enableColumnFilters={false}
                enableFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableHiding={false}
                enableDensityToggle={false}
                enableEditing
                editDisplayMode='cell'
              />
            </div>
          </Paper>
        </div>
      </div>
    );
}