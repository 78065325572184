import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";

export const Home = () => {
  return (
    <div className="m-4">
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h3" className="mb-0">
            Washub Dashboard
          </Typography>
        </Grid>        
      </Grid>      
    </div>
  );
};
