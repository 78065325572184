import { Button, Container, Grid, Stack } from '@mui/material';
import React from 'react';
import './BusinessStoreHome.scss';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';

export const BusinessStoreHome = () => {
  const navigate = useNavigate();

  const goToClothesSelection = () => {
    navigate('/businessStoreOrder/clothes-selection');
  };

  const goToUpdateOrder = () => {
    navigate('/businessStoreOrder/update-order');
  };
  return (
    <div style={{height: "80vh"}}>
      <div className="main">
        <img
          className="main-img"
          src="../../../../images/laundry-order2.png"
          alt="Login Icon"
        />
      </div>
      <div className="footer">
        <CustomButton
          className="footer-button mx-2"
          variant="contained"
          label="Update Order"
          onClick={goToUpdateOrder}
        />
        <CustomButton
          className="footer-button mx-2"
          variant="contained"
          label={"Store order"}
          onClick={goToClothesSelection}
        />
      </div>
    </div>
  );
};
