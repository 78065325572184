import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { data } from './makeData';
import { useAPI } from '../../../shared/services/api/API';
import { OrderType, WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessContext } from '../../../shared/BusinessContext';

export type RetailStoreOrderDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
};

export const RetailStoreOrder = () => {
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const location = useLocation();
  const { retailStoreData } = useContext(BusinessContext);
  const [retailStoreId, setRetailStoreId] = useState('');
  const [tableData, setTableData] = useState<RetailStoreOrderDeatils[]>(
    () => data,
  );
  const navigate = useNavigate();
  const type = localStorage.getItem('userType');
  const { businessStatusList } = useContext(BusinessContext);

  useEffect(() => {
    if (retailStoreId) {
      fetchRetailStoreOrder(retailStoreId);
    }
  }, [retailStoreId]);

  useEffect(() => {
    if (location.state?.retailStoreId) {
      setRetailStoreId(location.state?.retailStoreId);
    }
  }, [location.state?.retailStoreId]);

  // GET with API : Retail Store Order
  const fetchRetailStoreOrder = async (id: string) => {
    let url = '';
    if( type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_DATA_GET}`;

    } else if ( type === 'Retail') {
      url = `${WASHUB_CONSTANTS.API_URLS.RETAILSSTOREORDER_DATA_FOR_RETAIL_GET}`
    }
    httpGet(
      url + '/' + id,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching RetailStoreOrder: ',
            response.data.message,
          );
          setTableData([]);
        }
      }
    });
  };

  const handleStatusChange =(event: any, row: any) =>{
    const payload = {
      orderId: row.orderId,
      orderItemId: 0,
      status: event.target.value,
      processCount: row.totalItemCount
    }
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.RETAILSTOREORDER_STATUS_UPDATE_POST}`,
      payload,
    ).then((response) => {
        if (response?.status === 200) {
            handleAlertBar("success", `Status updated successfully`);
          }
        })
      .catch((error: any) => {
        console.log('error', error);
        handleAlertBar("error", error);
      });

  }

  const columns = useMemo<MRT_ColumnDef<RetailStoreOrderDeatils>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        enableRowActions: true,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'orderDate',
        header: 'Date',
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'orderStatus',
        header: 'Status',
        editVariant: 'select',
        editSelectOptions: businessStatusList,
        muiEditTextFieldProps: ({ cell, row }) => ({
          select: true,
          onChange: (value: any) => handleStatusChange(value,row.original),
        }),
      },
    ],
    [businessStatusList],
  );

  return (
    <div style={{width: '100%', overflow: 'hidden' }}>
      <Stack
        sx={{
          width: '20%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
          key="retailStore"
          label="Select Retail Store"
          name="retailStore"
          select
          onChange={(e) => {
            setRetailStoreId(e?.target?.value);
          }}
          value={retailStoreId}
        >
          {retailStoreData?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <div style={{padding:"5px 15px"}}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        enableFullScreenToggle={false}
        enableStickyHeader
        editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
        enableEditing
        enableHiding
        enableRowActions
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              variant="contained"
              onClick={()=>table.setEditingRow(row)}
            >
              Update
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate('order-details', {
                  state: {
                    orderType: OrderType.Retail,
                    order: row?.original,
                  },
                });
              }}
            >
              Details
            </Button>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => {
              navigate('customer-details', {
                state: {
                  retailStoreId: retailStoreId
                },
              });
            }}
            variant="contained"
          >
            Create Retail Order
          </Button>
        )}
      />
      </div>
    </div>
  );
};

export default RetailStoreOrder;
