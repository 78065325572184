import { useContext } from "react";
import { BusinessContext } from "../../BusinessContext";

export const SharedApi = () => {
    const { businessStatusList } = useContext(BusinessContext);
  
    const getStatusOptions = (status: string) => {
        const statusOptions: any = {
          CREATED: ['CREATED','PICKED', 'VERIFIED', 'READYTODELIVER', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          PICKED: ['PICKED','VERIFIED', 'READYTODELIVER', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          VERIFIED: ['VERIFIED', 'READYTODELIVER', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          READYTODELIVER_PARTIAL: ['READYTODELIVER_PARTIAL', 'READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          READYTODELIVER: ['READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          DELIVERED_PARTIAL: ['DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          DELIVERED: ['DELIVERED','INVOICED', 'PAYMENTDONE'],
          INVOICED: ['INVOICED', 'PAYMENTDONE'],
          //PAYMENTDONE: businessStatusList.filter( (status: any) => ['PAYMENTDONE',].includes(status)),
        }
        return statusOptions[status];
      }

      const getItemStatusOptions = (status: string) => {
        const statusOptions: any = {
          CREATED: ['CREATED','PICKED', 'VERIFIED', 'READYTODELIVER_PARTIAL', 'READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED'],
          PICKED: ['PICKED','VERIFIED','READYTODELIVER_PARTIAL', 'READYTODELIVER','DELIVERED_PARTIAL', 'DELIVERED'],
          VERIFIED: ['VERIFIED', 'READYTODELIVER_PARTIAL', 'READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED'],
          READYTODELIVER_PARTIAL: ['READYTODELIVER_PARTIAL', 'READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          READYTODELIVER: ['READYTODELIVER', 'DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          DELIVERED_PARTIAL: ['DELIVERED_PARTIAL', 'DELIVERED', 'INVOICED', 'PAYMENTDONE'],
          DELIVERED: ['DELIVERED','INVOICED', 'PAYMENTDONE'],
          INVOICED: ['INVOICED', 'PAYMENTDONE'],
          //PAYMENTDONE: businessStatusList.filter( (status: any) => ['PAYMENTDONE',].includes(status)),
        }
        return statusOptions[status];
    }
  
    return {
        getStatusOptions, getItemStatusOptions
    };
  };