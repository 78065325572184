import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { IRetailCustomerDetails } from '../../../../shared/models/RetailOrder.model';
import { OrderType } from '../../../../shared/Constants';
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { BusinessContext } from '../../../../shared/BusinessContext';

export const RetailStoreCustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [retailStoreId, setRetailStoreId] = useState('');
  const { retailStoreData } = useContext(BusinessContext);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableHiding: false,
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: 'firstName',
      header: 'First Name',
      size: 140,
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 140,
    },
    {
        accessorKey: 'phone',
        header: 'Phone',        
    },
    {
      accessorKey: 'address',
      header: 'Address',
      size: 140,
    },
    {
      accessorKey: 'address2',
      header: 'Address-2',
      size: 140,
    },
    {
      accessorKey: 'zipCode',
      header: 'Zipcode',        
    },
    {
      accessorKey: 'mail',
      header: 'Email',
    },
    {
      accessorKey: 'state',
      header: 'State',        
    },
    {
      accessorKey: 'country',
      header: 'Country',        
    },
  ];

  useEffect(() => {
    if (location.state?.retailStoreId) {
      setRetailStoreId(location.state?.retailStoreId);
    }
  }, [location.state?.retailStoreId]);

  const [values, setValues] = useState<any>(() =>
    columns?.reduce((acc: any, column: { accessorKey: any; }) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
);

  const onSubmit = (values: IRetailCustomerDetails) => {
    if (!Object.keys(validationErrors).length) {
        console.log(values)
        navigate('/orders', {
            state: {
              edit: false,
              orderType: OrderType.Retail,
              order: {
                retailStore: {
                  id: retailStoreId,
                },
                customer : values
              },
            },
          });
     }
 };

  const onClose = () => {
    navigate('..', { state: { retailStoreId: retailStoreId  } });
  };
  return (
    <div style={{width: '100%', overflow: 'hidden' }}>
    <Stack
      sx={{
        width: '100%',
        padding: '15px 15px 10px 15px',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
      }}
    >
      <TextField
        style={{width: '30%'}}
        key="retailStore"
        label="Select Retail Store"
        name="retailStore"
        select
        onChange={(e) => {
          setRetailStoreId(e?.target?.value);
        }}
        value={retailStoreId}
      >
        {retailStoreData?.map((option: any) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
    <div style={{width: '100%'}}>
    <div style={{backgroundColor:"purple",color: 'white',padding: "10px", marginBottom: '20px', textAlign:"center", borderBottom: "1px solid black"}}>{"Enter Customer Details"}</div>
    <div style={{height: "58vh", overflow: "auto", padding: "20px 20px"}}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          sx={{
            width: '80%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          {columns?.map((column: any,index: number) => (
            (column.header == 'ID') ? <></> :
            <TextField
              key={index}
              label={column.header}
              name={column.accessorKey}
              placeholder={column.accessorKey === "paymentDate" ? "YYYY-MM-DD" : `Please enter ${column.header}` }
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
          ))}
        </Stack>
      </form>
    </div>
    <div style={{ margin: ' 1rem 1rem', display: 'flex', justifyContent: "space-between",}}>
      {onClose ? <Button onClick={onClose}>Cancel</Button>: <div></div>}
      <Button color="secondary" onClick={()=>onSubmit(values)} variant="contained">
        {"order Laundry  >>"}
      </Button>
    </div>
  </div>
  </div>
  )
}