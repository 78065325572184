import React from 'react';
import { OrderType } from '../../shared/Constants';
import RetailStoreOrder from './retailOrder';
import BusinessStoreOrder from './businessOrder';
import { useLocation } from 'react-router-dom';
import { CustomerCardOrder } from './CustomerCardOrder';

const Orders = () => {
  const location = useLocation();
  const orderType = location?.state?.orderType;
  
  return (
    <>
      {orderType === OrderType.Retail ? (
        <RetailStoreOrder />
      ) : (orderType === OrderType.Customer || orderType === "Customer Order") ? (
        <CustomerCardOrder orderType={orderType}/>
      ) : (
        <BusinessStoreOrder/>
      )}
    </>
  );
};

export default Orders;
