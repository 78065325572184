import React, { createContext, useMemo, useState } from "react";
import { OrderContextModel } from "./models/OrderContextModel";

export const OrderContext = createContext({} as OrderContextModel);
export const OrderProvider = ({ children }: any) => {
    const [busStoreId, setBusStoreId] = useState('');
    const [deptId, setDeptId] = useState('');
    const [serviceItemOrder, setServiceItemOrder] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>({});
  
    const updateBusStoreId = (value: string) => {
        setBusStoreId(value);
    };

    const updateDeptId = (value: string) => {
        setDeptId(value);
    };

    const updateServiceItemOrder = (value: string) => {
        setServiceItemOrder(value);
    };

    const updateSelectedItems = (value: string) => {
        setSelectedItems(value);
    };
  
    const authProviderValues = useMemo(
      () => ({ 
        busStoreId , 
        deptId,
        serviceItemOrder,
        selectedItems,
        updateBusStoreId, 
        updateDeptId,
        updateServiceItemOrder,
        updateSelectedItems
       }),
      [
        busStoreId,
        deptId,
        serviceItemOrder,
        selectedItems
      ]
    );
  
    return (
      <OrderContext.Provider value={authProviderValues}>
        {children}
      </OrderContext.Provider>
    );
  };