import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CustomTabs from '../Tabs';
import CategoryWidget from '../CategoryWidget';
import { LaundryCartContext } from '../../context/service.context';
import CartSummary from '../CartSummary';
import './order.scss';
import { BusinessCartSummary } from '../business-cart-summary/BusinessCartSummary';

interface IOrder {
  submit: () => void;
  isFrom?: string
}

const Order: React.FC<IOrder> = ({
  submit, isFrom
}) => {
  const { data, setData } = useContext(LaundryCartContext);
  const [order, setOrder] = useState<any>([...data]);
  const [tabs, setTabs] = useState<any>([]);

  useEffect(() => {
    const _order = data?.filter((item: any) => {
      if (item.quantity > 0) return item;
    });
    _order?.map((item: any) => {
      item.edit = false;
    });

    const categories = data?.map((item: any) => item?.category) ?? '';
    if (categories?.length) {
      const _tabs = categories?.filter(
        (item: any, index: any, self: any) => item && self.indexOf(item) === index,
      );
      setTabs(_tabs);
    }

    setOrder(_order);
  }, [data]);

  useEffect(() => {
    return () => {
      setData((pv: any): any =>
        pv?.map((item: any) => ({ ...item, quantity: 0 })),
      );
      setOrder(() => []);
    };
  }, []);

  return (
    <Grid className="dashboard">
      <Grid className="dashboard-cart">
        {/* {!!tabs?.length && (
          <Box className="dashboard-tabs">
            <CustomTabs tabs={tabs} />
          </Box>
        )} */}
        <CategoryWidget />
      </Grid>
      <Grid className="dashboard-summary">
        {isFrom === "Business" ? <BusinessCartSummary order={order} setOrder={setOrder} submit={submit} /> : <CartSummary order={order} setOrder={setOrder} submit={submit} />}
      </Grid>
    </Grid>
  );
};

export default Order;
