import {
  Button,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAPI } from '../../../shared/services/api/API';
import { OrderType, WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessInvoiceList } from './business-invoice-list/BusinessInvoiceList';
import { MRT_ColumnDef } from 'material-react-table';
import { IBusinessInvoice } from '../models/BusinessInvoicesModels';
import { BusinessContext } from '../../../shared/BusinessContext';

export const BusinessInvoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const BusinessStoreOrder = location?.state?.order;
  const { httpGet } = useAPI();
  const type = localStorage.getItem('userType');

  const [businessId, setBusinessId] = useState('');
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState([]);
  const [tableData, setTableData] = useState<IBusinessInvoice[]>([]);
  const { businessList } = useContext(BusinessContext);
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async (id: string) => {
    let url = '';
    if (type === 'ClientAdmin') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_GET}`;
    } else if (type === 'Business') {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DATA_FOR_BUSINESS_GET}`;
    }
    httpGet(url + '/' + id, queryParams).then((response) => {
      if (response.status === 200) {
        setBusinessStoreData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : Business Order Invoice
  const fetchAllBusinessInvoice = async (id: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_INVOICE_GET + '/' + id,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Business Order Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : Business Store Order Invoice
  const fetchBusinessStoreInvoice = async (
    businessId: string,
    businessStoreId: string,
  ) => {
    setBusinessStoreId(businessStoreId);
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_INVOICE_GET}/${businessId}/${businessStoreId}`,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore Order Invoices: ',
            response.data.message,
          );
          setTableData([]);
        }
      }
    });
  };

  //Generate Business Invoice
  const generateBusinessInvoice = () => {
    navigate('generate-invoices', {
      state: { businessId: businessId, invoiceFor: 'Business' },
    });
  };

  //Generate Business Store Invoice
  const generateBusinessStoreInvoice = () => {
    navigate('generate-invoices', {
      state: {
        businessId: businessId,
        businessStoreId: businessStoreId,
        invoiceFor: 'BusinessStore',
      },
    });
  };

  // Business Store
  useEffect(() => {
    if (businessId) {
      fetchBusinessStore(businessId);
      fetchAllBusinessInvoice(businessId);
    }
  }, [businessId]);

  // edit order
  useEffect(() => {
    if (BusinessStoreOrder?.business?.id) {
      setBusinessId(BusinessStoreOrder?.business?.id);
    }
    if (BusinessStoreOrder?.businessStore?.id) {
      setBusinessStoreId(BusinessStoreOrder?.businessStore?.id);
    }
  }, [BusinessStoreOrder]);

  useEffect(() => {
    if (businessStoreId) {
      fetchBusinessStoreInvoice(businessId, businessStoreId);
    }
  }, [businessStoreId]);

  const columns = useMemo<MRT_ColumnDef<IBusinessInvoice>[]>(
    () => [
      {
        accessorKey: 'invoiceId',
        header: 'Invoice Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'invoiceAmount',
        header: 'Invoice Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

  return !isLoading ? (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <div style={{ width: "76%"}}>
        <TextField
          key="business"
          style={{width: "40%", marginRight: "10px"}}
          label="Select Business"
          name="business"
          select
          onChange={(e) => {
            setBusinessId(e.target.value);
          }}
          value={businessId}
        >
          {businessList?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        {businessId ? (
          <TextField
            key="businessStore"
            style={{width: "40%"}}
            label="Select Business Store"
            name="businessStore"
            select
            onChange={(e) => {
              setBusinessStoreId(e.target.value);
            }}
            value={businessStoreId}
          >
            {businessStoreData.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        </div>

        {businessId && businessStoreId === '' ? (
          <Button variant="contained" onClick={generateBusinessInvoice}>
            Generate Business Invoice
          </Button>
        ) : null}

        {businessStoreId ? (
          <Button variant="contained" onClick={generateBusinessStoreInvoice}>
          Generate Business Store Invoice
        </Button>
        ) : null}
      </Stack>
      <BusinessInvoiceList
        columns={columns}
        tableData={tableData}
        OrderType={OrderType}
        businessId={businessId}
        businessStoreId={businessStoreId}
      />
    </div>
  ) : null;
};
