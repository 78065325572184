import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Stack,
} from '@mui/material';
import { MRT_ColumnDef, MaterialReactTable, MaterialReactTableProps } from 'material-react-table';
import React, { useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { Edit } from '@mui/icons-material';
import { ICustomerDetails } from '../../../shared/models/CustomerDetails.model';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../../shared/services/api/API';
import { OrderType, WASHUB_CONSTANTS } from '../../../shared/Constants';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export const Customer = () => {
  const navigate = useNavigate();
  const { httpGet , handleAlertBar } = useAPI();
  const [searchText, setSearchText] = useState<string>('');
  const [tableData, setTableData] = useState<ICustomerDetails[]>([]);

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
        fetchCustomerData();
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
  };

  useEffect(() => {
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        fetchCustomerData();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        fetchCustomerData();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  // GET with API : Customer Details
  const fetchCustomerData = async () => {
    setIsLoading(true);
    await httpGet(
      WASHUB_CONSTANTS.API_URLS.CUSTOMER_DATA_GET, queryParams
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
            setIsLoading(false);
            handleAlertBar("error", response.data.error);
          console.error(
            'Error fetching Customer Data: ',
            response.data.message,
          );
        }
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<ICustomerDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 140,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'country',
        header: 'Country',
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
      }
    ],
    [],
  );
  const [IsLoading, setIsLoading] = useState(false);
  return (
    <div>
      <Stack
        sx={{
          width: '20%',
          padding: '15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1.5rem',
        }}
      >
        <FormControl sx={{ marginRight: 2 }} variant="standard">
              <Input
                id="search"
                type="text"
                placeholder={'Search Customer Name & Phone No.'}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <Link component="button" title={'clear'}>
                        <CloseIcon
                          fontSize="small"
                          onClick={() => setSearchText("")}
                        />
                      </Link>
                    </InputAdornment>
                  )
                }
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearchKeyEnter}
              />
            </FormControl>
      </Stack>
      <div style={{ padding: '0px 15px 5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTableContainerProps= {{
            sx: {
                height: "62vh"
            }
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableColumnFilters={false}
          enableFilters={false}
          enableDensityToggle={false}
          enableHiding={false}
          enableStickyHeader
          editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
          enableEditing
          enableRowActions
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              color="secondary"
              onClick={() => {
                navigate('create-order', {
                    state: {
                      edit: false,
                      orderType: OrderType.Customer,
                      order: {
                        customer: row.original,
                      },
                    },
                  });
              }}
              variant="contained"
            >
              Create Order
            </Button>
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Button
              color="secondary"
              onClick={() => {
                navigate('create-customer');
              }}
              variant="contained"
            >
              Create Customer
            </Button>
          )}
        />
      </div>
    </div>
  );
};
