import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Stack, TextField, Typography, Grid, Link } from "@mui/material";
import "./Login.scss";
import { MyButton } from "../Buttons/Button";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../Contexts";
import { useAPI } from "../../services/api/API";
import { WASHUB_CONSTANTS } from "../../Constants";
import RefreshIcon from '@mui/icons-material/Refresh';

export const Login = () => {
  const { httpPost } = useAPI();
  const navigate = useNavigate();
  const authenticate = useContext(AuthenticationContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef<any>(null);

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassword(e.target.value);
  };

  /*const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;*/
  const emailRegex = /^[A-Za-z0-9._%+-]+$/i;
  const generateOTP = async () => {
    if (!email.match(emailRegex)) {
      setVerificationStatus("Please enter a valid email address.");
      return;
    }
    if (userInput !== captchaText) {
      setVerificationStatus("Please enter a correct captcha.");
      const canvas: any = canvasRef.current;
      const ctx = canvas.getContext('2d');
      initializeCaptcha(ctx);
      return
  }

    try {
      const queryParams = {
        userId: email,
        password: password,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.AUTH_LOGIN,
        null,
        queryParams
      );
      console.log("Response:", response.data);
      if(!response.data.error){
      setVerificationStatus("Login successful.");
      setShowSuccess(true);
      const jwtToken: string = response?.data?.token || null;
      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("userRole",response?.data?.role);
      localStorage.setItem("userType",response?.data?.type);
      authenticate.updateToken(jwtToken);
      navigate("/");
      }else{
        setVerificationStatus( response?.data?.message || "Please enter a valid User / Password .");
        //setNameError(true);
      }
    } catch (error) {
      console.error("Error generating Login:", error);
      setVerificationStatus("Error generating Login.");
    }
  };

  const verifyOTP = async () => {
    try {
      const queryParams = {
        userId: email,
        password: password,
      };
      const response = await httpPost(
        WASHUB_CONSTANTS.API_URLS.AUTH_LOGIN_VERIFY_OTP,
        null,
        queryParams
      );
      console.log("Response:", response.data);
      setVerificationStatus("OTP verification successfully");
      setShowSuccess(true);
      const jwtToken: string = response?.data?.token || null;
      localStorage.setItem("jwtToken", jwtToken);
      authenticate.updateToken(jwtToken);
      navigate("/home");
    } catch (error) {
      setVerificationStatus("OTP verification failed");
    }
  };

    useEffect(() => {
        const canvas: any = canvasRef.current;
        const ctx = canvas.getContext('2d');
        initializeCaptcha(ctx);
    }, []);

    const generateRandomChar = (min: any, max:any) =>
        String.fromCharCode(Math.floor
            (Math.random() * (max - min + 1) + min));

    const generateCaptchaText = () => {
        let captcha = '';
        for (let i = 0; i < 3; i++) {
            captcha += generateRandomChar(65, 90);
            captcha += generateRandomChar(97, 122);
            captcha += generateRandomChar(48, 57);
        }
        return captcha.split('').sort(
            () => Math.random() - 0.5).join('');
    };

    const drawCaptchaOnCanvas = (ctx: any, captcha: any) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
        const letterSpace = 150 / captcha.length;
        for (let i = 0; i < captcha.length; i++) {
            const xInitialSpace = 25;
            ctx.font = '20px Roboto Mono';
            ctx.fillStyle = textColors[Math.floor(
                Math.random() * 2)];
            ctx.fillText(
                captcha[i],
                xInitialSpace + i * letterSpace,               
                // Randomize Y position slightly
                Math.floor(Math.random() * 16 + 25),
                100
            );
        }
    };

    const initializeCaptcha = (ctx: any) => {
        setUserInput('');
        const newCaptcha = generateCaptchaText();
        setCaptchaText(newCaptcha);
        drawCaptchaOnCanvas(ctx, newCaptcha);
    };

    const handleUserInputChange = (e: any) => {
        setUserInput(e.target.value);
    };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        "@media (min-width:0px)": {
          minHeight: "100vh",
        },
        "@media (min-width:600px)": {
          minHeight: "80vh",
        },
        "@media (min-width:960px)": {
          minHeight: "70vh",
        },
        "@media (min-width:1280px)": {
          minHeight: "60vh",
        },
        "@media (min-width:1920px)": {
          minHeight: "50vh",
        },
      }}
    >
      <Stack>
        
          <Stack>           
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: "80vh" }}
              >
                <Grid item xs={12} sm={6} md={6} lg={5} xl={4}>
                  <img
                    src="images/laundry1.png"
                    alt="Login Icon"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  xl={4}
                  sx={{ padding: "20px" }}
                >
                  <Typography variant="h4" gutterBottom className="header">
                    Login
                  </Typography>
                  {/*<Typography variant="body1" gutterBottom className="content">
                    Email address
                  </Typography>*/}
                  <Typography
                    variant="body1"
                    sx={{
                      color: verificationStatus.includes("successful")
                        ? "green"
                        : "red",
                    }}
                    className="verification-status"
                  >
                    {verificationStatus}
                  </Typography>
                  <TextField
                    placeholder="Username / Email" 
                    variant="standard"                
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={nameError}
                    helperText={nameError ? "Please enter your name" : ""}
                    sx={{ mb: 2 ,  paddingTop: "40px" }}
                  />
                  {/*<Typography variant="body1" gutterBottom className="content">
                    Password
                  </Typography>*/}
                  <TextField
                    placeholder="Password"
                    variant="standard"              
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    sx={{ mb: 2 ,  paddingTop: "20px" }}
                  />
                  <div className="wrapper">
                    <canvas ref={canvasRef}
                        width="200"
                        height="70">

                    </canvas>
                    <Link className="mt-4" id="reload-button" onClick={
                        () => initializeCaptcha(
                            canvasRef?.current.getContext('2d'))}>
                    <RefreshIcon/>
                    </Link>
                </div>
                <TextField
                    placeholder="Enter the captcha"
                    variant="standard"              
                    type="captcha"
                    value={userInput}
                    onChange={handleUserInputChange}
                    sx={{ mb: 2 ,  paddingTop: "20px" }}
                  />
                  <MyButton
                    variant="contained"
                    label={"Submit"}
                    onClick={generateOTP}
                  />                  
                  
                </Grid>
              </Grid>
            
          </Stack>
        
      </Stack>
    </Container>
  );
};
