import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserInfoForm.scss';
import { UserDataContext } from '../../context/service.context';
import { AuthenticationContext } from '../../../../shared/Contexts';
import { MyButton } from '../../../../shared/components/Buttons/Button';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';

export type UserDetail = {
  id?: string;
  userName: string;
  address: string;
  email: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  city: string;
  country: string;
};

export type UserDetailError = {
  userName: false;
  address: false;
  email: false;
  phoneNumber: false;
  state: false;
  zipCode: false;
  city: false;
  country: false;
};

export const UserInfoForm = () => {
  const navigate = useNavigate();
  const authenticate = useContext(AuthenticationContext);
  const [clientInfo, setClientInfo] = useState<UserDetail>({
    userName: '',
    phoneNumber: '',
    email: '',
    address: '',
    state: '',
    zipCode: '',
    country: '',
    city: '',
  });
  const { userInfo, dispatch } = useContext(UserDataContext);
  const [errors, setErrors] = useState<UserDetailError>({
    userName: false,
    address: false,
    email: false,
    phoneNumber: false,
    state: false,
    zipCode: false,
    city: false,
    country: false,
  });
  const isBusinessOrder = location.pathname.includes('businessCustomerOrder');

  const isFormValid = () => {
    // const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clientInfo.email);
    const isMobileValid = /^\+?\d{10,13}$/.test(clientInfo.phoneNumber);

    return clientInfo.userName.trim() !== '' && clientInfo.address.trim() !== '' && isMobileValid;
  };
  useMemo(() => {
    if (userInfo) {
      localStorage.removeItem('jwtToken');
      authenticate.updateToken(null);
      setClientInfo(userInfo);
    } else {
      setClientInfo({
        userName: '',
        phoneNumber: '',
        email: '',
        address: '',
        state: '',
        zipCode: '',
        country: '',
        city: '',
      });
    }
  }, [userInfo]);

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    let isError = false;

   if (name === 'phoneNumber') {
      isError = !/^\+?\d{10,13}$/.test(value) || value.length === 0;
    }
    setClientInfo((prevData: UserDetail) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: isError,
    });
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (isFormValid()) {
      setClientInfo((prevData: any) => ({ ...prevData }));
      dispatch({ type: 'UPDATE_USER_DATA', payload: clientInfo });
      if(isBusinessOrder) {
        navigate('/business-clothes-selection');
      } else {
        navigate('/clothes-selection');
      }
    } else {
      console.log('Form has errors. Please fill in all required fields.');
    }
    //navigate('/clothes-selection');
  };
  return (
    <div className="user-info">
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '10px 15px 15px',
          // borderRadius: '0px 0px 35px 35px',
        }}
      >
        <Grid item display={'flex'} justifyContent={'center'}>
          <Typography
            variant="h5"
            className="mb-0"
            style={{ color: '#ffffff' }}
          >
            Enter Your Information
          </Typography>
        </Grid>
      </Grid>
      <div className="user-info-content">
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              padding: '20px',
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <TextField
              label="Name"
              name="userName"
              value={clientInfo.userName}
              onChange={handleFormChange}
              required
              color="primary"
              error={errors.userName}
              helperText={errors.userName && 'Please enter Name'}
            />

            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={clientInfo.phoneNumber}
              onChange={handleFormChange}
              required
              color="primary"
              error={errors.phoneNumber}
              helperText={
                errors.phoneNumber && 'Please enter a valid Mobile number'
              }
            />

            <TextField
              label="Email"
              name="email"
              value={clientInfo.email}
              onChange={handleFormChange}
              color="primary"
              error={errors.email}
              helperText={errors.email && 'Please enter a valid email address'}
            />

            <TextField
              label="Address"
              name="address"
              value={clientInfo.address}
              onChange={handleFormChange}
              required
              color="primary"
              error={errors.address}
              helperText={errors.address && 'Please enter address'}
            />
            <TextField
              label="City"
              name="city"
              value={clientInfo.city}
              onChange={handleFormChange}
              color="primary"
              error={errors.city}
              helperText={errors.address && 'Please enter city'}
            />
            <TextField
              label="Zip Code"
              name="zipCode"
              value={clientInfo.zipCode}
              onChange={handleFormChange}
              color="primary"
              error={errors.zipCode}
              helperText={errors.zipCode && 'Please enter zipCode'}
            />
            <TextField
              label="State"
              name="state"
              value={clientInfo.state}
              onChange={handleFormChange}
              color="primary"
              error={errors.state}
              helperText={errors.address && 'Please enter state'}
            />
            <TextField
              label="Country"
              name="country"
              value={clientInfo.country}
              onChange={handleFormChange}
              color="primary"
              error={errors.country}
              helperText={errors.country && 'Please enter country'}
            />
          </Stack>
        </form>
      </div>
      <div className="user-info-footer">
        <Grid container className="user-info-footer">
          {/* <Grid item xs sm md lg></Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomButton
              fullWidth
              color="primary"
              onClick={handleSubmit}
              variant="contained"
              // sx={{ p: '.5rem', borderRadius: '20px 20px 20px 20px' }}
              disabled={!isFormValid()}
              label={'Order Laundry >>'}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
