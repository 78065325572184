import React from 'react'
import { Divider, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from "@mui/material";
import { TCategoryType, getCategoryColor } from "../../../../shared/Constants";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface ICartTileProps {
  index: number;
  name: string;
  price: string;
  currency: string;
  icon: string;
  count: number;
  type: TCategoryType;
  itemChangeHandler: (event: any, item: any) => void;
  onIncrement: (index: number) => void;
  onDecrement: (index: number) => void;
  filteredClothesList: []
}
export const CartList: React.FC<ICartTileProps> = ({
    index,
    name,
    price,
    currency,
    icon,
    count,
    type,
    itemChangeHandler,
    onIncrement,
    onDecrement,
    filteredClothesList
  }) => {
  return (
    <List sx={{ bgcolor: 'background.paper'}} >
    {filteredClothesList?.length > 0 ? (filteredClothesList?.map((item: any, index: number) => (
      <>
      <ListItem key={`laundry_${index}_${item}`} secondaryAction={
          <div style={{display: "flex", flexDirection: "row", justifyContent: "end"}}>
          <Link
                component="button"
                title="remove item"
                onClick={(event) => {event?.preventDefault();
                    event?.stopPropagation();
                    onDecrement(item.id);}}
              >
                <RemoveCircleOutlineIcon color="primary" />
              </Link>
                <TextField
                  id="editInput"
                  variant="standard"
                  sx={{ width: '15%' }}
                  value={item.quantity || 0}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                    },
                  }}
                  onChange={(event: any) =>
                    itemChangeHandler(event, item)
                  }
                />
              <Link
                component="button"
                title="add item"
                onClick={(event) => {event?.preventDefault();
                    event?.stopPropagation();
                    onIncrement(item.id);}}
              >
                <AddCircleOutlineIcon color="primary" />
              </Link>
          </div>
}>
          <ListItemAvatar>
          <img
                src={item?.icon}
                width={'60px'}
                height={'60px'}
                alt="icon"
                id="icon"
              />
          </ListItemAvatar>
          <ListItemText 
          sx={{
            whiteSpace: {
              xs: 'wrap',
              sm: 'wrap',
              md: 'wrap',
              lg: 'nowrap'
            },
            maxWidth: {
              xs: '100px',
            }
          }}
          primary={item?.item} 
          // secondary={`${item.currency}: ${item.price}`} 
          />
        </ListItem>
        <Divider sx={{bgcolor: "purple"}} />
      </>
  ))) : (
        <Typography
          variant="subtitle1"
          color={'GrayText'}
          sx={{ fontSize: '14px', padding: '30px 40%' }}
        >
          No Items to show{' '}
        </Typography>)}
  </List>
  )
}