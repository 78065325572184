import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  label: string;
  variant: 'text' | 'outlined' | 'contained' | undefined;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ label, variant, ...props }) => {
  const buttonStyles = {
    alignItems: 'center',
    background: variant !== 'outlined' ? '#25BCCA' : 'initial',
    border: variant === 'outlined' ? '0.2rem solid #25BCCA' : 'initial',
    color: variant === 'outlined' ? '#25BCCA' : '#FFFFFFCC',
    fontSize: "20px",
    '&:hover': {
      background: variant !== 'outlined' ? '#1C2E36' : 'initial',
      color: variant === 'outlined' ? '#1C2E36' : '#FFFFFFCC',
      border: variant === 'outlined' ? '0.2rem solid #25BCCA' : 'initial',
    },
  };

  return (
    <Button
      variant={variant}
      sx={{
        ...buttonStyles,
        display: 'flex',
        width: '100%',
        height: '3.4375rem',
        justifyContent: 'center',
      }}
      {...props}
    >
      {label}
    </Button>
  );
};
