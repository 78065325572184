import { Box, Button, Card, CardActions, Grid, IconButton } from '@mui/material';
import { MRT_ColumnDef, MRT_Row, MaterialReactTable } from 'material-react-table';
import React, { useMemo, useState } from 'react'
import { RetailStoreOrderDeatils } from '../../../retail-store-order/components/RetailStoreOrder';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useAPI } from '../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const GenerateRetailInvoice = () => {
  const { httpPost, handleAlertBar } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const retailStoreId = location.state.retailStoreId;
  const invoiceFor = location.state.invoiceFor;
  const [tableData, setTableData] = useState<RetailStoreOrderDeatils[]>([]);

  const columns = useMemo<MRT_ColumnDef<RetailStoreOrderDeatils>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 20,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        enableEditing: false,
        size: 20,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableEditing: false,
        size: 15,
      },
      {
        accessorKey: 'totalItemCount',
        header: 'Item Count',
        enableEditing: false,
        size: 15,
      },
    ],
    [],
  );

  // POST with API : create invoice for Retail Order ID
  const createInvoiceForRetailOrderId = async (row: any) => {
      const payload = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        retailStoreId: retailStoreId,
        orderIds: [row.orderId],
        clientId: row?.client?.id,
      };
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.RETAILSSTOREORDER_ID_INVOICES_CREATE_POST}`,
        payload,
      ).then((response: any) => {
        if (response.status === 200) {
          fetchAllRetailOrderIdForInvoice();
          handleAlertBar(
            'success',
            `${row.orderId} Invoice created successfully`,
          );
          setIsLoading(false);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching Retail Order for Invoices: ',
              response.data.message,
            );
          }
        }
      });
  };

  // POST with API : create invoice for Retail Order IDS
  const createInvoiceForRetailOrderIds = async (rowsData: any) => {
    const selectedRetailOrderIds: any = rowsData.map(
      (item: any) => item.orderId,
    );
      const payload = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        retailStoreId: retailStoreId,
        orderIds: selectedRetailOrderIds,
      };
      httpPost(
        `${WASHUB_CONSTANTS.API_URLS.RETAILSSTOREORDER_IDS_INVOICES_CREATE_POST}`,
        payload,
      ).then((response: any) => {
        if (response.status === 200) {
          setIsLoading(false);
          fetchAllRetailOrderIdForInvoice();
          handleAlertBar('success', 'Invoices created successfully');
        } else {
          if (response.data.error) {
            handleAlertBar('error', response.data.message);
            console.error(
              'Error fetching Retail Order for Invoices: ',
              response.data.message,
            );
          }
        }
      });
  };

  // POST with API : Retail Order ID for Invoice
  const fetchAllRetailOrderIdForInvoice = async () => {
    const payload = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      businessStoreId: retailStoreId,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.RETAILSSTOREORDER_IDS_FOR_INVOICE_POST}`,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        setTableData(response.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Retail Order for Invoices: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleGenerateInvoice = (
    rows: MRT_Row<RetailStoreOrderDeatils>[],
  ) => {
    const rowData = rows.map((row) => row.original);
    createInvoiceForRetailOrderIds(rowData);
  };

  const onBackRetailInvoice = () => {
    navigate('..', {
      state: { retailStoreId},
    });
  };

  const [startDate, setStartDate] = useState(dayjs().subtract(5, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };
  return (
    <div>
      <Grid container m={2}>
        <Grid item xs={2} md={0.5}>
          <IconButton onClick={onBackRetailInvoice}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={8} lg={8.5}>
          <h4 style={{ marginTop: '5px' }}>Generate Invoice</h4>
        </Grid>
      </Grid>
      <hr></hr>
      <Card sx={{ margin: '5px 15px' }} elevation={2}>
        <CardActions>
          <Grid container className="m-2" spacing={2}>
            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Start date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="startDate"
                  value={dayjs(startDate)}
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md lg>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'End date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="endDate"
                  value={dayjs(endDate)}
                  minDate={dayjs(startDate)}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </Grid>
            {startDate && endDate ? (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={fetchAllRetailOrderIdForInvoice}
                >
                  Get Retail Store Order
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardActions>
      </Card>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableEditing
          positionActionsColumn="last"
          enableRowActions
          enableRowSelection
          enableFullScreenToggle={false}
          enableStickyHeader
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                variant="contained"
                onClick={() => createInvoiceForRetailOrderId(row.original)}
              >
                Generate Invoice
              </Button>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                onClick={() =>
                  handleGenerateInvoice(table.getSelectedRowModel().rows)
                }
              >
                Generate Selected Order Invoice
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  );
}