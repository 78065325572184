export const WASHUB_CONSTANTS = {
  ADMIN: 'Admin',
  SUPERVISOR: 'Supervisor',
  API_URLS: {
    AUTH_LOGIN: 'auth/login',
    AUTH_BUSUSERLOGIN: 'auth/businessUserLogin',
    AUTH_LOGIN_VERIFY_OTP: 'auth/login/verifyotp',
    WORKFLOWS_LIST: 'workflow/workflows',
    WORKFLOWS_LIST_BYSTATUS: 'workflow/workflows/bystatus',

    PROFILE_DATA: 'userView/myProfile',

    CLIENT_DATA_GET: 'admin/getAllClients',
    CLIENT_DATA_PUT: 'admin/updateClient',
    CLIENT_DATA_POST: 'admin/createClient',

    BUSINESS_DATA_GET: 'clientadmin/getAllBusiness',
    BUSINESS_DATA_PUT: 'clientadmin/updateBusiness',
    BUSINESS_DATA_POST: 'clientadmin/createBusiness',

    BUSINESSSTORE_DEPARTMENT_DATA_GET: "businessOrder/getAllBusinessDepartment",

    COLLECTION_CENTER_BARCODE_DETAILS_GET:
      '/collectionCenter/getDetailsFromBarCode/',
    COLLECTION_CENTER_CREATE_BARCODE:
      '/collectionCenter/generateCollectionCenterBarCode',
    COLLECTION_CENTER_ALL_BUS_EMP: '/collectionCenter/getAllBusinessEmployees',
    COLLECTION_CENTER_ALL_BUS_PRODUCTS_GET:
      '/collectionCenter/getAllBusinessProducts',
    COLLECTION_CENTER_ALL_BUS_STORE_GET:
      '/collectionCenter/getAllBusinessStore',
    COLLECTION_CENTER_CREATE_BUS_EMP_POST:
      '/collectionCenter/createBusinessEmployee',
    COLLECTION_CENTER_UPDATE_BUS_EMP_PUT:
      'collectionCenter/updateBusinessEmployee/',
    COLLECTION_CENTER_DELETE_BUS_EMP_DELETE:
      '/collectionCenter/deleteBusinessEmployee/',
    COLLECTION_CENTER_CREATE_BARCODE_ORDER_POST:
      '/collectionCenter/createBarcodeOrder',

    BUSINESSSTORE_DATA_GET: 'clientadmin/getAllBusinessStore',
    BUSINESSSTORE_DATA_PUT: 'clientadmin/updateBusinessStore',
    BUSINESSSTORE_DATA_POST: 'clientadmin/createBusinessStore',

    ORDER_STATUS_LIST_GET: "role/getOrderStatusByUser",

    BUSINESSSTOREORDER_DATA_PUT: "user/updateBusinessOrder",

    BUSINESS_DATA_FOR_BUSINESS_GET: "businessOrder/getAllBusiness",
    BUSINESS_ORDER_DATA_GET: "businessOrder/getAllBusinessOrdersForBusiness",
    SEARCH_BUSINESS_ORDER_BY_ORDERID_POST: "businessOrder/searchBusinessOrdersByOrderId",
    SEARCH_BUSINESS_UNVERIFY_ORDER_BY_ORDERID_POST: "businessOrder/searchUnVerifiedBusinessOrdersByOrderId",
    SEARCH_BUSINESS_DELIVERY_NOTES_BY_ORDERID_POST: "businessOrder/searchBusinessOrdersDeliveryBatchesByOrderId",
    BUSINESS_ORDER_BUSINESS_STORE_DEPT_DATA_GET: "businessOrder/getAllBusinessServiceItemsForBusiness",
    BUSINESS_ORDER_UPDATE_DATA_POST: "businessOrder/updateBusinessOrder",
    BUSINESS_ORDER_VERIFY_DATA_POST: "businessOrder/verifyBusinessOrder",
    BUSINESS_ORDER_UNVERIFY_FOR_BUSINESS_GET: "businessOrder/getAllUnVerifiedBusinessOrdersForBusiness",
    BUSINESS_ORDER_UNVERIFY_FOR_BUSINESSSTORE_GET: "businessOrder/getAllUnVerifiedBusinessOrdersForBusinessStore",
    BUSINESS_ORDER_UNVERIFY_FOR_BUSINESSSTOREDEPT_GET: "businessOrder/getAllUnVerifiedBusinessOrdersForBusinessDept",
    
    BUSINESSSTORE_DATA_FOR_BUSINESS_GET: "businessOrder/getAllBusinessStore",

    BUSINESSSSTORE_GENERATE_DELIVERY_NOTE_BATCH_POST: "businessOrder/GenerateDeliveryNoteBatchForOrderId",
    BUSINESSSSTORE_GENERATE_DELIVERY_NOTE_PDF_POST: "businessOrder/generateA5BusinessDeliveryNotePDF",
    BUSINESSSTOREORDER_ALL_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessOrderDeliveryNoteBatches",
    BUSINESSSTOREORDER_DEPT_ALL_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessDeptOrderDeliveryNoteBatches",
    BUSINESS_DELIVERY_NOTE_DETAIL_GET: "businessOrder/getBusinessOrdersDeliveryNoteBatcheDetails",
    BUSINESSSTOREORDER_ALL_PENDING_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessOrderPendingDeliveryBatch",
    BUSINESSSTOREORDER_DEPT_ALL_PENDING_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessDeptOrderPendingDeliveryBatch",
    BUSINESSSTOREORDER_ALL_COMPLETED_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessOrderCompletedDeliveryBatch",
    BUSINESSSTOREORDER_DEPT_ALL_COMPLETED_DELIVERY_NOTES_GET: "businessOrder/getAllBusinessDeptOrderCompletedDeliveryBatch",


    BUSINESSSTOREORDER_SERVICE_ITEMS_GET: "businessOrder/getAllBusinessServiceItems",
    BUSINESSSTOREORDER_DATA_GET: "businessOrder/getAllBusinessOrders",
    BUSINESSSTOREORDER_DATA_POST: "businessOrder/createBusinessOrder",
    BUSINESSSTOREORDER_CANCEL_POST: "businessOrder/cancelBusinessOrder",
    BUSINESSSTOREORDER_STATUS_UPDATE_POST: "businessOrder/updateBusinessOrderStatus",
    BUSINESSSTOREORDER_GENERATE_ORDER_NOTE_POST: "businessOrder/generateBusinessPickupNotePDF",
    BUSINESSSTOREORDER_UPDATE_POST: "businessOrder/updateBusinessOrder",
    BUSINESSSTOREORDER_ADD_ITEM_POST: "businessOrder/addBusinessOrderItem",
    BUSINESSSTOREORDER_ITEM_UPDATE_POST: "businessOrder/updateBusinessOrderItems",
    BUSINESSSTOREORDER_ITEM_DELETE_POST: "businessOrder/deleteBusinessOrderItem",
    BUSINESSSTOREORDER_STATUS_FILTER_POST: "businessOrder/getAllBusinessOrdersByStatus",
    BUSINESSSTOREORDER_DETAILS_DATA_GET: "businessOrder/getBusinessOrderDetails",
    BUSINESSSTOREORDER_DETAILS_ITEM_STATUS_UPDATE_POST: "businessOrder/updateBusinessOrderItemStatus",

    BUSINESSORDER_INVOICE_GET: "businessOrderInvoice/getAllBusinessOrderInvoices",
    BUSINESSORDER_INVOICE_DETAIL_GET: "businessOrderInvoice/getBusinessOrderInvoiceDetails",
    BUSINESSORDER_INVOICE_CREATE_POST: "businessOrderInvoice/createBusinessOrderInvoice",
    BUSINESSORDER_IDS_INVOICES_CREATE_POST: "businessOrderInvoice/createBusinessOrderInvoiceForOrderIds",
    BUSINESSORDER_INVOICE_GENERATE_PDF_POST: "businessOrderInvoice/generateBusinessOrderInvoicePDF",
    BUSINESSORDER_IDS_FOR_INVOICE_POST: "businessOrder/getAllBusinessOrdersByBusinessIdForInvoicing",
    BUSINESSORDER_INVOICE_DATERANGE_GET: "businessOrderInvoice/getAllBusinessOrderInvoicesWithDateRange",
    BUSINESSORDER_SERVICE_TYPE_DATA_GET: "businessOrder/getServiceTypes",

    BUSINESSORDERSTORE_IDS_INVOICES_CREATE_POST: "businessOrderInvoice/createBusinessStoreOrderInvoiceForOrderIds",
    BUSINESSSTOREORDER_INVOICE_CREATE_POST: "/businessOrderInvoice/createBusinessStoreOrderInvoice",
    BUSINESSSTOREORDER_INVOICE_GET: "businessOrderInvoice/getAllBusinessStoreOrderInvoices",
    BUSINESSSTOREORDER_IDS_FOR_INVOICE_POST: "businessOrder/getAllBusinessOrdersByStoreIdForInvoicing",

    BUSINESSSTOREORDER_PAYMENT_POST: "businessPayment/createBusinessPayment",

    RETAILSTOREORDER_DETAILS_DATA_GET: "retailOrder/getRetailOrderDetails",
    RETAILSTOREORDER_PAYMENT_POST: "retailOrderInvoice/createRetailPayment",
    RETAILSTOREORDER_STATUS_UPDATE_POST: "retailOrder/updateRetailOrderStatus",
    RETAILSTOREORDER_DETAILS_ITEM_STATUS_UPDATE_POST: "retailOrder/updateRetailOrderItemStatus",

    BUSINESSSTOREORDERDETAILS_DATA_GET: 'user/getBusinessOrderDetails',
    RETAILSTOREORDERDETAILS_DATA_GET: 'user/getRetailOrderDetails',

    RETAILSTORE_DATA_FOR_RETAIL_GET: "retailOrder/getAllRetailStore",
    RETAILSTORE_INVOICE_GET: "retailOrderInvoice/getAllRetailOrderInvoices",

    RETAILSSTOREORDER_DATA_FOR_RETAIL_GET: "retailOrder/getAllRetailOrders",
    RETAILSTOREORDER_SERVICEITEM__DATA_GET: "retailOrder/getAllRetailServiceItems",

    RETAILSSTOREORDER_INVOICE_GENERATE_PDF_POST: "retailOrderInvoice/generateRetailOrderInvoicePDF",
    RETAILSTOREORDER_INVOICES_DETAILS_GET: "retailOrderInvoice/getAllRetailOrderInvoices",
    RETAILSSTOREORDER_INVOICE_CREATE_POST: "retailOrderInvoice/createRetailOrderInvoice",
    RETAILSSTOREORDER_ID_INVOICES_CREATE_POST: "retailOrderInvoice/createRetailStoreOrderInvoice",
    RETAILSSTOREORDER_IDS_INVOICES_CREATE_POST: "retailOrderInvoice/createRetailStoreOrderInvoiceForOrderIds",
    RETAILSSTOREORDER_IDS_FOR_INVOICE_POST: "retailOrder/GetAllRetailOrdersByStoreIdForInvoicing",

    RETAILSTORE_DATA_GET: 'clientadmin/getAllRetailStore',
    RETAILSTORE_DATA_PUT: 'clientadmin/updateRetailStore',
    RETAILSTORE_DATA_POST: 'clientadmin/createRetailStore',

    RETAILSTOREORDER_DATA_GET: 'user/getAllRetailOrders',
    RETAILSTOREORDER_DATA_PUT: 'user/updateRetailOrder',
    RETAILSTOREORDER_DATA_POST: 'retailOrder/createRetailOrder',

    CUSTOMER_DATA_GET: "customer/searchCustomers",
    CUSTOMER_DATA_POST: "customer/createCustomer",

    CUSTOMER_ORDER_SERVICEITEM_DATA_GET: "customer/getAllCustomerServiceItems",
    CUSTOMER_ORDER_UPDATE_DATA_POST: "customer/updateCustomerOrder",
    CUSTOMER_ORDER_STATUS_UPDATE_POST: "customer/updateCustomerOrderStatus",
    CUSTOMER_ORDER_DETAILS_ITEM_STATUS_UPDATE_POST: "customer/updateCustomerOrderItemStatus",
    CUSTOMER_ORDER_INVOICE_CREATE_POST: "customerOrderInvoice/createCustomerOrderInvoice",
    CUSTOMER_ORDER_INVOICES_DETAILS_GET: "customerOrderInvoice/getAllCustomerOrderInvoices",
    CUSTOMER_ORDER_IDS_FOR_INVOICE_POST: "customer/GetAllCustomerOrdersForInvoicing",

    CUSTOMER_ORDER_DETAILS_DATA_GET: "customer/getCustomerOrderDetails",
    CUSTOMER_ORDER_DATA_GET: 'customer/searchCustomerOrders',
    CUSTOMER_ORDER_DATA_PUT: 'customer/createCustomer',
    CUSTOMER_ORDER_DATA_POST: 'customer/createCustomerOrder',

    CUSTOMER_ORDER_PAYMENT_POST: "customerPayment/createCustomerPayment",
    CUSTOMER_ORDER_INVOICE_GENERATE_POST: "customerOrderInvoice/generateCustomerOrderInvoicePDF",

    CATEGORY_DATA_GET: 'clientadmin/getCategories',
    CATEGORY_DATA_PUT: 'clientadmin/updateCategory',
    CATEGORY_DATA_POST: 'clientadmin/createCategory',

    CLIENT_USER_DATA_GET: 'clientadmin/getAllUsers',
    CLIENT_USER_DATA_PUT: 'clientadmin/updateUser',
    CLIENT_USER_DATA_POST: 'clientadmin/createUser',
    CLIENT_USER_TYPE_DATA_GET: 'clientadmin/getAllUserTypes',

    CLIENT_ROLE_DATA_GET: 'clientadmin/getAllRoles',

    CLIENT_DEPARTMENT_DATA_GET: 'clientadmin/getDepartments',
    CLIENT_DEPARTMENT_DATA_PUT: 'clientadmin/updateDepartment',
    CLIENT_DEPARTMENT_DATA_POST: 'clientadmin/createDepartment',

    CLIENT_BUSINESS_DEPARTMENT_DATA_GET: '/clientadmin/getAllBusinessDepartment',
    CLIENT_BUSINESS_DEPARTMENT_DATA_POST: 'clientadmin/createBusinessDepartment',
    CLIENT_BUSINESS_DEPARTMENT_DATA_PUT: 'clientadmin/updateBusinessDepartment',
    CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_MAP_GET_DATA: "clientadmin/getAllBusinessServiceItemDeptMap",
    CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_MAP_POST_DATA: "clientadmin/cretaeBusinessServiceItemMap",
    CLIENT_BUSINESS_DEPARTMENT_SERVICEITEM_UNMAPPED_DELETE_DATA: "/clientadmin/deleteBusinessServiceItemMap",

    SERVICEITEM_DATA_GET: 'clientadmin/getAllOptiBusinessServiceItems',
    SERVICEITEM_DATA_PUT: 'clientadmin/updateServiceItem',
    SERVICEITEM_DATA_POST: 'clientadmin/createServiceItem',
    SERVICEITEM_BUSINESS_DATA_POST: 'clientadmin/createBusinessServiceItem',
    SERVICEITEM_BUSINESS_DATA_PUT: '/clientadmin/updateBusinessServiceItem',
    SERVICEITEM_BUSINESS_DATA_GET: '/clientadmin/getAllBusinessServiceItems',
    SERVICEITEM_SECTION_DATA_GET: "clientadmin/getAllServiceItemSections",

    PRODUCT_MASTER_SERVICE_ITEM_DATA_GET: "v2/adminserviceitem/getAllBusinessServiceItems",
    PRODUCT_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT: "v2/adminserviceitem/updateBusinessServiceItem",
    PRODUCT_MASTER_SERVICE_ITEM_CREATE_DATA_POST: "v2/adminserviceitem/createBusinessServiceItem",
    PRODUCT_MASTER_MEDIAFILE_DATA_GET: 'v2/adminserviceitem/getAllFiles',
    PRODUCT_MASTER_MEDIAFILE_DATA_POST: 'v2/adminserviceitem/uploadFile',

    PRICE_MASTER_SERVICE_ITEM_DATA_GET: "v2/adminserviceitem/getAllBusinessServiceItemPriceMaster",
    PRICE_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT: "v2/adminserviceitem/updateBusinessServiceItemPriceMaster",
    PRICE_MASTER_SERVICE_ITEM_CREATE_DATA_POST: "v2/adminserviceitem/cretaeBusinessServiceItemPriceMaster",
    PRICE_MASTER_SERVICE_ITEM_REMOVE_DATA_DELETE: "v2/adminserviceitem/deleteBusinessServiceItemPriceMaster",
    PRICE_MASTER_BUSINESS_STORE_DATA_GET: "v2/adminserviceitem/getAllBusinessStoreForClient",
    PRICE_MASTER_BUSINESS_DEPARTMENT_DATA_GET: "v2/adminserviceitem/getAllBusinessDepartment",
    PRICE_MASTER_ASSOCIATE_DEPT_DATA_POST: "v2/adminserviceitem/associateBusinessServiceItemPriceMasterWithDept",

    PROCESSING_CENTER_DATA_GET: "v2/adminbusiness/getAllBusinessProcessingCenters",
    PROCESSING_CENTER_UPDATE_DATA_PUT: "/v2/adminbusiness/updateBusinessProcessingCenter",
    PROCESSING_CENTER_CREATE_DATA_POST: "v2/adminbusiness/createBusinessProcessingCenter",

    COST_CENTER_DATA_GET: "v2/adminbusiness/getAllBusinessCostCenters",
    COST_CENTER_UPDATE_DATA_PUT: "/v2/adminbusiness/updateBusinessCostCenter",
    COST_CENTER_CREATE_DATA_POST: "/v2/adminbusiness/createBusinessCostCenter",

    BUSINESS_STORE_COST_CENTER_DATA_POST: "/v2/adminbusiness/createBusinessStoreCostCenterMap",
    BUSINESS_STORE_PROCESSING_CENTER_DATA_POST: "/v2/adminbusiness/createBusinessStoreProcessingCenterMap",
    BUSINESS_STORE_COST_CENTER_DATA_PUT: "/v2/adminbusiness/updateBusinessStoreCostCenterMap",
    BUSINESS_STORE_PROCESSING_CENTER_DATA_PUT: "/v2/adminbusiness/updateBusinessStoreProcessingCenterMap",
    BUSINESS_STORE_ADMIN_BUSINESS_DATA_GET: '/v2/adminbusiness/getAllBusinessStoresForClient',

    ADMIN_USER_DATA_GET: 'admin/getAllUsers',
    ADMIN_USER_DATA_PUT: 'admin/updateUser',
    ADMIN_USER_TYPE_DATA_GET: 'admin/getAllUserTypes',

    ADMIN_ROLE_DATA_GET: 'admin/getAllRoles',
    ADMIN_ROLE_DATA_PUT: 'admin/updateRole',
    ADMIN_ROLE_DATA_POST: 'admin/createRole',

    ADMIN_DEPARTMENT_DATA_GET: 'admin/getDepartments',
    ADMIN_DEPARTMENT_DATA_PUT: 'admin/updateDepartment',
    ADMIN_DEPARTMENT_DATA_POST: 'admin/createDepartmentForClient/',

    MEDIAFILE_DATA_GET: 'clientadmin/getAllFile',
    MEDIAFILE_DATA_PUT: 'clientadmin/uploadFile',
    MEDIAFILE_DATA_POST: 'clientadmin/uploadFile',

    REPORT_GET_ALL_BUSINESS_ORDER_ITEMS_FOR_BUSINESS: 'reporting/getAllBusinessOrderItemsForBusiness',
    REPORT_GET_ALL_BUSINESS_ORDER_ITEMS_FOR_BUSINESS_STORE: 'reporting/getAllBusinessOrderItemsForBusinessStore',
    REPORT_GET_ALL_BUSINESS_ORDER_ITEMS_FOR_BUSINESS_DEPT: 'reporting/getAllBusinessOrderItemsForBusinessDepartment',
    REPORT_GENERATE_INHOUSE_SALES_FOR_BUSINESS_STORE: 'reporting/GenerateInhouseSaleSummaryReportForBusinessStore',
    REPORT_GENERATE_BUSINESS_SALES_FOR_BUSINESS_STORE: 'reporting/GenerateBusinessSaleSummaryReportForBusinessStore',

    RETAILSTOREBYID_DATA_GET: 'retailCustomer/getRetailStoreById',
    RETAILSTORE_SERVICEITEM__DATA_GET:
      'retailCustomer/getRetailStoreServiceItems',
    RETAILSTORE_SERVICE_ITEM_DATA_GET:
      'retailCustomer/getOptiRetailStoreServiceItems',
    RETAILSTORE_CUSTOMER_ORDER_DATA_POST:
      'retailCustomer/createRetailCustomerOrder',

    BUSINESS_CUSTOMER_ORDER_DATA_GET: '/businessCustomer/getBusinessStoreById',
    BUSINESS_CUSTOMER_ORDER_OPTI_bUS_STORE_ITEMS_GET:
      '/businessCustomer/getOptiBusinessStoreServiceItems',
    BUSINESS_CUSTOMER_ORDER_OPTI_ITEMS_GET:
      '/businessCustomer/getBusinessStoreCustomerServiceItems',
    BUSINESS_STORE_CUSTOMER_ORDER_CREATE_POST:
      '/businessCustomer/createBusinessCustomerOrder',
    BUSINESS_CUSTOMER_ORDER_CREATE_POST:
      '/businessCustomer/createBusinessStoreCustomerOrder',
    BUSINESS_STORE_DEPARTMENT_DATA_GET:
      '/businessCustomer/getBusinessStoreDepartments',
    BUSINESS_STORE_SERVICE_TYPE_DATA_GET: "/businessCustomer/getServiceTypes",
    BUSINESS_STORE_ORDER_DETAILS_BY_ORDERID_POST: "businessCustomer/getBusinessCustomerOrderDetails",
    BUSINESS_STORE_ORDER_STATUS_UPDATE_POST: "businessCustomer/updateBusinessCustomerOrderStatus",


    BUSINESS_ORDER_PICKUP_STORE_DATA_GET: "businessOrderPickup/getBusinessStores",
    BUSINESS_ORDER_PICKUP_DEPT_DATA_GET: "businessOrderPickup/getBusinessStoreDepartments",
    BUSINESS_ORDER_PICKUP_DEPT_SERVICEITEM_DATA_GET: "businessOrderPickup/getBusinessStoreDeptServiceItems",
    BUSINESS_ORDER_PICKUP_STORE_SERVICEITEM_DATA_GET: "businessOrderPickup/getBusinessStoreCustomerServiceItems",
    BUSINESS_ORDER_PICKUP_SERVICE_TYPE_DATA_GET: "businessOrderPickup/getServiceTypes",
    BUSINESS_ORDER_PICKUP_CREATE_DATA_POST: "businessOrderPickup/createBusinessStoreDeptOrder",
    BUSINESS_ORDER_DETAILS_BY_ORDERID_POST: "businessOrderPickup/getBusinessCustomerOrderDetails",
    BUSINESS_ORDER_STATUS_UPDATE_POST: "businessOrderPickup/updateBusinessOrderStatus",

    BUSINESS_SERVICE_ITEM_GET_DATA: "businessOrder/getAllBusinessServiceItemsForBusiness"
  },
};

export enum Categories {
  Laundry = 'Laundry',
  DryClean = 'Dry Clean',
  Wash = 'Wash',
  Iron = 'Iron',
}

export type TCategoryType = keyof typeof Categories;

export const getCategoryColor = (category: string): string => {
  switch (category) {
    case Categories.Laundry:
      return 'rgb(111 225 240)';
    case Categories.DryClean:
      return 'lightGreen';
    case Categories.Wash:
      return 'rgb(221 169 174)';
    default:
      return 'rgb(255 230 97)';
  }
};

export const getGroupByCategory = (data: any) => {
  return data.reduce((acc: any, curr: any) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});
};

export const getGroupByCategoryWithSectionName = (data: any) => {
  return data.reduce((acc: any, curr: any) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    let serviceItemSectionGroup = acc[curr.category].find((group: any) => group.serviceItemSectionName === curr.serviceItemSectionName);

    if (!serviceItemSectionGroup) {
      serviceItemSectionGroup = { serviceItemSectionName: curr.serviceItemSectionName,
        optiServiceItem: []
      };
      acc[curr.category].push(serviceItemSectionGroup)
    }
    serviceItemSectionGroup.optiServiceItem.push(curr);
    return acc;
  }, {});
};

export const OrderType = {
  Retail: 'Retail',
  Business: 'Business',
  Customer: 'Customer',
};
