import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { data } from './makeData';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";
import { useNavigate } from 'react-router-dom';


export type RetailStoreDetails = {
  id: string;
  name: string;
  address: string;
  address2: string; 
  zipCode: string; 
  state: string;
  country: string;
  mail: string;
  phone: string;
  contactName: string;
  contactPhone: string;
};

export const RetailStore = () => {
  const navigate = useNavigate();
  const { httpGet, httpPost, httpPut, httpDelete } = useAPI();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<RetailStoreDetails[]>(() => data); 
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});



  // GET with API
  const fetchRetailStore = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_GET).then((response) => {
       if (response.status === 200) {
          setTableData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching RetailStore: ', response.data.message);
          setTableData([]);
          }
       }
    })
    };

 useEffect(() => {    
    fetchRetailStore();
  }, []); 

 
  // POST with API

  const handleCreateNewRow = (values: RetailStoreDetails) => {
     if (!Object.keys(validationErrors).length) {
        httpPost(WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_POST, values ).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchRetailStore();
           } else {
              if(response.data.error){        
              console.error('Error Updating RetailStore: ', response.data.message);
              }
           }
        });
      }
  };

  // PUT with API

  const handleSaveRowEdits: MaterialReactTableProps<RetailStoreDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        //console.log(row, values);
        tableData[row.index] = values;
        httpPut(WASHUB_CONSTANTS.API_URLS.RETAILSTORE_DATA_PUT+'/'+values.id, values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchRetailStore();
              exitEditingMode(); //required to exit editing mode and close modal
           } else {
              if(response.data.error){        
              console.error('Error Updating RetailStore: ', response.data.message);
              }
           }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<RetailStoreDetails>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  
  const columns = useMemo<MRT_ColumnDef<RetailStoreDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',        
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',        
      },
      {
        accessorKey: 'country',
        header: 'Country',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',        
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',        
      }
    ],
    [],
  );

  const createNewRetailStore = () => {
    const column = [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',        
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',        
      },
      {
        accessorKey: 'country',
        header: 'Country',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'contactName',
        header: 'Contact Name',        
      },
      {
        accessorKey: 'contactPhone',
        header: 'Contact Phone',        
      }
    ];
    navigate('/create-retail-store',{
      state:{column}
    });
  }

  
  return (
    <div style={{padding:"5px 15px"}}>
    <MaterialReactTable
        muiTableBodyRowProps={{
          sx: {
            height: '5px',
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTableContainerProps= {{
          sx: {
              height: "72vh"
          }
        }}
        columns={columns}
        data={tableData}
        enableFullScreenToggle={false}
        enableStickyHeader
        enableColumnOrdering
        enableEditing
        enableHiding
        editDisplayMode='row'
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {/*<Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>*/}
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={createNewRetailStore}
            variant="contained"
          >
            Create New Retail Store
          </Button>
        )}
      />
      
      {/* <CreateNewRetailStoreModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      /> */}
    </div>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<RetailStoreDetails>[];
  onClose: () => void;
  onSubmit: (values: RetailStoreDetails) => void;
  open: boolean;
}

//example of creating a mui dialog modal for creating new rows
// export const CreateNewRetailStoreModal = ({
//   open,
//   columns,
//   onClose,
//   onSubmit,
// }: CreateModalProps) => {
//   const [values, setValues] = useState<any>(() =>
//     columns.reduce((acc, column) => {
//       acc[column.accessorKey ?? ''] = '';
//       return acc;
//     }, {} as any),
//   );

//   const handleSubmit = () => {
//     //put your validation logic here
//     onSubmit(values);
//     onClose();
//   };

//   return (
//     <Dialog  
//         fullWidth
//         maxWidth='md' 
//         open={open}>
//       <DialogTitle style={{backgroundColor: 'purple', borderRadius: '0px 0px 25px 25px', color: '#fff', marginBottom: '20px'}} textAlign="center">Create New Retail Store</DialogTitle>
//       <DialogContent>
//         <form onSubmit={(e) => e.preventDefault()}>
//           <Stack
//             sx={{
//               width: '100%',
//               minWidth: { xs: '300px', sm: '360px', md: '400px' },
//               gap: '1.5rem',
//             }}
//           >
//             {columns.map((column) => (
//               (column.header == 'ID') ? <></> :
//               <TextField
//                 key={column.accessorKey}
//                 label={column.header}
//                 name={column.accessorKey}
//                 onChange={(e) =>
//                   setValues({ ...values, [e.target.name]: e.target.value })
//                 }
//               />
//             ))}
//           </Stack>
//         </form>
//       </DialogContent>
//       <DialogActions sx={{ p: '1.25rem' }}>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button color="secondary" onClick={handleSubmit} variant="contained">
//           Create New Retail Store
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default RetailStore;
