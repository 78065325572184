import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CreateForm } from '../../../../shared/components/create-form/CreateForm'
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessDetails } from '../Business';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const CreateBusiness = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { httpPost , handleAlertBar } = useAPI();
  const columns = location?.state?.column;
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const handleCreateNewRow = (values: BusinessDetails) => {
    if (!Object.keys(validationErrors).length) {
       httpPost(WASHUB_CONSTANTS.API_URLS.BUSINESS_DATA_POST, values).then((response) => {
         console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
         console.log( 'RESPONSE :',response);
          if (response.status === 200) {
            handleAlertBar("success", `New Business Created Successfully`);
            onClose();
          } else {
             if(response.data.error){        
             console.error('Error Updating Business: ', response.data.message);
             }
          }
       });
     }
 };

  const onClose = () => {
    navigate('/business');
  }
  return (
    <>
      <Grid container>
        <Grid item m={2}>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start', cursor: 'pointer' }}
            variant="h5"
            className="mt-1"
            onClick={onClose}
          >
            Business
          </Typography>
        </Grid>
        <Grid item alignContent={'center'}>
          <NavigateNextIcon />
        </Grid>
        <Grid item m={2}>
          <Typography
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-1"
          >
            Create Business
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: '#045464', margin: '10px 5px' }} />
      <CreateForm 
        columns={columns}
        onClose={onClose}
        onSubmit={handleCreateNewRow}
        ButtonLabel={'Create New Business'} />
    </>
  )
}