import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { data, states } from './makeData';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";

export type MediaFileDeatils = {
  id: string;
  name: string;
  url: string;
};

export const MediaFile = () => {
  const { httpGet, httpPost, httpPut, httpDelete, httpPostMedia } = useAPI();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<MediaFileDeatils[]>(() => data);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});


  // GET with API
  const fetchMediaFile = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.MEDIAFILE_DATA_GET).then((response) => {
       if (response.status === 200) {
          setTableData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching mediafile: ', response.data.message);
          setTableData([]);
          }
       }
    })
    };

 useEffect(() => {    
    fetchMediaFile();
  }, []); 

  // POST with API
  console.log( 'jwtToken :',localStorage.getItem("jwtToken"));

  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<MediaFileDeatils>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        //console.log(row, values);
        tableData[row.index] = values;
        httpPut(WASHUB_CONSTANTS.API_URLS.MEDIAFILE_DATA_PUT+'/'+values.id, values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              setTableData([...tableData]);
              exitEditingMode(); //required to exit editing mode and close modal
           } else {
              if(response.data.error){        
              console.error('Error Updating mediafile: ', response.data.message);
              }
           }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<MediaFileDeatils>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );
  
  const columns = useMemo<MRT_ColumnDef<MediaFileDeatils>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        enableEditing: false, 
        enableHiding: false,
        enableSorting: false,        
        size: 140,
      },
      {
        accessorKey: 'url',
        header: 'Media',
        enableEditing: false, 
        enableHiding: false,
        enableSorting: false,
        Cell:( {cell} ) => (  <img src={cell.getValue<string>()} /> ),
      },
    ],
    [],
  );

  return (
    <div style={{ height:'89vh', overflow: 'auto'}}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        enableFullScreenToggle={false}
        enableStickyHeader
        enableColumnOrdering
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New MediaFile
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </div>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<MediaFileDeatils>[];
  onClose: () => void;
  open: boolean;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
}: CreateModalProps) => {

  const { httpGet, httpPost, httpPut, httpDelete, httpPostMedia } = useAPI();
  
  const MAX_FILE_SIZE_MB = 5;
  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];
  
  const [selectedFile, setSelectedFile] = useState('');
  const [error, setError] = useState('');

  const handleFileChange = (event:any) => {
  const file = event.target.files[0];
  console.log("file1-", file);
    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      return;
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      return;
    }

    setSelectedFile(file);
    setError('');
  };



  const handleCreateNewRow = (selectedFile:any) => {
     if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const config = {
        headers: {
          "Content-Type" : "multipart/form-data",
          Authorization: localStorage.getItem("jwtToken") || null,
        },
        
      };

      httpPostMedia(WASHUB_CONSTANTS.API_URLS.MEDIAFILE_DATA_POST, formData, config).then((response) => {
          if (response.status === 200) {
              console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
              console.log( 'RESPONSE :',response);           
           } else {
              if(response.data.error){        
              console.error('Error Updating mediafile: ', response.data.message);
              }else{
                console.error('Server Error Updating mediafile: ', response.data.message);
              }
           }
           setSelectedFile('');
        }).catch(function(error) {
        console.log('Error---',error);
      });

    } else {
      console.error("No file selected");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log("Uploading file...", formData);
    } else {
      console.error("No file selected");
    }
  };

  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleClose = () => {
    setSelectedFile('');
    //put your validation logic here
    onClose();
  };  

  return (
    <Dialog  
        fullWidth
        maxWidth='md' 
        open={open}>
      <DialogTitle style={{backgroundColor: 'purple', borderRadius: '0px 0px 25px 25px', color: '#fff', marginBottom: '20px'}} textAlign="center">Uplaod Image</DialogTitle>
      <DialogContent>
      <Box p={3} border="1px dashed #ccc" borderRadius={8} textAlign="center">
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="image-file-input"
      />
      <label htmlFor="image-file-input">
        <Button variant="outlined" component="span">
          Select Image
        </Button>
      </label>
      {selectedFile && (
        <div>
          <Typography variant="subtitle1" mt={2}>
            Selected Image: 
          </Typography>
        </div>
      )}
      {error && (
        <Typography variant="body2" color="error" mt={2}>
          {error}
        </Typography>
      )}
    </Box>
    </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="secondary" onClick={handleCreateNewRow} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export default MediaFile;
