import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import { Profile } from "../../profile/components/Profile";
import { Menunav } from "../../menu/components/Menu";
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";

export const Header = () => {
  const [ clientDetail, setClientDetail ] = useState<any>({})
  const { httpGet } = useAPI();
  useEffect(() => {
    const fetchProfileData = async () => {
      await httpGet(WASHUB_CONSTANTS.API_URLS.PROFILE_DATA).then(async (response) => {
       if (response.status === 200) {
          await setClientDetail(response.data?.client);
       }
    })}
    if(!clientDetail?.name) {
      fetchProfileData();
    }
  }, [clientDetail]);
  const myStyle = {
    color: "white",
    backgroundColor: "#25BCCA",
    padding: "10px",
    fontFamily: "Sans-Serif",
    height: "11vh"
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        borderBottom: "1px solid rgba(9, 30, 66, 0.14)",
      }}
      style={myStyle}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: 'flex', justifyContent: 'space-between'}}>        
        <h1 >{clientDetail?.name}</h1>    
        <Profile />
      </Grid>  
    </Grid>
  );
};
