import React, { useEffect, useState } from 'react'
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { Button, Card, CardActions, CardContent, CardHeader, DialogContentText, Divider, Grid, Link, MenuItem, TextField, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomButton } from '../../../../orderCenter/modules/CustomButton';
import SuccessModal from '../../../../shared/SuccessModal';
import { BorderBottom } from '@mui/icons-material';

export const ServiceTypeDetail = () => {
    const [serviceTypeList, setServiceTypeList] = useState([]);
    const [serviceTypeId, setServiceTypeId] = useState(`2`);
    const [billNo, setBillNo] = useState('')
    const [serviceCharge, setServiceCharge] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { httpGet, httpPost, handleAlertBar } = useAPI();
    const payload = location.state.payload;
    const orderId = location.state.orderId;
    const itemData = location.state.itemData;
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const todayDate = new Date();
    const end = new Date();
    const tomorrowDate = end.setDate(end.getDate() + 1);
    const [deliveryData, setDeliveryData] = useState({
      order: '',
      pickupDate: '',
      deliveryDate: '',
      businessId: '',
      businessStoreId: '',
      departmentId: ''
    });
    const buid = localStorage.getItem('buid');
    const key = localStorage.getItem('key');
    const [pickupDate, setPickupDate] = useState(dayjs(todayDate));
    const [deliveryDate, setDeliveryDate] = useState(dayjs(tomorrowDate));
    const [orderNote, setOrderNote] = useState('');
    const handlePickupDateChange = (date: any) => {
      setPickupDate(date);
      setDeliveryDate(date.add(1,'day'))
    };
    const handleDeliveyDateChange = (date: any) => {
      setDeliveryDate(date);
    };
    const handleBack = () => {
        navigate(`../orders`, {state: {order: {
            business: {id: payload.businessId},
            businessStore: {id: payload.businessStoreId},
            department: {id: payload.departmentId}
        }}});
    };
    const handleServiceTypeData = (id: string) => {
      setServiceTypeId(id);
    };
    const getTotalQuantity = () => {
        return payload?.serviceItemOrder?.reduce((acc: number, item: any) => {
          return acc + (+item.itemCount);
        }, 0);
      };
  
    useEffect(() => {
      fetchBusinessStoreServiceTypeData();
    }, []);
  
    useEffect(() => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (+serviceTypeId === 1) {
        if (currentHour >= 12) {
          const laterDate = new Date(currentDate.getTime() + 12 * 60 * 60 * 1000);
          if(dayjs(currentDate) < pickupDate) {
            setDeliveryDate(dayjs(pickupDate));
          } else {
            setPickupDate(dayjs(currentDate));
            setDeliveryDate(dayjs(laterDate));
          } 
        } else {
          setPickupDate(dayjs(currentDate));
          setDeliveryDate(dayjs(currentDate));
        }
      } else {
        if (currentHour <= 24) {
          const laterDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
          setPickupDate(dayjs(currentDate));
          setDeliveryDate(dayjs(laterDate));
        }
      }
    }, [serviceTypeId]);
  
    const fetchBusinessStoreServiceTypeData = async () => {
      await httpGet(
        `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_SERVICE_TYPE_DATA_GET}`,
      ).then((response) => {
        if (response.status === 200) {
          setServiceTypeList(response?.data);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching Business Store Department: ',
              response.data.message,
            );
          }
        }
      });
    };

    const createBusinessOrder = async () => {
        payload['serviceTypeId'] = serviceTypeId;
        payload['pickupDate'] = pickupDate.format('YYYY-MM-DD');
        payload['deliveryDate'] = deliveryDate.format('YYYY-MM-DD');
        payload['orderNote'] = orderNote;
        payload['billNo'] = billNo;
      if (payload) {
        if (orderId) {
          httpPost(
            `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DATA_PUT}/${orderId}`,
            payload,
          )
            .then((response) => {
              if (response?.status === 200) {
                navigate('..', {
                  state: { order: location?.state?.order },
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        } else {
          httpPost(
            WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DATA_POST,
            payload,
          )
            .then((response) => {
              if (response?.status === 200) {
                setIsSuccessModal(true);
                setDeliveryData({
                    order: response.data.order,
                    pickupDate: pickupDate.format('YYYY-MM-DD'),
                    deliveryDate: deliveryDate.format('YYYY-MM-DD'),
                    businessId: payload?.businessId,
                    businessStoreId: payload?.businessStoreId,
                    departmentId: payload?.departmentId
                  });
                  setOrderNote('');
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        }
      }
    };
    const submitOrder = () => {
        createBusinessOrder();
    };
    return (
      <div>
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          style={{
            padding: '10px 15px 0px 15px',
          }}
        >
          <Grid item md={0.5} lg={0.5}>
            <Link
              component="button"
              title="back"
              onClick={handleBack}
            >
              <KeyboardArrowLeftIcon
                fontSize="large"
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={10}
            sm={11}
            md={11}
            lg={11}
            display={'flex'}
            justifyContent={'center'}
          >
            <Typography
              variant="h5"
              className="mb-0"
              color={'primary'}
              sx={{ padding: '3px' }}
            >
              Select Service Type
            </Typography>
          </Grid>
        </Grid>
        <hr></hr>
        <Grid
          container
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ padding: '0px 20px' }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} >
                <TextField
                  fullWidth
                  size='small'
                  key="serviceType"
                  label="Select Service Type"
                  name="serviceType"
                  className="mt-3"
                  select
                  onChange={(e) => {
                    handleServiceTypeData(e.target.value);
                  }}
                  value={serviceTypeId}
                >
                  {serviceTypeList.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {+serviceTypeId === 1 && (
                <div className="mx-3">
                  <InfoIcon color="warning" sx={{ fontSize: '1rem' }} />
                  <span style={{ fontSize: '0.8rem' }}>
                    Same day delivery charges apply
                  </span>
                </div>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'Pickup Date'}
                    slotProps={{ textField: { variant: 'standard' } }}
                    name="pickupDate"
                    value={dayjs(pickupDate)}
                    // minDate={dayjs(todayDate)}
                    onChange={handlePickupDateChange}
                    sx={{width: '45%' }}
                    className='ms-1 me-4'
                  />
                </LocalizationProvider>
  
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={'Delivery date'}
                    slotProps={{ textField: { variant: 'standard' } }}
                    name="deliveryDate"
                    value={dayjs(deliveryDate)}
                    minDate={dayjs(pickupDate)}
                    onChange={handleDeliveyDateChange}
                    sx={{ width: '45%' }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} >
          <TextField
                  fullWidth
                  size='small'
                  key="billNo"
                  label="Bill Number"
                  name="billNo"
                  className="mt-3 me-3"
                  onChange={(e) => {
                    setBillNo(e.target.value);
                  }}
                  value={billNo}
                >
                </TextField>
          </Grid>
        </Grid>
        <div
          style={{
            padding: '5px 20px',
            width: '100%',
          }}
        >
          <Typography className="body1">Order Details</Typography>
          <Card
            elevation={2}
          >
            <CardContent sx={{ minWidth: 200, maxHeight: '150px', overflow: 'auto' }}>
              {itemData?.length > 0 &&
                itemData.map((item: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'space-between'}
                  >
                    <Grid item>
                      <DialogContentText
                        sx={{ fontSize: '15px', color: 'black' }}
                      >
                        {item?.item}
                      </DialogContentText>
                    </Grid>
                    <Grid item>
                      <DialogContentText
                        sx={{ fontSize: '15px', color: 'black' }}
                      >
                        {item?.quantity}
                      </DialogContentText>
                    </Grid>
                  </Grid>
                ))}
            </CardContent>
            <CardActions sx={{borderTop: '1px solid #045464'}}>
            <Grid
                container
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Grid item>
                <Typography variant="h5">Total Count</Typography>
                </Grid>
                <Grid item>
                <Typography variant="h5">{`${getTotalQuantity()}`} items </Typography>
                </Grid>
              </Grid>
      </CardActions>
          </Card>
        </div>
        <div style={{padding: '5px 20px'}}>
        <TextField
              id="outlined-helperText"
              label="Add Notes"
              fullWidth
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
              multiline
              rows={2}
            />
        </div>
        <div className='d-flex justify-content-end me-3'>
            <div>
            <Button
                fullWidth
                color="primary"
                onClick={submitOrder}
                variant="contained"
                sx={{color: "white"}}
              >{'Confirm & Submit >>'}</Button>
            </div>
        </div>
        {isSuccessModal && (
          <SuccessModal
            isOpen={isSuccessModal}
            closeModal={setIsSuccessModal}
            deliveryData={deliveryData}
            isBusiness={false}
            isBusinessStoreOrder={false}
            isBusinessOrder={true}
          />
        )}
      </div>
    );
}