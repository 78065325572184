import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Link, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../shared/services/api/API';
import { MRT_ColumnDef, MRT_EditActionButtons, MaterialReactTable, MaterialReactTableProps } from 'material-react-table';
import { CustomButton } from '../CustomButton';

export type UpdateOrderDeatils = {
  id: string;
  name: string;
  description: string;
  price: string;
  categoryId: string;
  mediaItemId: string;
  orderId: string;
  orderDate: string;
  orderStatus: string;
};
export const UpdateOrder = () => {
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState('');
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [tableData, setTableData] = useState<UpdateOrderDeatils[]>([]);
  const { httpPost, handleAlertBar } = useAPI();
  const [ statusOptions, setStatusOptions] = useState(['DELIVERED_PARTIAL', 'DELIVERED']);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const isBusinessStoreOrder = location.pathname.includes('businessStoreOrder');
  const key = localStorage.getItem('key');

  const handleBack = () => {
    if(isBusinessStoreOrder) {
      navigate(`/businessStoreOrder?${key}`);

    } else {
      navigate(`/orderCenter/home`);
    }
  };

  const columns = useMemo<MRT_ColumnDef<UpdateOrderDeatils>[]>(
    () => [
      // {
      //   accessorKey: 'orderId',
      //   header: 'Order Id',
      //   enableEditing: false,
      //   size: 10,
      // },
      {
        accessorKey: 'totalItemCount',
        header: 'Total Item Count',
        enableEditing: false,
        enableHiding: true,
        size: 10,
      },
      {
        accessorKey: 'orderStatus',
        header: 'Status',
        size: 20,
        editVariant: 'select',
        editSelectOptions: statusOptions,
        muiEditTextFieldProps: ({ cell, row }) => ({
          select: true,
          onChange: (value: any) => handleStatusChange(value,row.original),
        }),
      },
    ],
    [],
  );

  const submitHandler = async () => {
    let url = '';
    if(isBusinessStoreOrder) {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_ORDER_DETAILS_BY_ORDERID_POST}/${key}/${orderId}`
    } else {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_DETAILS_BY_ORDERID_POST}/${orderId}`
    }
    try {
      const response = await httpPost(
        url,
        null,
      );
      if (response.status === 200) {
        if (response?.data) {
          setOrderDetails(response?.data);
          setTableData([response?.data]);
        }
        
      }
    } catch (error) {
      console.log('error', error);
    }

  }

  const handleStatusChange =(event: any, row: any) =>{
    const payload = {
      orderId: row.orderId,
      status: event.target.value,
    }
    let url = '';
    if(isBusinessStoreOrder) {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_ORDER_STATUS_UPDATE_POST}/${key}/${row.orderId}`
    } else {
      url = `${WASHUB_CONSTANTS.API_URLS.BUSINESS_ORDER_STATUS_UPDATE_POST}/${row.orderId}`
    }
    httpPost(
     url,
      payload,
    ).then((response) => {
        if (response?.status === 200) {
            handleAlertBar("success", `Status updated successfully`);
            handleBack();
          }
        })
      .catch((error: any) => {
        console.log('error', error);
        handleAlertBar("error", error);
      });

  }

  return (
    <div>
      <Grid
        container
        display={'flex'}
        justifyContent={'center'}
        style={{
          backgroundColor: '#045464',
          padding: '10px 15px 15px',
          borderRadius: 'none',
        }}
      >
        <Grid item md={0.5} lg={0.5}>
          <Link
            component="button"
            title="back to user detail"
            onClick={handleBack}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              style={{ color: '#ffffff' }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={11}
          display={'flex'}
          justifyContent={'center'}
        >
          <Typography
            variant="h5"
            className="mb-0"
            style={{ color: '#ffffff', padding: '3px' }}
          >
            Update Order
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={2} padding={'0px 10px'}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextField
            fullWidth
            key="orderId"
            label="Enter Order Id"
            name="orderId"
            onChange={(e) => {
              setOrderId(e.target.value);
            }}
            value={orderId}
          >
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
        <CustomButton
                onClick={submitHandler}
                variant="contained"
                disabled={orderId === ''}
                label={"Search Order"}
              />
        </Grid>
      </Grid>
      <hr></hr>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '5px 10px 10px 10px',
        }}
      >
        <Paper sx={{ width: '100%', height: '50%', padding: '10px' }}>
          <div style={{ marginTop: '10px' }}>
            <MaterialReactTable
              muiTableBodyRowProps={{
                sx: {
                  height: '5px',
                },
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  width: '100%',
                  '& .css-1tbggly': {
                    minHeight: '0px',
                  },
                  '& .css-10gei56': {
                    minHeight: '0px',
                  },
                },
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 20,
                },
              }}
              muiTableContainerProps={{
                sx: {
                  height: '40vh',
                },
              }}
              columns={columns}
              data={tableData}
              enableFullScreenToggle={false}
              enableStickyHeader
              positionActionsColumn="last"
              enableRowActions
              enableColumnActions={false}
              enableColumnFilters={false}
              enableFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableHiding={false}
              enableDensityToggle={false}
              editDisplayMode="cell" 
              enableEditing={true} 
              renderRowActions={({ row, table }) => (
                <Box>
                  <Button
                    variant="contained"
                    onClick={()=> {
                      table.setEditingRow(row);
                    }}
                    fullWidth
                  >
                    Update
                  </Button>
                </Box>
              )}
            />
          </div>
        </Paper>
      </div>
      
    </div>
  );
};
