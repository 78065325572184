import { RetailStoreDetails } from './RetailStore';

export const data: RetailStoreDetails[] = [
  {
    id: '9s41rp',
    name: 'Kelvin',
    address: 'Langosh',
    address2: 'Langosh',
    zipCode: '564321',
    mail: 'Jerod14@hotmail.com',
    state: 'Ohio',
    country: 'IN',
    phone: '9876521780',
    contactName: "Msr",
    contactPhone: "54385834583",
  },
  {
    id: '08m6rx',
    name: 'mohan',
    address: 'Langosh',
    address2: 'Langosh',
    zipCode: '564321',
    mail: 'Jerod14@hotmail.com',
    state: 'Ohio',
    country: 'IN',
    phone: '9876521780',
    contactName: "ssr",
    contactPhone: "834583",
  },
];
