import { Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from 'react'
import './ClothesSelectionList.scss';

export const  ClothesSelectionList = (Props: any) => {
  return (
        <List className='clothes-list-container'>
          {Props?.filteredClothesList?.length > 0 ? (Props?.filteredClothesList?.map((item: any, index: number) => (
            <>
            <ListItem style={{padding: "0px"}} key={`${index}_${item}`} secondaryAction={
                <div style={{display: "flex", flexDirection: "row", justifyContent: "end"}}>
                <Link
                      component="button"
                      title="remove item"
                      onClick={() => {
                        Props?.removeItem(item);
                      }}
                    >
                      <RemoveCircleOutlineIcon color={"primary"} style={{width:"1.3em", height:"1.3em"}}/>
                    </Link>
                      <TextField
                        id="editInput"
                        variant="standard"
                        sx={{ width: '15%' }}
                        value={Props?.selectedItems[item.id] || 0}
                        onChange={(event: any) =>
                            Props?.itemChangeHandler(event, item)
                        }
                        inputProps={{
                          style: {
                            textAlign: 'center',
                            fontSize: "1.2rem"
                          },
                        }}
                      />
                    <Link
                      component="button"
                      title="add item"
                      onClick={() => {
                        Props?.addItem(item);
                      }}
                    >
                      <AddCircleOutlineIcon color={"primary"} style={{width:"1.3em", height:"1.3em"}}/>
                    </Link>
                </div>
      }>
                <ListItemAvatar>
                <img
                      src={item?.mediaItem?.url}
                      width={'80px'}
                      height={'80px'}
                      alt="icon"
                      id="icon"
                    />
                </ListItemAvatar>
                <ListItemText sx={{
                  '& .css-c5fo71-MuiTypography-root': {
                    fontSize: '1.3rem'
                  },
                  '& .css-1aglti4-MuiTypography-root': {
                    fontSize: '1rem'
                  },
                  whiteSpace: {
                    xs: 'wrap',
                    sm: 'nowrap',
                    md: 'nowrap',
                    lg: 'nowrap'
                  },
                  maxWidth: {
                    xs: '150px',
                  }
                }}
                primary={item.name} 
                secondary={Props?.useFor === "pickupOrder" || Props?.useFor === 'businessStoreOrder' ? '' : `AED: ${item.price}`} />
              </ListItem>
              <Divider sx={{bgcolor: '#045464'}} />
            </>
        ))) : (
              <Typography
                variant="subtitle1"
                color={'GrayText'}
                sx={{ fontSize: '14px', padding: '30px 40%' }}
              >
                No Items to show{' '}
              </Typography>)}
        </List>
  )
}