import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { Profile } from '../../modules/profile/components/Profile';
import { useAPI } from '../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../shared/Constants';

const Header = () => {
  const [clientDetail, setClientDetail] = useState<any>({});
  const { httpGet } = useAPI();
  useEffect(() => {
    httpGet(WASHUB_CONSTANTS.API_URLS.PROFILE_DATA)
      .then((response) => {
        console.log('res', response);
        if (response.status === 200) {
          setClientDetail(response.data?.client);
        }
      })
      .catch((error: any) => {
        console.log('err', error);
      });
  }, []);
  const myStyle = {
    color: 'white',
    backgroundColor: '#25BCCA',
    padding: '10px',
    fontFamily: 'Sans-Serif',
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid rgba(9, 30, 66, 0.14)',
      }}
      style={myStyle}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h1>{clientDetail?.clientlogo || clientDetail?.name}</h1>
        <Profile />
      </Grid>
    </Grid>
  );
};

export default Header;
