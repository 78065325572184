import React from 'react';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';

export const CustomerInvoiceList = (Props: any) => {
  const { httpPost } = useAPI();
  const generateCustomerOrderInvoice = (row: any) => {
    const payload = {
      clientId: row.customer.client.id,
      invoiceId: row.invoiceId,
      customerId: row.customer.id,
    };
    httpPost(
      WASHUB_CONSTANTS.API_URLS.CUSTOMER_ORDER_INVOICE_GENERATE_POST,
      payload,
    ).then((response) => {
      if (response.status === 200) {
        console.log('invoice response ---> ' + response.data);
      } else {
        if (response.data.error) {
          console.error('Error Updating Business: ', response.data.message);
        }
      }
    });
  };
  return (
    <div style={{ padding: '0px 15px 5px 15px' }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 100,
          },
        }}
        muiTablePaperProps={{
          elevation: 2,
          sx: {
            width: '100%',
            '& .css-1tbggly': {
              minHeight: '5px',
            },
          },
        }}
        columns={Props?.columns}
        data={Props?.tableData}
        editDisplayMode="cell" // ('modal', 'row', 'cell', and 'custom' are also available)
        enableEditing
        positionActionsColumn="last"
        enableRowActions
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableFilters={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableStickyHeader
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              variant="contained"
              onClick={() => generateCustomerOrderInvoice(row.original)}
            >
              Downoad Pdf
            </Button>
          </Box>
        )}
      />
    </div>
  );
};
