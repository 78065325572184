import React from 'react';
import { InitialLaundryCartData, LaundryCartContext } from './service.context';

export const LaundryCartProvider = React.memo(function LaundryCartProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [data, setData] = React.useState(InitialLaundryCartData?.data);
  const [clientInfo, setClientInfo] = React.useState(
    InitialLaundryCartData?.clientInfo,
  );

  return (
    <LaundryCartContext.Provider
      value={{
        clientInfo,
        setClientInfo,
        data,
        setData,
      }}
    >
      {children}
    </LaundryCartContext.Provider>
  );
});
