import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticationContext } from './Contexts';
import { ProtectedRoute } from './ProtectedRoute';
import { Home } from '../modules/home/components/Home';

import { ProfileAccount } from '../modules/personal-account/Component/PersonalAccount';
import { LandingPage } from './components/landing-page/LandingPage';
import { Login } from './components/LogIn/Login';

import { Client } from '../modules/client/components/Client';
import { Business } from '../modules/business/components/Business';
import { BusinessStore } from '../modules/business-store/components/BusinessStore';
import { RetailStore } from '../modules/retail-store/components/RetailStore';
import { Category } from '../modules/category/components/Category';
import { Department } from '../modules/department/components/Department';
import { Role } from '../modules/role/components/Role';
import { User } from '../modules/user-management/components/User';
import { ServiceItem } from '../modules/service-item/components/ServiceItem';
import { BusinessStoreOrder } from '../modules/business-store-order/components/BusinessStoreOrder';
import { RetailStoreOrder } from '../modules/retail-store-order/components/RetailStoreOrder';
import { CustomerOrder } from '../modules/customer-order/components/CustomerOrder';
import { MediaFile } from '../modules/media/components/MediaFile';
import Orders from '../modules/orders/Orders';
import { UserInfoForm } from '../modules/user-order/components/user-info-form/UserInfoForm';
import { ClothesSeletion } from '../modules/user-order/components/clothes-seletion/ClothesSeletion';
import { PickAndDrop } from '../modules/user-order/components/pick-and-drop/PickAndDrop';
import { Login as CustomerLogin } from '../collectionCenter/modules/Login';
import Dashboard from '../collectionCenter/modules/Dashboard';
import CollectionPoint from '../collectionCenter/modules/CollectionPoint';
import EmployeeSetup from '../collectionCenter/modules/EmployeeSetup';
import { RetailStoreOrderDetails } from '../modules/retail-store-order/components/retail-store-order-detail/RetailStoreOrderDetails';
import { GenerateInvoices } from '../modules/business-invoices/components/generate-invoices/GenerateInvoices';
import { BusinessInvoices } from '../modules/business-invoices/components/BusinessInvoices';
import { BusinessOrderDetails } from '../modules/business-store-order/components/business-order-details/BusinessOrderDetails';
import { CreateBusinessStore } from '../modules/business-store/components/create-business-store/CreateBusinessStore';
import { CreateBusiness } from '../modules/business/components/create-business/CreateBusiness';
import { BusinessDeliveryNotes } from '../modules/business-delivery-notes/components/BusinessDeliveryNotes';
import { CreateRetailStore } from '../modules/retail-store/components/create-retail-store/CreateRetailStore';
import { RetailInvoices } from '../modules/retail-invoices/components/RetailInvoices';
import { AddBusinessPayment } from '../modules/business-payment/components/add-business-payment/AddBusinessPayment';
import { GenerateRetailInvoice } from '../modules/retail-invoices/components/generate-retail-invoice/GenerateRetailInvoice';
import { AddRetailPayment } from '../modules/retail-payment/components/add-retail-payment/AddRetailPayment';
import { AddCustomerPayment } from '../modules/customer-payment/components/add-customer-payment/AddCustomerPayment';
import { RetailStoreCustomerDetails } from '../modules/retail-store-order/components/retail-store-customer-details/RetailStoreCustomerDetails';
import { CustomerInvoices } from '../modules/customer-invoices/components/CustomerInvoices';
import { GenerateCustomerInvoice } from '../modules/customer-invoices/components/generate-customer-invoice/GenerateCustomerInvoice';
import { Customer } from '../modules/customer/components/Customer';
import { CreateCustomer } from '../modules/customer/components/create-customer/CreateCustomer';
import { CustomerDetails } from '../modules/customer-order/components/customer-details/CustomerDetails';
import { CustomerCardOrder } from '../modules/orders/CustomerCardOrder';
import { CustomerOrderDetails } from '../modules/customer-order/components/customer-order-detail/CustomerOrderDetails';
import { PickupDeliveryAddress } from '../modules/orders/components/pickup-delivery/PickupDeliveryAddress';
import { ServiceType } from '../modules/user-order/components/select-service-type/ServiceType';
import { VerifyBusinessOrderDetails } from '../modules/business-order-verify/components/verify-business-order-details/VerifyBusinessOrderDetails';
import { BusinessOrderVerify } from '../modules/business-order-verify/components/BusinessOrderVerify';
import { DeliveryItemDetails } from '../modules/business-delivery-notes/components/delivery-item-details/DeliveryItemDetails';
import { OrderLogin } from '../orderCenter/modules/login/OrderLogin';
import { OrderHome } from '../orderCenter/modules/order-home/OrderHome';
import { PickupOrder } from '../orderCenter/modules/pickup-order/PickupOrder';
import { UpdateOrder } from '../orderCenter/modules/update-order/UpdateOrder';
import { OrderServiceType } from '../orderCenter/modules/order-service-type/OrderServiceType';
import { BusinessStoreHome } from '../modules/user-order/components/business-store-home/BusinessStoreHome';
import { ServiceTypeDetail } from '../modules/orders/components/service-type-detail/ServiceTypeDetail';
import { InhouseSales } from '../modules/reports/components/inhouse-sales/InhouseSales';
import { BusinessSales } from '../modules/reports/components/business-sales/BusinessSales';
import { BusinessDepartment } from '../modules/business-department/components/BusinessDepartment';
import { CreateBusinessDepartment } from '../modules/business-department/components/create-business-department/CreateBusinessDepartment';
import { BusinessServiceItem } from '../modules/service-item/components/business-service-item/BusinessServiceItem';
import { CreateBusinessServiceItem } from '../modules/service-item/components/business-service-item/CreateBusinessServiceItem';
import { MappedServiceItem } from '../modules/business-department/components/mapped-service-item/MappedServiceItem';
import { CreatePriceMaster } from '../modules/service-item/components/price-master/CreatePriceMaster';
import { PriceMaster } from '../modules/service-item/components/price-master/PriceMaster';
import { CostCenter } from '../modules/cost-center/components/CostCenter';
import { ProcessingCenter } from '../modules/processing-center/components/ProcessingCenter';
import {CreateCostCenter} from '../modules/cost-center/components/CreateCostCenter';
import { CreateProcessingCenter } from '../modules/processing-center/components/CreateProcessingCenter';
import { CreateUser } from '../modules/user-management/components/CreateUser';

export const Routers = () => {
  const { token, urlPath } = useContext(AuthenticationContext);
  const role = localStorage.getItem('userRole');
  const type = localStorage.getItem('userType');

  return (
    <Routes>
      <Route
        path="/"
        element={
          urlPath.includes('retailCustomerOrder') ? (
            <UserInfoForm />
          ) : !token ? (
            <Login />
          ) : role == 'SUPER_ADMIN' ? (
            <Navigate to="/client" replace />
          ) : type == 'Business' ? (
            <Navigate to="/business-store-order" replace />
          ) : type == 'Retail' ? (
            <Navigate to="/retail-store-order" replace />
          ) : type == 'Customer' ? (
            <Navigate to="/customer" replace />
          ) : (
            <Navigate to="/business" replace />
          )
        }
      />
      <Route
        path="/login"
        element={
          !token ? (
            <Login />
          ) : role == 'SUPER_ADMIN' ? (
            <Navigate to="/client" replace />
          ) : (
            <Navigate to="/business" replace />
          )
        }
      />
      {/* collection center */}
      <Route path="/collectionCenter/login" element={<CustomerLogin />} />
      <Route
        path="/collectionCenter/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/collectionCenter/collectionPoint"
        element={
          <ProtectedRoute>
            <CollectionPoint />
          </ProtectedRoute>
        }
      />
      <Route
        path="/collectionCenter/employeeSetup"
        element={
          <ProtectedRoute>
            <EmployeeSetup />
          </ProtectedRoute>
        }
      />

      {/* Order Center */}
      <Route path="/orderCenter" element={<OrderLogin />} />
      <Route path="/orderCenter/login" element={<OrderLogin />} />
      <Route path="/orderCenter/home" 
        element={
          <ProtectedRoute>
            <OrderHome />
          </ProtectedRoute>
        } />
      <Route path="/orderCenter/pickup-order" element={<ProtectedRoute><PickupOrder /></ProtectedRoute>} />
      <Route path="/orderCenter/update-order" element={<ProtectedRoute><UpdateOrder /></ProtectedRoute>} />
      <Route path="/orderCenter/pickup-order">
          <Route path="order-service-type" element={<ProtectedRoute><OrderServiceType/></ProtectedRoute>} />
      </Route>

      {/* Retail App */}
      {/* <Route path="/retail-app" element={<RetailLoginPage />} />
      <Route path="/retail-app/login" element={<RetailLoginPage />} />
      <Route path="/retail-app/home" element={<RetailHomePage />} /> */}

      <Route
        path="/retail-store"
        element={
          <ProtectedRoute>
            <RetailStore />
          </ProtectedRoute>
        }
      />
      <Route
        path="/client"
        element={
          <ProtectedRoute>
            <Client />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business"
        element={
          <ProtectedRoute>
            <Business />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-business"
        element={
          <ProtectedRoute>
            <CreateBusiness />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business-store"
        element={
          <ProtectedRoute>
            <BusinessStore />
          </ProtectedRoute>
        }
      />
      <Route
        path="/business-department"
        element={
          <ProtectedRoute>
            <BusinessDepartment />
          </ProtectedRoute>
        }
      />
      <Route path="/business-department">
        <Route
          path="create-business-department"
          element={
            <ProtectedRoute>
              <CreateBusinessDepartment />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/business-department">
        <Route
          path="mapped-service-item"
          element={
            <ProtectedRoute>
              <MappedServiceItem />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/create-business-store"
        element={
          <ProtectedRoute>
            <CreateBusinessStore />
          </ProtectedRoute>
        }
      />

      <Route
        path="/business-store-order"
        element={
          <ProtectedRoute>
            <BusinessStoreOrder />
          </ProtectedRoute>
        }
      />

      <Route path="/business-store-order">
        <Route
          path="order-details"
          element={
            <ProtectedRoute>
              <BusinessOrderDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/business-store-order">
        <Route
          path="orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/business-store-order">
        <Route
          path="order-summary"
          element={
            <ProtectedRoute>
              <ServiceTypeDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/business-store-order">
        <Route
          path="add-payment"
          element={
            <ProtectedRoute>
              <AddBusinessPayment />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/business-verify"
        element={
          <ProtectedRoute>
            <BusinessOrderVerify />
          </ProtectedRoute>
        }
      />

      <Route path="/business-verify">
        <Route
          path="order-details-verify"
          element={
            <ProtectedRoute>
              <VerifyBusinessOrderDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/business-invoices"
        element={
          <ProtectedRoute>
            <BusinessInvoices />
          </ProtectedRoute>
        }
      />

      <Route path="/business-invoices">
        <Route
          path="generate-invoices"
          element={
            <ProtectedRoute>
              <GenerateInvoices />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/business-payments"
        element={
          <ProtectedRoute>
            <AddBusinessPayment />
          </ProtectedRoute>
        }
      />

      <Route
        path="/business-delivery-notes"
        element={
          <ProtectedRoute>
            <BusinessDeliveryNotes />
          </ProtectedRoute>
        }
      />
      <Route path="/business-delivery-notes">
        <Route
          path="delivery-item-details"
          element={
            <ProtectedRoute>
              <DeliveryItemDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/department"
        element={
          <ProtectedRoute>
            <Department />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user"
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />
      <Route path="/user">
        <Route
          path="create-user"
          element={
            <ProtectedRoute>
              <CreateUser/>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/category"
        element={
          <ProtectedRoute>
            <Category />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cost-center"
        element={
          <ProtectedRoute>
            <CostCenter />
          </ProtectedRoute>
        }
      />
      <Route path="/cost-center">
        <Route
          path="create-cost-center"
          element={
            <ProtectedRoute>
              <CreateCostCenter />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/processing-center"
        element={
          <ProtectedRoute>
            <ProcessingCenter />
          </ProtectedRoute>
        }
      />
      <Route path="/processing-center">
        <Route
          path="create-processing-center"
          element={
            <ProtectedRoute>
              <CreateProcessingCenter />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/role"
        element={
          <ProtectedRoute>
            <Role />
          </ProtectedRoute>
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfileAccount />
          </ProtectedRoute>
        }
      />

      {/* <Route
        path="/service-item"
        element={
          <ProtectedRoute>
            <ServiceItem />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/business-service-item"
        element={
          <ProtectedRoute>
            <BusinessServiceItem />
          </ProtectedRoute>
        }
      />
      <Route path="/business-service-item">
        <Route
          path="create-business-service-item"
          element={
            <ProtectedRoute>
              <CreateBusinessServiceItem/>
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/price-master"
        element={
          <ProtectedRoute>
            <PriceMaster />
          </ProtectedRoute>
        }
      />
      <Route path="/price-master">
        <Route
          path="create-price-master"
          element={
            <ProtectedRoute>
              <CreatePriceMaster/>
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/retail-store-order"
        element={
          <ProtectedRoute>
            <RetailStoreOrder />
          </ProtectedRoute>
        }
      />

      <Route path="/retail-store-order">
        <Route
          path="customer-details"
          element={
            <ProtectedRoute>
              <RetailStoreCustomerDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/retail-store-order">
      <Route
        path="orders"
        element={
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        }
      />
      </Route>

      <Route path="/retail-store-order">
        <Route
          path="order-details"
          element={
            <ProtectedRoute>
              <RetailStoreOrderDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/retail-store-order">
        <Route
          path="add-payment"
          element={
            <ProtectedRoute>
              <AddRetailPayment />
            </ProtectedRoute>
          }
        />
      </Route>

       <Route
          path="/create-retail-store"
          element={
            <ProtectedRoute>
              <CreateRetailStore />
            </ProtectedRoute>
          }
        />

      <Route
        path="/retail-invoices"
        element={
          <ProtectedRoute>
            <RetailInvoices />
          </ProtectedRoute>
        }
      />

      <Route path="/retail-invoices">
        <Route
          path="generate-invoices"
          element={
            <ProtectedRoute>
              <GenerateRetailInvoice />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/retail-payments"
        element={
          <ProtectedRoute>
            <AddRetailPayment />
          </ProtectedRoute>
        }
      />

      <Route
        path="/customer-order"
        element={
          <ProtectedRoute>
            <CustomerOrder />
          </ProtectedRoute>
        }
      />

      <Route path="/customer-order">
        <Route
          path="order-details"
          element={
            <ProtectedRoute>
              <CustomerOrderDetails />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/customer-order">
        <Route
          path="pick-and-drop"
          element={
            <ProtectedRoute>
              <PickupDeliveryAddress />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer-order">
        <Route
          path="card-details"
          element={
            <ProtectedRoute>
              <CustomerCardOrder />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer-order">
        <Route
          path="customer-details"
          element={
            <ProtectedRoute>
              <CustomerDetails />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer"
        element={
          <ProtectedRoute>
            <Customer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer">
        <Route
          path="create-customer"
          element={
            <ProtectedRoute>
              <CreateCustomer />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer">
        <Route
          path="create-order"
          element={
            <ProtectedRoute>
              <CustomerCardOrder />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer">
        <Route
          path="pick-and-drop"
          element={
            <ProtectedRoute>
              <PickupDeliveryAddress />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer-invoices"
        element={
          <ProtectedRoute>
            <CustomerInvoices />
          </ProtectedRoute>
        }
      />

      <Route path="/customer-invoices">
        <Route
          path="generate-invoices"
          element={
            <ProtectedRoute>
              <GenerateCustomerInvoice />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/customer-payments"
        element={
          <ProtectedRoute>
            <AddCustomerPayment />
          </ProtectedRoute>
        }
      />

      <Route
        path="/orders"
        element={
          <ProtectedRoute>
            <Orders />
          </ProtectedRoute>
        }
      />

      <Route
        path="/mediafile"
        element={
          <ProtectedRoute>
            <MediaFile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inhouse"
        element={
          <ProtectedRoute>
            <InhouseSales />
          </ProtectedRoute>
        }
      />
      <Route
        path="/businesssales"
        element={
          <ProtectedRoute>
            <BusinessSales />
          </ProtectedRoute>
        }
      />
      <Route path="/retailCustomerOrder" element={<UserInfoForm />} />
      <Route path="/businessCustomerOrder" element={<UserInfoForm />} />
      <Route path="/business-clothes-selection" element={<ClothesSeletion />} />
      <Route path="/clothes-selection" element={<ClothesSeletion />} />
      <Route path="/checkout" element={<PickAndDrop />} />
      <Route path="/business-checkout" element={<PickAndDrop />} />
      {/* <Route path="/businessStoreOrder" element={<ClothesSeletion />} /> */}
      <Route path="/businessStoreOrder" element={<BusinessStoreHome />} />
      <Route path="/businessStoreOrder">
        <Route
          path="clothes-selection"
          element={
              <ClothesSeletion />
          }
        />
      </Route>
      <Route path="/businessStoreOrder">
        <Route
          path="update-order"
          element={
              <UpdateOrder/>
          }
        />
      </Route>
      <Route path="/service-type" element={<ServiceType/>} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
