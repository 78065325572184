import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  MRT_EditActionButtons,  
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";
import { useNavigate } from 'react-router-dom';
import { UserDetails } from './makeData';

export const User = () => {
  const { httpGet, httpPost, httpPut, httpDelete, handleAlertBar } = useAPI();
  const [tableData, setTableData] = useState<UserDetails[]>([]);

  const [roleDisplayData, setRoleDisplayData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const [departmentDisplayData, setDepartmentDisplayData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [userTypeDisplayData, setUserTypeDisplayData] = useState([]);
  const [userTypeData, setUserTypeData] = useState([]);

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const role  = localStorage.getItem("userRole");
  const navigate = useNavigate();

// GET with API : User
    const fetchUser = async () => {
      const urlToGet = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_USER_DATA_GET : WASHUB_CONSTANTS.API_URLS.CLIENT_USER_DATA_GET ;
      httpGet(urlToGet).then((response) => {
       if (response.status === 200) {
          setTableData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching User: ', response.data.message);
           setTableData([]);
          }
       }
    })
    };
    
 useEffect(() => { 
     // GET with API : Role
    const fetchRole = async () => {
      const urlToGetRole = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_ROLE_DATA_GET : WASHUB_CONSTANTS.API_URLS.CLIENT_ROLE_DATA_GET ;
      httpGet(urlToGetRole).then((response) => {
       if (response.status === 200) {
          const rolevalues = response.data.map( (item : any ) => item.name);
          setRoleDisplayData(rolevalues);
          setRoleData(response.data); 
       } else {
          if(response.data.error){        
          console.error('Error fetching role: ', response.data.message);
          setRoleData([]);
          }
       }
    })
    };  

    // GET with API : Department
    const fetchDepartment = async () => {
      const urlToGetDept = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_DEPARTMENT_DATA_GET : WASHUB_CONSTANTS.API_URLS.CLIENT_DEPARTMENT_DATA_GET ;
      httpGet(urlToGetDept).then((response) => {
       if (response.status === 200) {
          const deptvalues = response.data.map( (item : any ) => item.name);
          setDepartmentDisplayData(deptvalues);
          setDepartmentData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching department: ', response.data.message);
          setDepartmentData([]);
          }
       }
    })
    };

    // GET with API : User Type
    const fetchUserType = async () => {
      const urlToGetDept = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_USER_TYPE_DATA_GET
       : WASHUB_CONSTANTS.API_URLS.CLIENT_USER_TYPE_DATA_GET ;
      httpGet(urlToGetDept).then((response) => {
       if (response.status === 200) {
          const userTypeValues = response.data.map( (item : any ) => item.name);
          setUserTypeDisplayData(userTypeValues);
          setUserTypeData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching userType: ', response.data.message);
          setUserTypeData([]);
          }
       }
    })
    };
    
    fetchRole();
    fetchDepartment();
    fetchUserType();
    fetchUser();    
  }, []); 

  // POST with API
  // const handleCreateNewRow = (values: any) => {
  //    if (!Object.keys(validationErrors).length) {
  //       console.log('Roles--',roleData, values)

  //       const foundRole: any  = roleData?.find((i : any) => (i.name == String(values['role.name']))) ; 
  //       values.roleId  = foundRole['id']

  //       const foundDept: any  = departmentData?.find((i : any) => (i.name == String(values['department.name']))) ; 
  //       values.departmentId  = foundDept['id'] 

  //       const foundUserType: any  = userTypeData?.find((i : any) => (i.name == String(values['userType.name']))) ; 
  //       values.userTypeId  = foundDept['id'] ;

  //       const payload = {
  //         firstName: values.firstName,
  //         lastName: values.lastName,
  //         phone: values.phone,
  //         userId: values.userId,
  //         password: values.password,
  //         position: values.position,
  //         departmentId: values.departmentId,
  //         roleId: values.roleId,
  //         clientId: values.id,
  //         userTypeId: values.userTypeId
  //       }
        
  //       httpPost(WASHUB_CONSTANTS.API_URLS.CLIENT_USER_DATA_POST, payload).then((response) => {
  //          if (response.status === 200) {
  //             fetchUser();
  //          } else {
  //             if(response.data.error){        
  //             console.error('Error Updating User: ', response.data.message);
  //             }
  //          }
  //       });
  //     }
  // };

  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<UserDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        
        console.log('Roles2--',roleData, valuesDetail)

        const foundRole: any  = roleData?.find((i : any) => (i.name == String(values['role.name']))) ; 
        valuesDetail.roleId  = foundRole['id']

        const foundDept: any  = departmentData?.find((i : any) => (i.name == String(values['department.name']))) ; 
        valuesDetail.departmentId  = foundDept['id'] 

        const foundUserType: any  = userTypeData?.find((i : any) => (i.name == String(values['userType.name']))) ; 
        valuesDetail.userTypeId  = foundDept['id'] 

        const payload = {
          firstName: valuesDetail.firstName || values.firstName,
          lastName: valuesDetail.lastName || values.lastName,
          phone: valuesDetail.phone || values.phone,
          userId: valuesDetail.userId || values.userId,
          position: valuesDetail.position || values.position,
          departmentId: valuesDetail.departmentId || values.departmentId,
          roleId: valuesDetail.roleId || values.roleId,
          clientId: valuesDetail.clientId || row?.original?.client?.id,
          userTypeId: valuesDetail.userTypeId || values.userTypeId
        }
        
        httpPut(WASHUB_CONSTANTS.API_URLS.CLIENT_USER_DATA_PUT+'/'+values.id, payload).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              handleAlertBar(
                'success',
                `User updated Successfully`,
              );
              setValuesDetail({});
              fetchUser();
              exitEditingMode(); //required to exit editing mode and close modal
           } else {
              if(response.data.error){        
              console.error('Error Updating User: ', response.data.message);
              }
           }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<UserDetails>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  
  const columns = useMemo<MRT_ColumnDef<UserDetails>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'userId',
        header: 'User Id',
        enableEditing: false,
        size: 140,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 140,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 140,
      },
      {
        accessorKey: 'position',
        header: 'Position',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'role.name',
        header: 'Role',
        editVariant: 'select',
        editSelectOptions: roleDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
      {
        accessorKey: 'department.name',
        header: 'Department',
        editVariant: 'select',
        editSelectOptions: departmentDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
      {
        accessorKey: 'userType.name',
        header: 'User Type',
        editVariant: 'select',
        editSelectOptions: userTypeDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
    ],
    [],
  );

  const createNewUser = () => {
    const column = [
      {
        accessorKey: 'userId',
        header: 'User Id',
        size: 140,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 140,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 140,
      },
      {
        accessorKey: 'password',
        header: 'Password',        
      },
      {
        accessorKey: 'position',
        header: 'Position',        
      },
      {
        accessorKey: 'phone',
        header: 'Phone',        
      },
      {
        accessorKey: 'role.name',
        header: 'Role',
        editVariant: 'select',
        editSelectOptions: roleDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
      {
        accessorKey: 'department.name',
        header: 'Department',
        editVariant: 'select',
        editSelectOptions: departmentDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
      {
        accessorKey: 'userType.name',
        header: 'User Type',
        editVariant: 'select',
        editSelectOptions: userTypeDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
    ];
    navigate('create-user', {
      state: {
        column: column,
        roleData: roleData,
        departmentData: departmentData,
        userTypeData: userTypeData
      },
    });
  };

  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            User Management
          </Typography>
        </Grid>
        <Grid item className="mb-1 mt-1 me-3">
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={createNewUser}
            variant="contained"
          >
            Create New User
          </Button>
        </Grid>
      </Grid>

      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="modal"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          muiTableContainerProps={{
            sx: {
              height: '64vh',
            },
          }}
          renderEditRowDialogContent={({
            table,
            row,
            internalEditComponents,
          }) => (
            <Dialog fullWidth open={true}>
              <DialogTitle
                sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
                variant="h5"
                textAlign={'center'}
                className="p-3 mb-3"
              >
                Update User
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  {columns.map((column) => (
                    <>
                      {column.accessorKey === 'userId' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.userId}
                            disabled={true}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'firstName' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.firstName}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'lastName' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.lastName}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'position' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.position}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'phone' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.phone}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'role.name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            key={column.accessorKey}
                            fullWidth
                            label={column.header}
                            name={column.accessorKey}
                            variant="standard"
                            select
                            defaultValue={row.original?.role?.name}
                            onChange={(e) =>
                              setValuesDetail({ ...valuesDetail, [e.target.name]: e.target.value })
                            }>
                            {roleDisplayData.map((option: any ) => (
                            <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      {column.accessorKey === 'department.name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            key={column.accessorKey}
                            fullWidth
                            label={column.header}
                            name={column.accessorKey}
                            variant="standard"
                            select
                            defaultValue={row.original?.department?.name}
                            onChange={(e) =>
                              setValuesDetail({ ...valuesDetail, [e.target.name]: e.target.value })
                            }>
                            {departmentDisplayData.map((option: any ) => (
                            <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      {column.accessorKey === 'userType.name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            key={column.accessorKey}
                            fullWidth
                            label={column.header}
                            name={column.accessorKey}
                            variant="standard"
                            select
                            defaultValue={row.original?.userType?.name}
                            onChange={(e) =>
                              setValuesDetail({ ...valuesDetail, [e.target.name]: e.target.value })
                            }>
                            {userTypeDisplayData.map((option: any ) => (
                            <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <MRT_EditActionButtons variant="text" table={table} row={row} />
              </DialogActions>
            </Dialog>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Edit">
                <IconButton
                  onClick={() => {
                    table.setEditingRow(row);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  onClick={() => handleDeleteCostCenter(row?.original)}
                >
                  <Delete />
                </IconButton>
              </Tooltip> */}
            </Box>
          )}
        />
      </div>
      {/* <AlertDialog
        open={isAlertDialog}
        title={'Delete Cost Center'}
        description={'Are you sure you want to delete this cost center ?'}
        submitBtnText={'Delete'}
        cancelBtnText={'Cancel'}
        handleSubmit={deleteCostCenter}
        handleClose={handleAlertDialogClose}
      ></AlertDialog> */}
    </div>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<UserDetails>[];
  onClose: () => void;
  onSubmit: (values: UserDetails) => void;
  open: boolean;
  rolesPass: any,
  departmentsPass: any,
  userTypePass: any
}

export default User;
