import { Box, Card, CardMedia, Grid, Link, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CustomButton } from '../CustomButton';
import { AuthenticationContext } from '../../../shared/Contexts';
import { useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import './OrderHeader.scss';

export const OrderHeader = () => {
  const [clientDetail, setClientDetail] = useState<any>({});
  const [busDetail, setBusDetail] = useState<any>({});
  const client = localStorage.getItem('clientDetail');
  const business = localStorage.getItem('busDetail');
  const isFirstRender = useRef(true);
  const authenticate = useContext(AuthenticationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFirstRender.current) {
      if (client) {
        setClientDetail(JSON.parse(client));
      }
      if (business) {
        setBusDetail(JSON.parse(business));
      }
      isFirstRender.current = false;
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    authenticate.updateToken(null);
    navigate("/orderCenter/login");
  };

  return (
      <div className='order-center-header p-2'>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid container direction={'column'} spacing={1}>
              <Grid item md={7}>
                <Grid container direction={'row'} spacing={2}>
                  <Grid item xs={3} sm={2} md={1} lg={1}>
                    <Card
                      sx={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '25px',
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          padding: '15px 5px 15px 5px',
                          width: '90%',
                          objectFit: 'cover',
                        }}
                        image={clientDetail?.clientlogo}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={9} sm={10} md={11} lg={11}>
                    {' '}
                    <Typography
                      component="h2"
                      sx={{
                        marginLeft: '5px',
                        fontWeight: 900,
                        fontSize: '30px',
                        color: '#045464',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {clientDetail?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} className='mt-5'>
                {authenticate.token && <Link onClick={handleLogout}>
                  <Typography component="p" className='mt-4'>
                    {'Logout'}
                  </Typography>
                </Link>}
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} textAlign={'end'}>
            <Stack>
              <div style={{ position: 'relative', marginBottom: '45px' }}>
                <Card
                  sx={{
                    width: '45px',
                    height: '45px',
                    borderRadius: '25px',
                    position: 'absolute',
                    right: '0px',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="100%"
                    image={busDetail?.businessLogo}
                  />
                </Card>
              </div>
              <Typography
                component="h3"
                sx={{ fontWeight: 900, fontSize: '20px', color: '#045464' }}
              >
                {busDetail.name}
              </Typography>
              <Typography sx={{ color: '#045464' }} component="p">
                {busDetail?.address}
              </Typography>
              <Typography sx={{ color: '#045464' }} component="p">
                {busDetail?.city}
              </Typography>
              <Typography sx={{ color: '#045464' }} component="p">
                {busDetail?.phone}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </div>
  );
};
