import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_RowSelectionState,
} from 'material-react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { Delete, Edit } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductMasterItemDetails } from '../../models/ProductMaster';

export const BusinessServiceItem = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<ProductMasterItemDetails[]>([]);
  const [IsLoading, setIsLoading] = useState(true);
  const { httpGet, httpPut, handleAlertBar } = useAPI();
  const [categoryDisplayData, setCategoryDisplayData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [mediaDisplayData, setMediaDisplayData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [serviceSectionData, setServiceSectionData] = useState([]);
  const [serviceSectionDisplayData, setServiceSectionDisplayData] = useState(
    [],
  );
  const [userType, setUserType] = useState([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };
  const fetchUserType = async () => {
    httpGet(WASHUB_CONSTANTS.API_URLS.CLIENT_USER_TYPE_DATA_GET).then(
      (response: any) => {
        if (response.status === 200) {
          setUserType(response.data);
        } else {
          if (response.data.error) {
            console.error(
              'Error fetching RetailStore: ',
              response.data.message,
            );
          }
        }
      },
    );
  };
  // GET with API
  const fetchServiceItem = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.PRODUCT_MASTER_SERVICE_ITEM_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };

  // Business Store
  useEffect(() => {
    fetchServiceItem();
  }, []);

  // PUT with API
  const handleSaveRowEdits: MaterialReactTableProps<ProductMasterItemDetails>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const foundCat: any = categoryData?.find(
          (i: any) =>
            i.name ==
            String(valuesDetail['category.name'] || values['category.name']),
        );
        const foundServiceSection: any = serviceSectionData?.find(
          (i: any) =>
            i.name ==
            String(
              valuesDetail['serviceItemSection.name'] ||
                values['serviceItemSection.name'],
            ),
        );
        valuesDetail['serviceItemSectionId'] = foundServiceSection?.id;
        values.serviceItemSectionId = foundServiceSection?.id;
        valuesDetail['categoryId'] = foundCat['id'];
        values.categoryId = foundCat['id'];
        const foundMedia: any = mediaData?.find(
          (i: any) =>
            String(i.url) ==
            String(valuesDetail['mediaItem.url'] || values['mediaItem.url']),
        );
        valuesDetail['mediaItemId'] = foundMedia?.id;
        values.mediaItemId = foundMedia['id'];
        const type: any[] = userType.filter(
          (item: any) => item.name === 'Business',
        );
        const payload = {
          name: valuesDetail?.name || values.name,
          description: valuesDetail?.description || row.original.description,
          price: +valuesDetail?.price || values.price,
          itemCode: valuesDetail?.itemCode || values.itemCode,
          weight: +valuesDetail?.weight || values.weight,
          packQuantity: +valuesDetail?.packQuantity || values.packQuantity,
          categoryId: valuesDetail?.categoryId || values.categoryId,
          mediaItemId: valuesDetail?.mediaItemId || values.mediaItemId,
          type: type[0].id,
        };

        httpPut(
          WASHUB_CONSTANTS.API_URLS
            .PRODUCT_MASTER_SERVICE_ITEM_UPDATE_DATA_PUT +
            '/' +
            row.original.id,
          payload,
        ).then((response) => {
          if (response.status === 200) {
            fetchServiceItem();
            handleAlertBar(
              'success',
              `Business Service Item updated Successfully`,
            );
            setValuesDetail({});
            exitEditingMode(); //required to exit editing mode and close modal
          } else {
            if (response.data.error) {
              handleAlertBar('error', response.data.message);
              console.error(
                'Error Updating serviceitem: ',
                response.data.message,
              );
            }
          }
        });
      }
    };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  useEffect(() => {
    // GET with API: category
    const fetchCategory = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.CATEGORY_DATA_GET).then((response) => {
        if (response.status === 200) {
          const catvalues = response.data.map((item: any) => item.name);
          setCategoryDisplayData(catvalues);
          setCategoryData(response.data);
        } else {
          if (response.data.error) {
            console.error('Error fetching category: ', response.data.message);
            setCategoryData([]);
          }
        }
      });
    };

    const fetchServiceItemSection = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.SERVICEITEM_SECTION_DATA_GET).then(
        (response) => {
          if (response.status === 200) {
            const serviceSectionvalues = response.data.map(
              (item: any) => item.name,
            );
            setServiceSectionDisplayData(serviceSectionvalues);
            setServiceSectionData(response.data);
          } else {
            if (response.data.error) {
              console.error(
                'Error fetching BusinessStore: ',
                response.data.message,
              );
              setServiceSectionData([]);
            }
          }
        },
      );
    };

    // GET with API: media
    const fetchMediaFile = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.PRODUCT_MASTER_MEDIAFILE_DATA_GET).then(
        (response) => {
          if (response.status === 200) {
            const mediavalues = response.data.map((item: any) =>
              String(item.url),
            );
            setMediaDisplayData(mediavalues);
            setMediaData(response.data);
          } else {
            if (response.data.error) {
              console.error(
                'Error fetching mediafile: ',
                response.data.message,
              );
              setMediaData([]);
            }
          }
        },
      );
    };

    fetchMediaFile();
    fetchCategory();
    fetchServiceItemSection();
    fetchUserType();
  }, []);

  const columns = useMemo<MRT_ColumnDef<ProductMasterItemDetails>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 140,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50,
      },
      // {
      //   accessorKey: 'category.name',
      //   header: 'Service Type',
      //   editVariant: 'select',
      //   editSelectOptions: categoryDisplayData,
      //   muiEditTextFieldProps: {
      //     select: true,
      //   },
      // },
      // {
      //   accessorKey: 'serviceItemSection.name',
      //   header: 'Category',
      //   editVariant: 'select',
      //   editSelectOptions: serviceSectionDisplayData,
      //   muiEditTextFieldProps: {
      //     select: true,
      //   },
      // },
      {
        accessorKey: 'category.name',
        header: 'Category',
        editVariant: 'select',
        editSelectOptions: categoryDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
      {
        accessorKey: 'itemCode',
        header: 'Code',
        size: 50,
      },
      {
        accessorKey: 'packQuantity',
        header: 'Pack Quantity',
        size: 50,
      },
      {
        accessorKey: 'mediaItem.url',
        header: 'Item Image',
        editVariant: 'select',
        editSelectOptions: mediaDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
        Cell: ({ cell }) => (
          <img width={50} height={40} src={cell.getValue<string>()} />
        ),
      },
    ],
    [mediaDisplayData, serviceSectionDisplayData, categoryDisplayData],
  );
  const createNewBusinessServiceItem = () => {
    const column = [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 500,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 50,
      },
      {
        accessorKey: 'weight',
        header: 'Weight',
        size: 50,
      },
      {
        accessorKey: 'itemCode',
        header: 'Code',
        size: 50,
      },
      {
        accessorKey: 'packQuantity',
        header: 'Pack Quantity',
        size: 50,
      },
      // {
      //   accessorKey: 'categoryId',
      //   header: 'Service Type'
      // },
      // {
      //   accessorKey: 'serviceItemSectiontId',
      //   header: 'Category',
      // },
      {
        accessorKey: 'categoryId',
        header: 'Category',
      },
      {
        accessorKey: 'mediaItemId',
        header: 'Item Image',
      },
    ];
    navigate('create-business-service-item', {
      state: {
        column: column,
        categoryData: categoryData,
        serviceSectionData: serviceSectionData,
        mediaData: mediaData,
        userType: userType,
      },
    });
  };
  const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  return (
    <div style={{ padding: '5px 15px', height: 'calc(100vh - 12vh)' }}>
      <Grid container justifyContent={'space-between'} className="mb-2 mt-1">
        <Grid item>
          <Typography
            color={'primary'}
            sx={{ textAlign: 'start' }}
            variant="h5"
            className="mt-1"
          >
            Product Master
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            sx={{ color: 'white' }}
            onClick={createNewBusinessServiceItem}
            variant="contained"
          >
            Create Product Master
          </Button>
        </Grid>
      </Grid>

      <div>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          columns={columns}
          data={tableData}
          enableFullScreenToggle={false}
          enableStickyHeader
          enableColumnOrdering
          enableEditing
          enableHiding
          editDisplayMode="modal"
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          muiTableContainerProps={{
            sx: {
              height: '63vh',
            },
          }}
          renderEditRowDialogContent={({
            table,
            row,
            internalEditComponents,
          }) => (
            <Dialog fullWidth open={true}>
              <DialogTitle
                sx={{ backgroundColor: '#83CBEB', color: '#ffff' }}
                variant="h5"
                textAlign={'center'}
                className="p-3 mb-3"
              >
                Update Price Master
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  {columns.map((column) => (
                    <>
                      {column.accessorKey === 'name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.name}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: +e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'description' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.description}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'price' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            required
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.price}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: +e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'weight' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            required
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.weight}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: +e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column.accessorKey === 'packQuantity' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            required
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.packQuantity}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: +e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {column?.accessorKey === 'itemCode' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <TextField
                            fullWidth
                            required
                            key={column.accessorKey}
                            variant="standard"
                            label={column.header}
                            name={column.accessorKey}
                            defaultValue={row.original.itemCode}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {/* {column?.accessorKey === 'category.name' && (
                        <Grid key={column.accessorKey} item xs={12} md={6} lg={6}>
                        <div>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: 'rgba(0, 0, 0, 0.6)',
                            }}
                          >
                            Service Type
                          </Typography>
                          <Select
                            fullWidth
                            label={column.header}
                            variant="standard"
                            name={column.accessorKey}
                            defaultValue={row.original.category?.name}
                            onChange={(e) =>
                              setValuesDetail({
                                ...valuesDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          >
                            {categoryDisplayData.map((item: any) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>{' '}
                        </div>
                        </Grid>
                      )} */}
                      {column?.accessorKey === 'category.name' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <div>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: 'rgba(0, 0, 0, 0.6)',
                              }}
                            >
                              Category *
                            </Typography>
                            <Select
                              fullWidth
                              required
                              label={column.header}
                              variant="standard"
                              name={column.accessorKey}
                              defaultValue={row?.original?.category?.name}
                              onChange={(e) =>
                                setValuesDetail({
                                  ...valuesDetail,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            >
                              {categoryDisplayData?.map((item: any) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>{' '}
                          </div>
                        </Grid>
                      )}
                      {column.accessorKey === 'mediaItem.url' && (
                        <Grid
                          key={column.accessorKey}
                          item
                          xs={12}
                          md={6}
                          lg={6}
                        >
                          <div>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: 'rgba(0, 0, 0, 0.6)',
                              }}
                            >
                              Item Image
                            </Typography>
                            <Select
                              fullWidth
                              label={column.header}
                              variant="standard"
                              name={column.accessorKey}
                              defaultValue={row.original.mediaItem.url}
                              onChange={(e) =>
                                setValuesDetail({
                                  ...valuesDetail,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            >
                              {mediaDisplayData.map((item: any) => (
                                <MenuItem key={item} value={item}>
                                  <img src={item} width={30} height={22} />
                                </MenuItem>
                              ))}
                            </Select>{' '}
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <MRT_EditActionButtons variant="text" table={table} row={row} />
              </DialogActions>
            </Dialog>
          )}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
    </div>
  );
};
