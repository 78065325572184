import React, { useState } from 'react';

import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const DeliveryItemList = (Props: any) => {
  const navigate = useNavigate();
  const { axiosInstance, httpPost } = useAPI();
  const [openDialog, setOpenDialog] = useState(false);
  const [printDeliveryNotePayload, setPrintDeliveryNotePayload] = useState<any>([]);
  const [deliveryDate, setDeliveryDate] = useState<any>("");

  // POST with API : Generate delivery Notes
  const fetchGenerateDeliveryNotesPdf = async () => {
    const payload: any = {
      departmentId: printDeliveryNotePayload?.businessDepartment,
      businessId: printDeliveryNotePayload?.business,
      businessStoreId: printDeliveryNotePayload?.businessStore,
      deliveryBatchId: printDeliveryNotePayload?.deliveryBatch,
      orderId: printDeliveryNotePayload?.orderId
    };
    if(deliveryDate) {
      payload["deliveryDate"] = deliveryDate?.format('YYYY-MM-DD') 
    }
    axiosInstance.post(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSSTORE_GENERATE_DELIVERY_NOTE_PDF_POST,
      payload,
      { 
        headers: {
          Authorization: localStorage.getItem("jwtToken") || null
        },
        responseType: 'blob'}
    ).then((response: any) => {
      if (response?.status === 200) {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = `business-order-delivery-note-${printDeliveryNotePayload?.deliveryBatch}`;
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2)
                fileName = fileNameMatch[1];
        }
        console.log(contentDisposition);
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        setOpenDialog(false);

        a.remove();
        window.URL.revokeObjectURL(url)
      } else {
        if (response?.data?.error) {
          console.error(
            'Error fetching BusinessStoreInvoices: ',
            response?.data?.message,
          );
        }
      }
    }).catch((err) => {
      console.log('Second catch');
    });
  };

  const handlePrintDeliveryNotes = (row: any) => {
    setDeliveryDate(null);
    setPrintDeliveryNotePayload(row);
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeliveryDateChange = (date: any) => {
    setDeliveryDate(date);
  };

  return (
    <div style={{ padding:"5px 15px"}}>
      <MaterialReactTable
               defaultColumn={{
                maxSize: 150,
                minSize: 10,
                size: 50
              }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 100,
          },
        }}
        layoutMode="grid"
        muiTableContainerProps={{
          sx: {
           height: '60vh',
          },
        }}
        muiTablePaperProps={{
          sx: {
            width: '100%',
            '& .css-1tbggly': {
              minHeight: '2px',
            },
          },
        }}
        localization={{noRecordsToDisplay: Props?.businessStoreId && Props?.businessId && Props?.departmentId || Props?.orderId && Props?.businessId ? 'No data Available' : 'Select a business & business store & department to display records'}}
        columns={Props?.columns}
        data={Props?.tableData}
        enableFullScreenToggle={false}
        enableStickyHeader
        positionActionsColumn="last"
        enableRowActions
        enableColumnActions={false}
        enableColumnFilters={false}
        enableFilters={true}
        enableSorting={false}
        enableHiding={false}
        enableDensityToggle={false}
        enableToolbarInternalActions={false}
        renderRowActions={({ row, table }) => (
          <Box>
            {row.original.deliveryBatch === null ? (<Button
              variant="contained"
              className='ms-4'
              onClick={() => {
                Props?.fetchGenerateDeliveryNotesBatch(row.original)
              }}
            >
              Generate Delivery Batch
            </Button>) : (<Box sx={{ display: 'flex', justifyContent: "space-between" }}><Button
              variant="contained"
              className='me-4'
              onClick={() => {
                handlePrintDeliveryNotes(row.original);
              }}
            >
              Print Delivery
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate('delivery-item-details', {
                  state: {
                    rowData: row?.original,
                  },
                });
              }}
            >
              Details
            </Button></Box>)}
          </Box>
        )}
      />
      <Dialog fullWidth
        maxWidth='sm'  open={openDialog} onClose={handleDialogClose}>
        <DialogTitle textAlign="center">Add Delivery Date</DialogTitle>
      <DialogContent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Delivery date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="startDate"
                  onChange={handleDeliveryDateChange}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>

      </DialogContent>
      <DialogActions className="dialog-btn">
      <Button color='error' variant="contained" sx={{color: 'white'}} onClick={handleDialogClose}>Cancel</Button>
        <Button
          onClick={fetchGenerateDeliveryNotesPdf}
          sx={{color: 'white'}}
          variant="contained"
        >
          Print Delivery
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};
