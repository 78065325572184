import React from 'react';
import './Menu.scss';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Menunav = () => {
  const role = localStorage.getItem('userRole');
  const type = localStorage.getItem('userType');
  const paymentFormField = [
    {
      accessorKey: 'paymentDate',
      header: 'Payment Date',
    },
    {
      accessorKey: 'invoiceNo',
      header: 'Invoice No',
    },
    {
      accessorKey: 'purchaseOrder',
      header: 'Purchase Order',
    },
    {
      accessorKey: 'paymentStatus',
      header: 'Payment Status',
    },
    {
      accessorKey: 'paymentType',
      header: 'Payment Type',
    },
    {
      accessorKey: 'transactionId',
      header: 'Transaction Id',
    },
    {
      accessorKey: 'paymentReferenceNo',
      header: 'Payment Reference No.',
    },
    {
      accessorKey: 'paymentAmount',
      header: 'Payment Amount',
    },
  ];
  return (
    <div className="side-bar">
      {role === 'CLIENT_ADMIN' ? (
        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Business Management</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/business'}>
              <div className="side-bar-content">Business</div>
            </NavLink>
            <NavLink to={'/business-store'}>
              <div className="side-bar-content">Business Store</div>
            </NavLink>
            <NavLink to={'/business-department'}>
              <div className="side-bar-content">Business Department</div>
            </NavLink>

            {type === 'Business' || role === 'CLIENT_ADMIN' ? (
              <NavLink to={'/business-store-order'}>
                <div className="side-bar-content">Business Orders</div>
              </NavLink>
            ) : null}
            {type === 'Business' || role === 'CLIENT_ADMIN' ? (
              <NavLink to={'/business-verify'}>
                <div className="side-bar-content">Verify Business Order</div>
              </NavLink>
            ) : null}

            {type === 'Business' || role === 'CLIENT_ADMIN' ? (
              <NavLink to={'/business-delivery-notes'}>
                <div className="side-bar-content">Business Delivery Notes</div>
              </NavLink>
            ) : null}
          </AccordionDetails>
        </Accordion>
      ) : type === 'Business' ? (
        <>
          {' '}
          <NavLink to={'/business-store-order'}>
            <div className="side-bar-content">Business Orders</div>
          </NavLink>
          <NavLink to={'/business-verify'}>
            <div className="side-bar-content">Verify Business Order</div>
          </NavLink>
          <NavLink to={'/business-delivery-notes'}>
            <div className="side-bar-content">Business Delivery Notes</div>
          </NavLink>
        </>
      ) : null}
      {role === 'CLIENT_ADMIN' && <Divider sx={{ opacity: 0.8}} />}
      {/* {role === 'CLIENT_ADMIN' ? (
        <NavLink to={'/service-item'}>
          <div
            className={`${location.pathname === '/service-item' ? 'active' : ''}`}
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            Manage Service Items
          </div>
        </NavLink>
      ) : null} */}
            {role === 'CLIENT_ADMIN' && (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Manage Product Master</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/business-service-item'}>
              <div className="side-bar-content">Product Master</div>
            </NavLink>
            <NavLink to={'/price-master'}>
                <div className="side-bar-content">Price Master</div>
            </NavLink>
          </AccordionDetails>
        </Accordion>
      )}
      {role === 'CLIENT_ADMIN' ? (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Retail Management</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/retail-store'}>
              <div className="side-bar-content">Retail Store</div>
            </NavLink>
            <NavLink to={'/retail-store-order'}>
              <div className="side-bar-content">Retail Orders</div>
            </NavLink>
            <NavLink to={'/retail-invoices'}>
              <div className="side-bar-content">Retail Store Invoices</div>
            </NavLink>
            <NavLink
              to={'/retail-payments'}
              state={{ paymentFormField: paymentFormField }}
            >
              <div className="side-bar-content">Retail Payments</div>
            </NavLink>
          </AccordionDetails>
        </Accordion>
      ) : type === 'Retail' ? (
        <>
          {' '}
          <NavLink to={'/retail-store-order'}>
            <div className="side-bar-content">Retail Orders</div>
          </NavLink>
          <NavLink to={'/retail-invoices'}>
            <div className="side-bar-content">Retail Store Invoices</div>
          </NavLink>
          <NavLink
            to={'/retail-payments'}
            state={{ paymentFormField: paymentFormField }}
          >
            <div className="side-bar-content">Retail Payments</div>
          </NavLink>
        </>
      ) : null}
      {role === 'CLIENT_ADMIN' ? (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Customer Management</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/customer'}>
              <div className="side-bar-content">Customer</div>
            </NavLink>

            <NavLink to={'/customer-order'}>
              <div className="side-bar-content">Customer Orders</div>
            </NavLink>

            <NavLink to={'/customer-invoices'}>
              <div className="side-bar-content">Customer Invoices</div>
            </NavLink>

            <NavLink
              to={'/customer-payments'}
              state={{ paymentFormField: paymentFormField }}
            >
              <div className="side-bar-content">Customer Payments</div>
            </NavLink>
          </AccordionDetails>
        </Accordion>
      ) : type === 'Customer' ? (
        <>
          {' '}
          <NavLink to={'/customer'}>
            <div className="side-bar-content">Customer</div>
          </NavLink>
          <NavLink to={'/customer-order'}>
            <div className="side-bar-content">Customer Orders</div>
          </NavLink>
          <NavLink to={'/customer-invoices'}>
            <div className="side-bar-content">Customer Invoices</div>
          </NavLink>
          <NavLink
            to={'/customer-payments'}
            state={{ paymentFormField: paymentFormField }}
          >
            <div className="side-bar-content">Customer Payments</div>
          </NavLink>
        </>
      ) : null}
      {role === 'CLIENT_ADMIN' && (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Administration</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/cost-center'}>
              <div className="side-bar-content">Cost Center</div>
            </NavLink>
            <NavLink to={'/processing-center'}>
              <div className="side-bar-content">Processing Center</div>
            </NavLink>
            <NavLink to={'/category'}>
              <div className="side-bar-content">Category</div>
            </NavLink>
            {role === 'CLIENT_ADMIN' ? (
              <NavLink to={'/department'}>
                <div className="side-bar-content">Department</div>
              </NavLink>
            ) : (
              type !== 'Business' &&
              type !== 'Retail' &&
              type !== 'Customer' && (
                <NavLink to={'/department'}>
                  <div className="side-bar-content">Client Department</div>
                </NavLink>
              )
            )}
            <NavLink to={'/mediafile'}>
              <div className="side-bar-content">Media Items</div>
            </NavLink>
            {role === 'CLIENT_ADMIN' ? (
              <NavLink to={'/user'}>
                <div className="side-bar-content">User Management</div>
              </NavLink>
            ) : (
              type !== 'Business' &&
              type !== 'Retail' &&
              type !== 'Customer' && (
                <NavLink to={'/user'}>
                  <div className="side-bar-content">Client Admin User</div>
                </NavLink>
              )
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {role === 'CLIENT_ADMIN' && (
        <Accordion
          sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
          }}
        >
          <AccordionSummary
            sx={{ paddingleft: '10px', textAlign: 'left' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant='h5'>Reports</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ display: 'flex', flexDirection: 'column', padding: '0px' }}
          >
            <NavLink to={'/inhouse'}>
              <div className="side-bar-content">Inhouse Sales Summary Reports</div>
            </NavLink>
            <NavLink to={'/businesssales'}>
                <div className="side-bar-content">Business Sales Summary Reports</div>
            </NavLink>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
