import { BusinessStoreOrderDeatils } from './BusinessStoreOrder';

export const data: BusinessStoreOrderDeatils[] = [];

export const orderStatus = [
    'CREATED',
    'PICKED',
    'VERIFIED',
    'INPROCESS',
    'INPACKAGING',
    'READYTODELIVER',
    'DELIVERED',
    'PAYMENTDONE',
    'ONHOLD',
    'DELIVERED_PARTIAL',
    'READYTODELIVER_PARTIAL',
    'INPACKAGING_PARTIAL',
    'INVOICED'
]
