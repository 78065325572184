import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { data } from './makeData';
import { useAPI } from "../../../shared/services/api/API";
import { WASHUB_CONSTANTS } from "../../../shared/Constants";


export type DepartmentDeatils = {
  id: string;
  name: string;
  description: string;
  clientId: string;
};

export const Department = () => {
  const { httpGet, httpPost, httpPut, httpDelete } = useAPI();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<DepartmentDeatils[]>(() => data);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [clientDisplayData, setClientDisplayData] = useState([]);
  const [clientData, setClientData] = useState([]);


  const role  = localStorage.getItem("userRole");

  // GET with API
  const fetchDepartment = async () => {
      const urlToGetDept = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_DEPARTMENT_DATA_GET : WASHUB_CONSTANTS.API_URLS.CLIENT_DEPARTMENT_DATA_GET ;
      httpGet(urlToGetDept).then((response) => {
       if (response.status === 200) {
          setTableData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching department: ', response.data.message);
          setTableData([]);
          }
       }
    })
    };

  // GET with API
  const fetchClient = async () => {
      httpGet(WASHUB_CONSTANTS.API_URLS.CLIENT_DATA_GET).then((response) => {
       if (response.status === 200) {
          const catvalues = response.data.map( (item : any ) => item.name);
          setClientDisplayData(catvalues);
          setClientData(response.data);
       } else {
          if(response.data.error){        
          console.error('Error fetching client: ', response.data.message);
          setClientData([]);
          }
       }
    })
    };

 useEffect(() => {  
    fetchClient();  
    fetchDepartment();
  }, []); 

  // POST with API

  const handleCreateNewRow = (values: any) => {
     if (!Object.keys(validationErrors).length) {
        //console.log(row, values);

        if( role ==='SUPER_ADMIN') {
          const foundCli: any  = clientData?.find((i : any) => (i.name == String(values['client.name']))) ; 
          values.clientId  = foundCli['id']
        }

        const urlToPostDept = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_DEPARTMENT_DATA_POST +values.clientId : WASHUB_CONSTANTS.API_URLS.CLIENT_DEPARTMENT_DATA_POST ;
        httpPost(urlToPostDept,values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchDepartment();
           } else {
              if(response.data.error){        
              console.error('Error Updating department: ', response.data.message);
              }
           }
        });
      }
  };

  // PUT with API

  const handleSaveRowEdits: MaterialReactTableProps<DepartmentDeatils>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        //console.log(row, values);
        if( role ==='SUPER_ADMIN') {
          const foundCli: any  = clientData?.find((i : any) => (i.name == String(values['client.name']))) ; 
          values.clientId  = foundCli['id']
        }

        const urlToPutDept = ( role ==='SUPER_ADMIN') ? WASHUB_CONSTANTS.API_URLS.ADMIN_DEPARTMENT_DATA_PUT : WASHUB_CONSTANTS.API_URLS.CLIENT_DEPARTMENT_DATA_GET ;
        httpPut(urlToPutDept+'/'+values.id, values).then((response) => {
          console.log( 'jwtToken :',localStorage.getItem("jwtToken"));
          console.log( 'RESPONSE :',response);
           if (response.status === 200) {
              fetchDepartment();
              exitEditingMode(); //required to exit editing mode and close modal
           } else {
              if(response.data.error){        
              console.error('Error Updating department: ', response.data.message);
              }
           }
        });
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<DepartmentDeatils>) => {
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  
  const columns = useMemo<MRT_ColumnDef<DepartmentDeatils>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 140,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 500,
      },
      {
        accessorKey: 'client.name',
        header: 'Client',
        editVariant: 'select',
        editSelectOptions: clientDisplayData,
        muiEditTextFieldProps: {
          select: true,
        },
      },
    ],
    [],
  );

  return (
    <div style={{height: '89vh',overflow: 'auto', }}>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        enableFullScreenToggle={false}
        enableStickyHeader
        editDisplayMode='row'
        enableColumnOrdering
        enableEditing
        enableHiding
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {/*<Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>*/}
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Department
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        clients = {clientDisplayData}        
      />
    </div>
  );
};

interface CreateModalProps {
  columns: MRT_ColumnDef<DepartmentDeatils>[];
  onClose: () => void;
  onSubmit: (values: DepartmentDeatils) => void;
  open: boolean;
  clients: any;
}

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  clients,
}: CreateModalProps) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog  
        fullWidth
        maxWidth='md' 
        open={open}>
      <DialogTitle style={{backgroundColor: 'purple', borderRadius: '0px 0px 25px 25px', color: '#fff', marginBottom: '20px'}} textAlign="center">Create New Department</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              (column.header == 'ID') ? <></> :
              (column.header == 'Client') ? 
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                select
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }>
                {clients.map((option: any) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
              </TextField> :
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Department
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age: number) => age >= 18 && age <= 50;

export default Department;
