import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ICustomerDetails } from '../../../../shared/models/CustomerDetails.model';
import { Button, Stack, TextField } from '@mui/material';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { useAPI } from '../../../../shared/services/api/API';

export const CreateCustomer = () => {
    const navigate = useNavigate();
    const { httpPost , handleAlertBar } = useAPI();
    const [validationErrors, setValidationErrors] = useState<{
      [cellId: string]: string;
    }>({});
  
    const columns = [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableHiding: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 140,
      },
      {
          accessorKey: 'lastName',
          header: 'Last Name',
          size: 140,
      },
      {
          accessorKey: 'phone',
          header: 'Phone',        
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 140,
      },
      {
        accessorKey: 'address2',
        header: 'Address-2',
        size: 140,
      },
      {
        accessorKey: 'zipCode',
        header: 'Zipcode',        
      },
      {
        accessorKey: 'mail',
        header: 'Email',
      },
      {
        accessorKey: 'state',
        header: 'State',        
      },
      {
        accessorKey: 'country',
        header: 'Country',        
      },
    ];
  
    const [values, setValues] = useState<any>(() =>
      columns?.reduce((acc: any, column: { accessorKey: any; }) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
      }, {} as any),
  );
  
    const onSubmit = (values: ICustomerDetails) => {
      if (!Object.keys(validationErrors).length) {
        httpPost(WASHUB_CONSTANTS.API_URLS.CUSTOMER_DATA_POST, values).then((response) => {
             if (response.status === 200) {
               handleAlertBar("success", `New Customer Created Successfully`);
               onClose();
             } else {
                if(response.data.error){        
                console.error('Error Updating Business: ', response.data.message);
                }
             }
          });
       }
   };
  
    const onClose = () => {
      navigate('..');
    };
  return (
    <div style={{width: '100%'}}>
    <div style={{backgroundColor:"purple",color: 'white',padding: "10px", marginBottom: '20px', textAlign:"center", borderBottom: "1px solid black"}}>{"Enter Customer Details"}</div>
    <div style={{height: "69vh", overflow: "auto", padding: "20px 20px"}}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          sx={{
            width: '80%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          {columns?.map((column: any,index: number) => (
            (column.header == 'ID') ? <></> :
            <TextField
              key={index}
              label={column.header}
              name={column.accessorKey}
              placeholder={column.accessorKey === "paymentDate" ? "YYYY-MM-DD" : `Please enter ${column.header}` }
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
            />
          ))}
        </Stack>
      </form>
    </div>
    <div style={{ margin: ' 1rem 1rem', display: 'flex', justifyContent: "space-between",}}>
      {onClose ? <Button onClick={onClose}>Cancel</Button>: <div></div>}
      <Button color="secondary" onClick={()=>onSubmit(values)} variant="contained">
        {"Submit"}
      </Button>
    </div>
  </div>
  )
}